.form-row
    display: grid
    grid-template-columns: repeat(2, 1fr)
    grid-gap: 25px 10px

.input-container
    position: relative

    &.color-picker-container
        max-width: 210px

    label
        font-weight: $font-weight-semi-bold
        color: $black-2
        display: block
        margin-bottom: 5px
        transition: all ease-in-out .3s

    .input
        border: 1px solid $grey-6
        border-radius: 6px
        height: 40px
        width: 100%
        display: flex
        align-items: center
        font-size: 14px
        padding: 0 10px
        background: transparent
        position: relative
        box-shadow: 0
        transition: all ease-in-out .3s
        background: $white
        font-family: $primary-font

        @include placeholder
            color: $grey-6
            font-family: $primary-font
            font-size: 14px
            font-weight: $font-weight-medium

        &::-webkit-input-placeholder
        &:-ms-input-placeholder
        &::placeholder
            font-weight: 500
            font-size: 14px
            color: #8f99a6

        &.textarea
            padding-top: 3px
            padding-bottom: 3px

    &:focus
        border-color: $blue-2
        outline: 0

        &.textarea
            height: 140px
            resize: none
            padding: 10px 15px 15px
            overflow: auto

    .input-icon-container
        position: relative
        display: flex
        align-items: center

        .input-icon
            position: absolute
            display: flex
            align-items: center
            pointer-events: none
            width: 15px

            svg
                width: 100%
                height: auto

        &.rightIcon
            .input
                padding-right: 35px

            .input-icon
                right: 0px

                &.right
                    right: 10px

        &.leftIcon
            .input
                padding-left: 35px

            .input-icon
                left: 10px

        .right
            right: 10px
            left: unset !important

        .show-password
            pointer-events: all

            svg
                path
                    fill: $grey-6
                    transition: all ease-in-out .3s

            &.shown
                svg
                    path
                        fill: $purple-2

    &.isFocused
        label
            color: $black-2

        .input
            border-color: $blue-2

    &.error
        label
            color: $red

        .input
            border-color: $red

    &.no-border
        .input
            border: 0
            height: auto
            padding: 0
            color: $grey-8
            font-weight: $font-weight-regular

            @include placeholder
                color: $grey-6
                font-family: $primary-font
                font-size: 14px
                font-weight: $font-weight-medium

            &.textarea
                font-weight: $font-weight-regular

                @include placeholder
                    color: $grey-6
                    font-family: $primary-font
                    font-size: 14px
                    font-weight: $font-weight-medium

    &.expand
        & > div
            position: relative

        .input
            height: 40px

        .primary-button
            position: absolute
            bottom: 15px
            right: 15px
            width: 110px
            opacity: 0
            transition: all ease-in-out .2s
            pointer-events: none

        &.isFocused
            .input
                height: 115px
                padding-left: 45px
                padding-right: 140px

            .primary-button
                opacity: 1
                transition: all .3s ease-in-out .2s
                pointer-events: all

    &.showEmoji
        & >  div
            position: relative

    .emoji-container
        position: absolute
        bottom: 15px
        left: 15px
        width: 15px
        cursor: pointer

        .emoji-mart
            position: relative
            z-index: 10

    .emoji-select
        position: relative
        z-index: 10
        display: flex

    .textarea-actions
        @include flex-position(space-between, center)
        position: absolute
        bottom: 5px
        left: 5px

        & > div
            @include flex-position(initial, center)

            .icon
                @include flex-position(center, center)
                width: 30px
                flex: 0 0 30px
                height: 30px
                margin-right: 5px
                cursor: pointer
                border-radius: 6px
                transition: all ease-in-out .3s

                svg
                    width: 15px
                    height: auto

                    path
                        transition: all ease-in-out .3s

                &:last-child
                    margin-right: 0

                &:hover
                    background: $grey-6

                &.send
                    &:hover, &.active
                        background: $blue-2

                        svg
                            path
                                stroke: $white

.input-hint
    font-size: 12px
    font-weight: $font-weight-medium
    color: $grey-7
    display: flex
    margin-top: 5px

    a
        margin-left: 4px

.color-picker-preview
    @include full-circle(20px, 20px)

.select-container
    position: relative

    &.label-inline
        display: flex
        align-items: center

        label
            margin-right: 10px

    .input-icon-container
        min-width: 100px

        .input-icon
            width: 10px
            height: 10px

            svg
                width: 100%
                height: 100%

                path
                    stroke: $grey-7

    .select-current
        cursor: pointer
        white-space: nowrap
        padding-right: 30px !important
        background: transparent

    .select-items
        position: absolute
        min-width: 100%
        background-color: $white
        top: calc(100% + 5px)
        border-radius: 3px
        overflow: auto
        padding: 0
        max-height: 200px
        z-index: 100
        box-shadow: $user-dropdown-shadow

        li
            font-size: 14px
            font-weight: $font-weight-medium
            border-bottom: 1px solid $grey-6
            height: 35px
            line-height: 21px
            padding: 0 15px
            color: $black-3
            cursor: pointer
            transition: all ease-in-out .3s
            white-space: nowrap
            @include flex-position(initial, center)

            &:last-child
                border-bottom: 0

            &:hover
                background: $blue-light-3

            &.disabled
                pointer-events: none
                opacity: 0.3

            &.add-new-item
                color: $blue-2
                @include flex-position(initial, center)

                .icon
                    display: flex
                    margin-right: 10px
                    width: 15px
                    height: 15px

                    svg
                        width: 100%
                        height: auto

                        path
                            stroke: $blue-2

            &.item-input
                padding: 5px 15px

                &:hover
                    background: transparent

                input
                    background: $grey-2
                    border: 0
                    border-radius: 4px
                    padding: 5px 10px
                    height: 100%
                    color: $black-3
                    margin-right: 15px

    &.filter
        .leftIcon
            .left
                width: 15px
                height: 15px

    &.buttonFilter
        .input
            justify-content: center
            border-color: $black-2
            color: $black-2
            font-size: 14px
            font-weight: $font-weight-regular

            &.active
                background: $white

            span
                display: flex
                align-items: center

        .input-icon
            position: relative
            right: unset !important
            margin-left: 10px
            width: 10px
            height: 10px

.multi-select-input
    display: flex
    flex-wrap: wrap
    padding: 0 5px !important
    cursor: text

    .value-item
        margin: 10px 5px
        display: flex
        height: 20px
        align-items: center
        justify-content: center
        padding: 0 15px
        color: $white
        background: $blue-3
        font-size: 12px
        border-radius: 6px
        white-space: nowrap

        .close
            cursor: pointer
            width: 12px
            margin-left: 10px
            display: flex

            svg
                width: 100%
                height: auto

                g, path
                fill: $white
                stroke: $blue-3

    input
        border: 0

.checkbox-container
    .checkbox
        position: relative
        display: flex
        padding-left: 0
        text-transform: none
        align-items: center

        span
            display: inline-block

        input
            height: 0
            opacity: 0
            position: absolute

            & ~ .checkbox-display
                @include flex-position(center, center)
                width: 15px
                height: 15px
                border: 1px solid $purple-2
                border-radius: 3px
                background: transparent
                transition: all ease-in-out .3s
                cursor: pointer

                svg
                    transition: all ease-in-out .3s
                    opacity: 0
                    width: 9px
                    height: auto

            &:checked
                & ~ .checkbox-display
                    background: $purple-2

                    svg
                        opacity: 1

        .checkbox-label
            font-size: 14px
            font-weight: $font-weight-semi-bold
            color: $grey
            margin-left: 10px

        &.green
            input
                & ~ .checkbox-display
                    border-color: $grey-6

                &:checked
                    & ~ .checkbox-display
                        border-color: $green
                        background: $green

        &.black
            input
                & ~ .checkbox-display
                    border-color: $black-2

                &:checked
                    & ~ .checkbox-display
                        border-color: $purple-2

.radio-container
    .radio
        position: relative
        display: flex
        padding-left: 0
        text-transform: none
        align-items: center
        cursor: pointer

        &.space-between
            justify-content: space-between

        span
            display: inline-block

        input
            height: 0
            opacity: 0
            position: absolute

            & ~ .radio-display
                @include flex-position(center, center)
                width: 15px
                height: 15px
                border: 1px solid $blue-2
                border-radius: 50%
                background: $white
                transition: all ease-in-out .3s
                cursor: pointer

                &::after
                    content: ''
                    position: absolute
                    width: 7px
                    height: 7px
                    background: $blue-2
                    border-radius: 50%
                    transition: all ease-in-out .3s
                    opacity: 0

            &:checked
                & ~ .radio-display
                    &::after
                        opacity: 1

        .radio-label
            font-size: 14px
            font-weight: $font-weight-semi-bold
            color: $black-2
            margin-left: 10px
            cursor: pointer
            line-height: 21px

            &.left
                margin-left: 0
                margin-right: 10px

        &.green
            input
                & ~ .radio-display
                    border-color: $grey-6

                &:checked
                    & ~ .radio-display
                        border-color: $green
                        background: $green

        &.black
            input
                & ~ .radio-display
                    border-color: $black-2

                &:checked
                    & ~ .radio-display
                        border-color: $purple-2

label.file-input-container
    position: relative
    margin-bottom: 0 !important
    cursor: pointer
    width: 100%
    height: 100%
    @include flex-position(center, center)

    input
        position: absolute
        opacity: 0
        height: 0
        left: 0
