.options-dropdown-container
    position: relative
    align-self: center
    cursor: pointer

    .icon
        &.vertical
            svg
                transform: rotate(90deg)

    &.disabled
        opacity: 0.4
        pointer-events: none

    .options-menu
        min-width: 100px
        position: absolute
        right: calc(50% - 20px)
        top: calc(-215%)
        background: $white
        box-shadow: $options-dropdown-shadow
        border-radius: 6px
        z-index: 100

        ul
            li
                font-size: 14px
                font-weight: $font-weight-medium
                color: $black-2
                white-space: nowrap

                div
                    cursor: pointer
                    width: 100%
                    @include flex-position(initial, center)
                    padding: 10px 15px
                    background: $white
                    transition: all ease-in-out .25s
                    text-align: left
                    color: $black-2

                    .icon
                        display: flex
                        width: 15px
                        height: 15px
                        margin-right: 10px

                        svg
                            width: 100%
                            height: auto

                            path
                                stroke: $black-2

                    &.red
                        color: $red

                        svg
                            path
                                stroke: $red

                    &:hover
                        background: $blue-light-3
