.home-container
    padding: 30px 25px

    @media screen and (max-width: 600px)
        padding: 30px 15px

    .title
        font-size: 24px
        font-weight: $font-weight-bold

    .search-section
        margin-top: 25px
        position: relative

        .global-search
            padding: 0

        .global-search-options
            margin: 0

    .home-section-title
        display: block
        font-size: 16px
        font-weight: $font-weight-bold
        margin-bottom: 20px

    .overview-section
        display: grid
        grid-template-columns: repeat(4, 1fr)
        grid-gap: 15px
        margin-bottom: 25px

        @media screen and (max-width: 1200px)
            grid-template-columns: repeat(2, 1fr)

        @media screen and (max-width: 600px)
            grid-template-columns: repeat(1, 1fr)

            div.overview-card
                padding: 15px 15px
                height: auto

    .table-container
        font-size: 14px
        font-weight: $font-weight-semi-bold
        width: 100%
        overflow-x: hidden

        .table-tabs
            white-space: nowrap

            @media screen and (max-width: 600px)
                overflow-x: scroll

                &::-webkit-scrollbar
                    display: none

        .table table
            @media screen and (max-width: 1193px)
                width: 1100px
                overflow-x: scroll

        .customer-info
            @include flex-position(initial, center)

            .avatar
                margin-right: 20px

        .customer-channel
            width: 20px

        .preview-message
            color: $grey-7
            min-width: 400px
            max-width: 480px
            text-transform: none

        .message-date
            color: $grey-3

.get_started_block
    padding: 100px 15px 120px 15px
    text-align: center
    border-top: 1px solid #E6EAF0

    p
        margin-top: 18px
        margin-bottom: 20px
        font-weight: 600
        font-size: 16px
        text-align: center
        color: #8F99A6

.get_started_block_button
    margin: 0 auto
    padding: 0
    height: auto
    font-weight: bold
    font-size: 14px
    text-align: center
    color: #4BA3E7
    background: transparent

    &:hover
        box-shadow: none
