.settings-empty-state
    width: 100%
    height: 540px
    max-width: 390px
    margin: auto
    @include flex-position(center, center)
    flex-direction: column

    .title
        font-size: 16px
        font-weight: $font-weight-bold

    .description
        font-size: 14px
        font-weight: $font-weight-medium
        margin: 10px 0 30px
    