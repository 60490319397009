.modal
    position: fixed
    top: 0
    right: 0
    bottom: 0
    left: 0
    width: 100%
    height: 100vh
    z-index: 1010
    overflow: auto
    background: transparentize($black-2, 0.3)

    .modal-overlay
        position: fixed
        width: 100%
        height: 100vh
        z-index: -1

    .modal-container
        width: 570px
        margin: 100px auto 30px
        background: $white
        border-radius: 6px
        position: relative
        display: flex
        z-index: 10

    &.fullScreen
        .modal-container
            width: 100%
            height: 100%
            margin: 0
            border: 0
            border-radius: 0
            overflow: auto

            .section-content
                padding: 0

            .section-header
                padding: 0 50px
                height: 60px
                box-shadow: inset 0px -1px 0px rgba(220, 225, 231, 0.57)

            .section-action
                padding: 0 50px

    &.halfScreen
        .modal-container
            margin: 0 0 0 auto
            max-width: 560px
            height: 100vh
            border-radius: 0px

    &.large
        .modal-container
            width: 790px
            height: auto

    &.small
        .modal-container
            width: 440px
            height: auto

    &.xsmall
        .modal-container
            width: 325px
            height: auto

    &.minHeight
        .modal-container
            min-height: 240px
            display: flex
            justify-content: center
            align-items: center

    &-content
        width: 100%
        padding: 30px 25px

        .modal-header
            @include flex-position(space-between, center)
            padding: 0 25px
            margin-bottom: 15px

        .close
            cursor: pointer

            svg
                path
                    stroke: $black-2

        .modal-icon
            width: 40px
            height: 40px
            border-radius: 50%
            @include flex-position(center, center)
            margin: 0 auto 35px

            svg
                width: 20px
                height: auto

                path
                    stroke: $white

            &.red
                background: $red-2

        .main-text, .modal-title
            font-size: 18px
            font-weight: $font-weight-medium
            color: $black

        .desc-text
            font-weight: $font-weight-semi-bold
            text-align: center

        .modal-title
            @include flex-position(initial, center)

            &.file-preview-title
                justify-content: space-between

            img
                width: 35px
                height: 35px
                margin-right: 12px
                object-fit: contain

    &-action
        display: grid
        grid-template-columns: repeat(2, 1fr)
        grid-column-gap: 30px
        margin-top: 40px

        &.small
            width: 220px
            margin: 40px auto 0

.modal-form
    width: 100%

    &-header
        display: flex
        justify-content: space-between
        background: $white-2
        padding: 15px 20px
        border-radius: 8px 8px 0 0

        .title
            font-size: 18px
            font-weight: $font-weight-bold
            color: $black

            .form-progress
                font-weight: $font-weight-semi-bold
                display: inline-block
                margin-left: 25px

        .close
            display: flex
            cursor: pointer
            width: 10px

            svg
                width: 100%
                height: auto

    &-content
        padding: 15px 20px

    &-action
        display: flex
        padding-top: 10px
        justify-content: space-between

        &.right
            justify-content: flex-end

    &-success
        @include flex-position(center, center)
        flex-direction: column
        width: 100%
        min-height: 600px

        .icon
            width: 80px
            flex: 0 0 80px
            height: 80px
            margin-bottom: 20px
            border-radius: 50%
            background: $green
            @include flex-position(center, center)

            svg
                width: 40px
                height: auto

        .message
            font-size: 24px
            font-weight: $font-weight-bold
            color: $black-4
            margin-bottom: 30px
            text-align: center

        .action
            @include flex-position(center, center)

    .date_range_picker
        @include flex-position(flex-start, center)
        margin-top: 24px
        padding: 0px 0px 0px 13px
        width: fit-content
        height: 39px
        border: 1px solid #E6EAF0
        background: #fff

        svg
            margin-top: -2px
            margin-right: 8px
            width: 17px
            height: 17px

        .react-datepicker-wrapper
            margin-top: -2px
            width: 170px

            .date_range_input
                font-family: Manrope,sans-serif
                font-size: 1em
                font-weight: 600
                color: #666666
                height: 40px
                background: transparent
                border: none

.confirmation-modal
    .modal-title
        text-align: center
        justify-content: center
        font-size: 16px

    .modal-text
        max-width: 340px
        margin: 15px auto 35px
        text-align: center
        font-size: 14px
        line-height: 21px
        color: $black-2

    .modal-action
        display: flex
        flex-direction: column

        button
            &:first-child
                margin-bottom: 15px
                align-self: center
