*
    padding: 0
    margin: 0
    box-sizing: border-box
    -webkit-tap-highlight-color: transparent
    -webkit-font-smoothing: antialiased

    &:before, &:after
        box-sizing: border-box
        -webkit-tap-highlight-color: transparent

body
    background: $white-2
    font-family: $primary-font
    font-size: 14px
    font-weight: $font-weight-regular
    line-height: 1.4
    color: $black-2
    width: 100% !important
    max-width: 100% !important

    .uf-bubble
        width: 250px

        .uf-close-button
            svg
                width: 15px
                height: 15px

        .uf-bubble-content
            padding-top: 5px
            padding-bottom: 8px

            p span
                font-family: $primary-font !important
                font-size: 15px !important
                color: #757F8A
                background-color: none !important

        .uf-button-primary
            padding-top: 6px
            font-weight: 600
            background: #4BA3E7
            border-color: #4BA3E7

        .uf-free-badge
            display: none

    .uf-right
        position: fixed !important

    .uf-slideout
        display: none !important

ul
    li
        list-style: none
        padding: 0

img
    max-width: 100%

a
    text-decoration: none
    color: inherit
    cursor: pointer

    &:active, &:hover, &:focus
        @extend a

input, button
    &:focus, &:active
        box-shadow: none
        outline: 0

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar
    display: none

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar
    -ms-overflow-style: none
    scrollbar-width: none

.full-width
    width: 100%

.mr-15
    margin-right: 15px

.mr-25
    margin-right: 25px

.mb-10
    margin-bottom: 10px

.mb-15
    margin-bottom: 15px

.mb-20
    margin-bottom: 20px

.mb-25
    margin-bottom: 25px

.mb-30
    margin-bottom: 30px

.mb-35
    margin-bottom: 35px

.mb-50
    margin-bottom: 50px

.mt-30
    margin-top: 30px

.mt-50
    margin-top: 50px

.mw-425
    max-width: 425px

.cw-50
    width: 50%

.cw-35
    width: 35%

.fw-semi-bold
    font-weight: $font-weight-semi-bold

.fw-bold
    font-weight: $font-weight-bold

.text-capitalize
    text-transform: capitalize

.text-lowercase
    text-transform: lowercase !important

.text-center
    text-align: center !important

.text-right
    text-align: right !important

.display-flex
    display: flex

.items-center
    align-items: center

.spinner svg
    margin-bottom: -4px
    animation: spin 1s ease-in-out infinite
    -webkit-animation: spin 1s ease-in-out infinite

    @keyframes spin
        to
            -webkit-transform: rotate(360deg)

    @-webkit-keyframes spin
        to
        -webkit-transform: rotate(360deg)
