.audio-player-container
    position: relative
    @include flex-position(space-between, center)
    width: 100%

    audio
        opacity: 0
        height: 0
        position: absolute
    
    .play
        cursor: pointer
        display: flex
        width: 15px
        flex: 0 0 15px

        svg
            width: 100%
            height: auto

    .player-progress
        width: 100%
        height: 3px
        margin: 0 10px
        background: transparentize($grey-7, 0.3)
        border-radius: 4px
        position: relative
        @include flex-position(initial, center)

        .player-position
            width: 10px
            height: 10px
            border-radius: 50%
            background: $black-2
            position: absolute
            left: 0

            &-line
                position: absolute
                left: 0
                background: $black-2
                height: 3px
                border-radius: 4px 0 0 4px

    .player-duration
        font-size: 12px
        font-weight: $font-weight-medium
        color: $grey-7
        white-space: nowrap