$main-bg: #F5F6F9

$purple: #39206A
$purple-2: #7359ED
$purple-trans: rgba(86, 44, 202, 0.08)
$white: #FFFFFF
$white-2: #E5E5E5
$black: #000000
$black-2: #474F58
$black-trans: rgba(130, 130, 130, 0.4)
$black-trans-2: rgba(130, 130, 130, 0.7)
$blue: #3A89F7
$blue-2: #4BA3E7
$blue-light: #9DA8BB
$blue-light-2: #D7E1EA
$blue-light-3: #EDF6FD
$blue-light-4: #F9FBFD
$blue-trans: rgba(75, 163, 231, 0.3)
$blue-trans-2: rgba(75, 163, 231, 0.1)
$grey: #8F99A6
$grey-2: #F6F7FB
$grey-3: #CAD0D9
$grey-4: #ABB2BC
$grey-5: #E7E5EB
$grey-6: #E6EAF0
$grey-7: #757F8A
$grey-8: #778699
$grey-9: #F1F3F8
$grey-10: #C6C1D0
$grey-11: #F8F8F8
$grey-12: #E6E7EA
$grey-trans: rgba(198, 193, 208, 0.42)
$grey-trans-2: rgba(241, 243, 248, 0.6)
$red: #e24660
$red-2: #D94A10
$green: #92D048
$yellow-trans: rgba(247, 203, 69, 0.3)
$yellow: #F7CB45

$black-3: #262626
$black-4: #2C3F58
$blue-3: #738EB0
$blue-4: #0235DD
$blue-5: #B7D7FF
$blue-light-5: #F0F7FE
$blue-light-6: #E1EEFD
$blue-light-7: #E5E9EE
$blue-light-8: #FBFCFF
$red-trans: #FFD0BC
$green-2: #4EA039
$green-trans: #DCEFD6

$primary-font: "Manrope", sans-serif

$font-weight-regular: 400
$font-weight-medium: 500
$font-weight-semi-bold: 600
$font-weight-bold: 700
$font-weight-extra-bold: 800

$menu-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04)
$conversation-section-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25)
$toggle-display-shadow: 1px 2px 3px rgba(67, 86, 100, 0.123689)
$conversation-list-shadow: inset 0px -1px 0px rgba(202, 208, 217, 0.38)
$user-dropdown-shadow: 0px 4px 30px #EAECEF
$options-dropdown-shadow: 0px 7px 19px rgba(0, 0, 0, 0.16)
$overview-shadow: 0px 4px 14px rgba(0, 0, 0, 0.04)

$header-shadow: -2px 2px 5px rgba(67, 86, 100, 0.123689), -1px 1px 2px rgba(67, 70, 74, 0.0001)
$grid-item-shadow: 0px 3px 20px rgba(115, 142, 176, 0.16)
$comment-form-shadow: 0px 3px 20px rgba(30, 61, 99, 0.16)
$pagination-shadow: 1px 2px 3px rgba(67, 86, 100, 0.123689)
$calendar-shadow: 0px 7px 70px rgba(90, 97, 105, 0.108036), 0px 10px 10px rgba(2, 2, 2, 0.0001), 0px 4px 13px rgba(90, 97, 105, 0.0001)
$toggle-shadow: 1px 2px 3px rgba(67, 86, 100, 0.123689)
$court-session-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1)
$message-options-shadow: 0px 4px 24px rgba(0, 0, 0, 0.12)
