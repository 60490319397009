.insights-page-container
    .insights-section-title
        font-size: 16px
        font-weight: $font-weight-bold

    .overview-section
        display: grid
        grid-template-columns: repeat(3, 1fr)
        grid-gap: 25px
        margin-top: 15px

    .insights-card
        height: 350px
        background: $white
        border: 1px solid $grey-6
        border-radius: 6px
        box-shadow: $overview-shadow
        padding: 10px
        margin-top: 15px

    .response-resolution-container
        margin-top: 25px
        display: grid
        grid-template-columns: repeat(2, 1fr)
        grid-gap: 25px

    .conversations-insights-container, .conversations-tags-container, .agents-performance-container, .customers-engagement-container
        margin-top: 25px

        & > div
            width: 100%

    .insights-chart-container
        border: 1px solid $grey-6
        border-radius: 6px
        padding: 15px 10px
        height: 100%

        &.no-border
            border: 0

        .insights-chart-header
            @include flex-position(space-between, flex-end)
            font-weight: $font-weight-bold

            .average-response-time, .average-resolution-time
                .title
                    display: block
                    font-size: 14px
                    margin-bottom: 5px

                .value
                    font-size: 20px

            .sla
                font-size: 14px
                color: $green

                span
                    display: block

        .insights-chart
            margin-top: 20px
            width: 100%

    .conversations-tags
        height: auto

        .insights-chart-container
            @include flex-position(initial, center)

            .insights-chart
                width: 255px
                flex: 0 0 255px
                margin-right: 85px

    .agents-performance
        height: auto

    .message-logs-analytics-loader
        @include flex-position(center, center)
        padding: 40px 0px
        font-weight: 500
        font-style: italic
        width: 100%
        border: 1px solid #E6EAF0
        border-radius: 6px

    .message-logs-analytics
        display: grid
        grid-template-columns: repeat(5, 1fr)
        grid-gap: 25px
        margin-top: 30px
        margin-bottom: 35px
        padding: 25px
        border: 1px solid #E6EAF0
        border-radius: 6px

        .info
            h6
                margin-bottom: 5px
                font-weight: 600
                font-size: 1em
                color: #474F58
                text-transform: capitalize

            p
                font-size: 1.4em

                span
                    font-size: 0.7em

    .message-logs-container
        padding: 0
        height: auto

        .grid-view
            display: grid
            grid-template-columns: 3fr 1fr 1fr

            &.header
                padding: 8px 15px
                padding-bottom: 10px
                background: $grey-2

                p
                    font-weight: bold
                    opacity: .8

            &.message
                margin-bottom: 10px
                padding: 12px 15px
                border-bottom: 1px solid $grey-6

                &:last-child
                    margin: 0
                    border: none

                .info
                    .type
                        margin-bottom: 7px
                        font-size: 0.95em

                        span
                            padding: 0px 6px
                            padding-bottom: 1px
                            font-weight: 600
                            color: $black-2
                            background: $grey-3

                            &.pending
                                color: $white
                                background: $yellow

                            &.delivered
                                color: $white
                                background: $green-2

                            &.undelivered
                                color: $white
                                background: $red

                    .text
                        margin-bottom: 5px
                        font-size: 0.95em

                        span
                            font-weight: 600

        .loading-container
            @include flex-position(center, center)
            padding: 32px 0px
            font-weight: 500
            font-style: italic

        .pagination-container
            border-bottom: none

.insights-filter
    @include flex-position(flex-start, flex-end)
    flex-wrap: wrap
    gap: 12px
    background: $grey-2
    border-radius: 6px
    padding: 15px
    margin-top: 15px

    .date_range_picker
        @include flex-position(space-between, center)
        padding: 9px 0px 9px 13px
        text-align: center
        background: #fff
        border: 1px solid #E6EAF0
        border-radius: 6px

        svg
            margin-right: 7px
            width: 18px
            height: 18px

            path
                stroke: #CCCCCC

        .date_range_input
            font-family: Manrope,sans-serif
            font-weight: 500
            color: #999
            border: none
            width: 185px

        .input-container .input
            padding: 1px 0px
            height: auto
            border: none

        .close
            @include flex-position(center, center)
            width: 20px
            height: 20px
            cursor: pointer

            svg path
                stroke: #aaaaaa

    .d_flex
        @include flex-position(flex-start, flex-end)
        width: 180px

        .input-container
            width: 180px

        .close
            margin-bottom: 10px
            margin-left: 5px
            padding: 1px 4px 0px 4px
            border-radius: 50%
            background: $white
            cursor: pointer

            svg
                width: 12px
                height: 12px

        .select_container__control
            width: 180px
            border: 1px solid #E6EAF0

    label
        color: $grey

    .select-current
        background: $white

    &-action
        margin: 0 0 0 auto
        width: 110px
        flex: 0 0 110px

        button
            height: 30px

.insights-table
    width: 100%

    .table
        padding-bottom: 0

        table
            tr
                th
                    background: transparent
                    font-size: 14px
                    font-weight: $font-weight-bold
                    color: $grey

                td
                    a
                        display: block

    .tag-name
        @include flex-position(initial, center)

    &-header, &-row
        @include flex-position(space-between, center)
        padding: 20px 5px
        font-size: 14px
        font-weight: $font-weight-bold
        color: $grey
        border-bottom: 1px solid $grey-6

        & > div
            min-width: 110px

            &.text-center
                justify-content: center

            &.text-right
                justify-content: flex-end

        .icon
            display: flex
            margin-left: 5px

    &-row
        padding: 15px 5px
        color: $black-2
        font-weight: $font-weight-semi-bold

        &:last-child
            border-bottom: 0

.most-active-agent
    line-height: 1
    font-weight: $font-weight-bold
    margin-bottom: 20px

    .title
        font-size: 14px
        color: $grey
        display: block
        margin-bottom: 10px

    .value
        font-size: 24px
        color: $black-2

.insights_page_container_new
    padding-bottom: 30px

    .loading
        padding: 60px 12px
        text-align: center
        background: #FFFFFF
        border: 1px solid #E6EAF0
        border-radius: 6px

    .page_title
        margin-bottom: 30px
        font-weight: bold
        font-size: 20px
        color: $black-2

    .filter_container
        @include flex-position(space-between, center)
        margin-bottom: 25px

        .d_flex
            @include flex-position(space-between, center)

            .date_range_picker
                margin-right: 15px

    .date_range_picker
        @include flex-position(space-between, center)
        padding: 9px 0px 9px 13px
        text-align: center
        border: 1px solid #DDDDDD
        border-radius: 6px

        svg
            margin-right: 7px
            width: 18px
            height: 18px

            path
                stroke: #CCCCCC

        .date_range_input
            font-family: Manrope,sans-serif
            font-weight: bold
            color: #999
            border: none
            width: 185px

        .input-container .input
            padding: 1px 0px
            height: auto
            border: none

        .primary-button
            padding-bottom: 2px

    .select_container__control
        width: 250px

    .info_popup_container
        position: absolute
        left: 8px
        padding: 8px 10px
        padding-bottom: 6px
        font-size: 0.8em
        color: #1a1a1a
        width: 200px
        white-space: normal
        border: 1px solid #E6EAF0
        border-radius: 3px
        background: #fff
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.06)
        z-index: 0000000

    .stats_container
        display: grid
        grid-template-columns: repeat(4, 1fr)
        grid-gap: 16px

        @media screen and (max-width: 1320px)
            grid-template-columns: repeat(3, 1fr)

        @media screen and (max-width: 1140px)
            grid-template-columns: repeat(2, 1fr)

    .title_with_icon
        @include flex-position(flex-start, center)
        @include ellipsis
        font-weight: 600
        font-size: 16px
        color: #8F99A6

        span
            display: block
            padding: 0px 4px
            margin-top: 3px
            margin-left: 5px
            cursor: pointer

            svg
                width: 14px
                height: 14px

    .stat_item
        position: relative
        padding: 16px
        border: 1px solid #E6EAF0
        box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.04)
        border-radius: 6px

        .value
            margin-top: 10px
            margin-bottom: 14px
            font-weight: 500
            font-size: 34px
            color: #474F58
            line-height: 100%

        .changes
            @include flex-position(flex-start, center)

            svg
                margin-right: 6px
                padding: 2px
                width: 19px
                height: 19px
                transform: rotate(180deg)
                border-radius: 50%
                background: rgba(217, 16, 16, 0.25)

                path
                    stroke: #D91010

            p
                margin-right: 5px
                font-weight: 500
                font-size: 14px
                color: #D91010

            span
                font-weight: 500
                font-size: 14px
                color: #8F99A6

            &.increment
                svg
                    transform: rotate(0deg)
                    background: rgba(146, 208, 72, 0.25)

                    path
                        stroke: #92D048

                p
                    color: #92D048

    .chart_filter
        margin-top: 15px
        margin-bottom: -5px
        padding-top: 13px
        border-top: 1px solid #E6EAF0

        .date_range_picker
            width: 225px

    .new_conversation_and_channels_charts
        display: grid
        grid-template-columns: 3.3fr 1.7fr
        margin-top: 20px
        padding: 0px
        border: 1px solid #E6EAF0
        box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.04)
        border-radius: 6px

        &.no_grid
            display: block

            .left
                border: none

        .left
            padding: 20px 16px
            border-right: 1px solid #E6EAF0

            .stat_item
                padding: 0px
                border: none
                box-shadow: none
                border-radius: 0px

                .title_with_icon
                    margin-bottom: 15px

                .changes
                    align-items: flex-end

                    .value
                        margin: 0
                        margin-right: 8px
                        margin-bottom: -3px

            .chart
                padding-top: 40px
                height: 400px

        .right
            position: relative
            padding: 20px 16px

            .percentage_toggle
                @include flex-position(flex-start, center)
                margin-top: 16px

                p
                    margin-bottom: 1px
                    margin-left: 7px
                    font-weight: 500
                    font-size: 14px
                    color: #8F99A6

            .chart
                padding-top: 30px
                height: 410px

    .switch_toggler
        @include flex-position(space-between, center)
        padding: 2px
        width: 34px
        height: 20px
        background: #CAD0D9
        border: 1px solid #CAD0D9
        border-radius: 16px
        cursor: pointer

        .side
            width: 16px
            height: 16px
            border-radius: 50%

            &:first-child
                background: $white

            &:last-child
                background: transparent

        &.active
            background: $white

            .side:first-child
                background: transparent

            .side:last-child
                background: #4BA3E7

    .new_conversation_vs_conversations_replied
        position: relative
        margin-top: 20px
        padding: 20px 20px
        border: 1px solid #E6EAF0
        box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.04)
        border-radius: 6px

        .chart
            padding-top: 20px
            height: 470px

    .conversations_status
        margin-top: 20px
        padding: 20px 20px
        padding-bottom: 40px
        border: 1px solid #E6EAF0
        box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.04)
        border-radius: 6px

        .d_flex
            @include flex-position(space-between, center)
            position: relative

            &.d_block
                display: block

                .percentage_toggle
                    margin-top: 16px

        .percentage_toggle
            @include flex-position(flex-start, center)

            p
                margin-bottom: 1px
                margin-left: 7px
                font-weight: 500
                font-size: 14px
                color: #8F99A6

        .chart
            padding-top: 25px
            height: 300px

        .chart_filter
            margin-bottom: -25px

    .responses_and_resolution
        .section_title
            margin-top: 60px
            font-weight: bold
            font-size: 18px
            color: #474F58

        .responses_container
            display: grid
            grid-template-columns: 1.7fr 3.3fr
            margin-top: 24px
            border: 1px solid #E6EAF0
            box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.04)
            border-radius: 6px

            .left
                padding: 24px 16px
                padding-bottom: 30px
                border-right: 1px solid #E6EAF0

                .stat_item
                    margin-bottom: 32px
                    padding: 0px
                    border: none
                    box-shadow: none
                    border-radius: 0px

                    &:last-child
                        margin: 0

                    .title_with_icon
                        margin-bottom: 13px

                    .value
                        margin: 0
                        margin-bottom: 12px

                .breakdown
                    .item
                        margin-bottom: 18px

                        &:last-child
                            margin: 0

                        .figures
                            @include flex-position(space-between, center)
                            margin-bottom: 8px
                            font-weight: 600
                            font-size: 15px
                            color: #8F99A6

                            span
                                font-weight: 600
                                color: #474F58

                        .progress_bar
                            position: relative
                            margin-right: 9px
                            width: 100%
                            height: 10px
                            background: #F6F7FB
                            border-radius: 100px

                            span
                                position: relative
                                display: block
                                height: 100%
                                background: #4BA3E7
                                border-radius: 100px
                                overflow: hidden

            .right
                position: relative
                padding: 24px 16px
                padding-bottom: 30px

                .title_with_icon
                    margin-bottom: 20px

                .chart
                    height: 415px

                .chart_filter
                    margin-bottom: -15px

    .agent_list_container
        padding: 0
        padding-bottom: 20px
        border: 1px solid #E6EAF0
        box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.04)
        border-radius: 6px

        .d_flex
            @include flex-position(space-between, center)
            padding: 16px 16px

            .overview
                p
                    font-weight: bold
                    font-size: 15px
                    color: #8F99A6

                h6
                    margin-top: 12px
                    font-weight: bold
                    font-size: 24px
                    line-height: 100%
                    color: #474F58

        .table_container
            padding: 0px 10px

            .table
                margin-bottom: 10px

                thead tr td
                padding: 10px 16px
                font-weight: bold
                font-size: 14.5px
                text-align: right
                color: #8F99A6
                border-bottom: 1px solid #E6EAF0

                &:first-child
                    text-align: left

                tbody tr td
                    padding: 9px 16px
                    padding-bottom: 12px
                    font-weight: 600
                    font-size: 14.5px
                    text-align: right
                    color: #474F58
                    border-bottom: 1px solid #E6EAF0

                    &:first-child
                        text-align: left

                    &.d_flex
                        @include flex-position(flex-start, center)

                        .agent
                            @include flex-position(center, center)
                            margin-left: -17px
                            margin-right: 15px
                            width: 38px
                            height: 38px
                            font-size: 16px
                            font-weight: bold
                            color: #FFFFFF
                            background: #CAD0D9
                            border: 1px solid #E6EAF0
                            border-radius: 50%

                            &:first-child
                                margin-left: 0

                    &.link
                        color: #4BA3E7
                        text-decoration: underline

    &.chatbot_insights_page_container
        .insights-filter
            background: none
            border-radius: none
            padding: 0
            margin-top: 0
            margin-bottom: 40px
            @include flex-position(space-between, center)

            & > div
                display: flex

            label
                color: $grey

            .date_range_picker
                padding: 0px 0px 0px 13px
                height: 40px
                border: 1px solid #E6EAF0
                background: #fff

                .date_range_input
                    font-weight: 500
                    color: #666666
                    background: transparent
                    height: 39px
                    width: 145px

            .filter-btn
                font-size: 16px
                margin-left: 15px

            &-action
                width: auto
                flex: 0 0 110px

                button
                    font-size: 16px !important
                    height: 40px

        .insights-section-title
            display: block
            margin-bottom: 15px
            font-size: 16px
            font-weight: $font-weight-bold

        .overview-section
            display: grid
            grid-template-columns: repeat(4, 1fr)
            grid-gap: 25px
            margin-top: 30px
            margin-bottom: 30px

            &.time-overview-section
                grid-template-columns: repeat(2, 1fr)

        .insights-card
            height: 100%
            background: $white
            border: 1px solid $grey-6
            border-radius: 6px
            box-shadow: $overview-shadow
            padding: 10px

        .section-divider
            background: #E6EAF0
            margin: 40px 0
            height: 1px
            border: none

        .actions-container
            display: grid
            grid-template-columns: 3fr 2fr
            grid-gap: 25px

        .response-resolution-container
            margin-top: 25px
            display: grid
            grid-template-columns: repeat(2, 1fr)
            grid-gap: 25px

        .conversations-insights-container, .conversations-tags-container, .agents-performance-container, .customers-engagement-container
            margin-top: 25px

            & > div
                width: 100%

        .insights-chart-container
            @include flex-position(space-between, flex-start)
            flex-direction: column
            border: 1px solid $grey-6
            border-radius: 6px
            padding: 15px 10px
            height: 100%

            &.no-border
                border: 0

            .insights-chart-header
                @include flex-position(space-between, flex-end)
                font-weight: $font-weight-bold

            .insights-chart
                margin-top: 20px
                width: 100%

                &.d-flex
                    @include flex-position(flex-start, center)

        .conversations-tags
            height: auto

            .insights-chart
                margin: 0 auto
                margin-top: 15px
                max-width: 255px

        .insights-chart-legend
            @include flex-position(center, center)
            flex-wrap: wrap
            margin-top: 29px

            .legend-label
                @include flex-position(center, center)
                margin: 0px 0px 14px 15px
                padding: 3px 12px
                font-size: 12px
                font-weight: bold
                border-radius: 4px
                text-transform: capitalize

                &:nth-child(1)
                    color: #4CAF50
                    background: rgba(76, 175, 80, 0.2)

                    span
                        background: #4CAF50

                &:nth-child(2)
                    color: #18A0FB
                    background: rgba(24, 160, 251, 0.2)

                    span
                        background: #18A0FB

                &:nth-child(3)
                    color: #7359ED
                    background: rgba(115, 89, 237, 0.2)

                    span
                        background: #7359ED

                &:nth-child(4)
                    color: #3C7BF6
                    background: rgba(60, 123, 246, 0.2)

                    span
                        background: #3C7BF6

                &:nth-child(5)
                    color: #F97272
                    background: rgba(249, 114, 114, 0.2)

                    span
                        background: #F97272

                span
                    margin-right: 10px
                    height: 7px
                    width: 7px
                    border-radius: 50%
                    display: inline-block

        .all-users-container
            .insights-section-title
                @include flex-position(space-between, center)

            .insights-table
                margin-top: 20px
                width: 100%

                .table
                    margin: 0
                    padding-bottom: 0

                    table
                        thead tr
                        padding: 8px 15px
                        padding-bottom: 10px
                        background: #F6F7FB

                        td
                            padding-top: 16px
                            padding-bottom: 16px
                            font-size: 14px
                            color: #474F58
                            font-weight: 500

                            p.lowercase
                                text-transform: lowercase

        .bot-sessions-container
            .insights-section-title
                display: block

                .filters
                    display: flex
                    justify-content: flex-end
                    flex-wrap: wrap
                    gap: 10px
                    margin-top: 12px

                    .select_container__control
                        width: 140px
                        font-weight: 500
                        font-size: 0.9em

                    .select_customer .select_container__control
                        width: 180px

                    .select_container__menu
                        font-weight: 500
                        font-size: 0.9em

                    .icon
                        display: flex
                        justify-content: center
                        align-items: center
                        gap: 4px
                        padding: 5px 8px
                        font-size: 0.9em
                        border: 1px solid hsl(0, 0%, 80%)
                        border-radius: 4px
                        cursor: pointer

                        p
                            font-weight: 400

                            span
                                font-weight: 500

            .insights-table
                table
                    width: 100%
                    min-width: 1250px

                .ellipsis
                    @include ellipsis
                    white-space: nowrap
                    max-width: 150px

                .status
                    padding: 0px 6px
                    padding-bottom: 1px
                    font-size: 0.9em
                    font-weight: 600
                    color: $white

                    &.active
                        background: $green-2

                    &.ongoing
                        background: $yellow

                    &.ended
                        background: $black-4

                .link
                    color: $blue
                    font-weight: 500
                    cursor: pointer

                    &:hover
                        text-decoration: underline
