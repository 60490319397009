.form-notification-container
    @include flex-full-center
    margin-top: 15px
    width: 100%
    border-radius: 3px
    border: 1px solid
    padding: 10px

    .icon
        display: block
        width: 18px
        height: 18px
        margin-right: 10px

        svg
            width: 100% !important
            height: 100% !important

    .message
        @include ellipsis
        display: -webkit-box
        font-size: 12px
        font-weight: $font-weight-semi-bold
        -webkit-line-clamp: 3
        -webkit-box-orient: vertical

    &.success
        border-color: $green-2
        background: $green-trans

        .icon
            svg
                g, path
                stroke: $green-2

        .message
            color: $green-2

    &.error
        border-color: $red
        background: $red-trans

        .icon
            svg
                g, path
                stroke: $red

        .message
            color: $red

    &.notify
        color: $white
        border-color: #EF940C
        background: #EF940C

        .onboarding
            font-weight: bold
            cursor: pointer

            &:hover
                text-decoration: underline

        .separator
            margin: 0px 15px
            font-size: 1.1em

        .action
            margin-left: 5px
            padding: 3px 9px 2px 9px
            font-size: 0.9em
            font-weight: 500
            color: $black
            background: $white
            border-radius: 17px
            box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.13)
            cursor: pointer
