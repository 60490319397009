.body
    display: flex

.main-container
    width: calc(100% - 65px)
    min-height: 100vh
    margin-left: 65px
    background: $grey-2

    .topbar-notification
        margin-top: 0px
        border-radius: 0px

        &.error
            color: $white
            background: #EF940C

.secondary-menu
    width: 240px
    flex: 0 0 240px
    height: 100%
    padding: 0 0 25px
    background: $grey-2
    position: sticky
    position: -webkit-sticky
    top: 0
    transition: all ease-in-out .3s
    z-index: 10
    box-shadow: inset 0px 0px 4px rgba(0, 0, 0, 0.25)

    .toggle-display
        top: 28px
        right: -12px

    .title
        font-size: 20px
        font-weight: $font-weight-bold
        padding: 25px 15px 10px
        transition: opacity .3s ease-in-out .2s

    .secondary-menu-items
        margin-top: 15px
        transition: opacity .3s ease-in-out .2s
        position: relative
        height: calc(100% - 80px)
        overflow: auto

        .item
            width: 100%
            height: 40px
            @include flex-position(space-between, center)
            transition: all ease-in-out .3s
            cursor: pointer
            font-weight: $font-weight-medium
            padding: 0 15px
            margin-bottom: 5px

            & > div
                line-height: 1
                @include flex-position(initial, center)

            .counts
                @include flex-position(flex-end, center)

            .icon
                display: flex
                width: 20px
                margin-right: 10px

                svg
                    width: 100%
                    height: auto

                    path
                        stroke: $black-2

            .unread-count
                margin-right: 15px

            &:hover, &.active
                background: $grey-trans

            &.dead
                background: transparent

                &:hover
                    background: $grey-trans

    &.hideDisplay
        width: 20px
        flex: 0 0 20px
        transition: all .3s ease-in-out .2s

        .title, .secondary-menu-items
            opacity: 0
            transition: opacity ease-in-out .3s
            pointer-events: none

.unread-count
    width: 20px
    height: 20px
    border-radius: 50%
    background: $red
    font-size: 13px
    font-weight: $font-weight-bold
    color: $white
    @include flex-position(center, center)

.toggle-display
    cursor: pointer
    position: absolute
    width: 25px
    height: 25px
    border: 1px solid $blue-light-2
    background: $white
    border-radius: 50%
    box-shadow: $toggle-display-shadow
    transition: all ease-in-out .3s
    @include flex-position(center, center)
    z-index: 10

    svg
        height: auto

        path
            transition: all ease-in-out .3s

    &:hover
        background: $blue

        svg
            path
                fill: $white

    &.active
        transform: rotate(180deg)

.empty-state
    text-align: center
    font-size: 16px
    font-weight: $font-weight-semi-bold
    color: $grey
    line-height: 24px
    margin: auto
    width: 100%
    height: 100%
    @include flex-position(center, center)
    flex-direction: column

    span
        display: flex
        max-width: 285px

    &.small
        font-size: 12px
        line-height: 18px

        span
            max-width: 140px

    .icon
        width: 35px
        margin: 0 auto 25px
        display: flex

        svg
            width: 100%
            height: auto

            path
                stroke: $grey

.loader-container
    @include flex-position(center, center)
    align-self: center
    width: 100%
    height: calc(100vh - 110px)
    overflow: hidden

    &.no-height
        height: 100px

    .animation-wrapper
        width: 250px
        height: 250px

.no-height.load-more
    opacity: 0

    &.active
        opacity: 1

.page-header
    width: 100%
    @include flex-position(space-between, center)
    margin-bottom: 25px

    .title
        font-size: 20px
        font-weight: $font-weight-bold

.page-menu
    position: fixed
    overflow: auto

.page-container
    height: 100%
    display: flex

.page-content
    width: calc(100% - 240px)
    background: $white
    padding: 25px 30px
    margin-left: 240px

    &.full
        width: 100%
        height: 100%
        margin-left: 0
