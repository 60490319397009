.customer-details-page
    .customer-avatar
        margin-right: 10px

        .avatar-container
            @include full-circle(90px, 90px)
            font-size: 34px

    .insights-details-header
        .location
            margin-bottom: 5px

    &.insights-page-container
        .insights-card
            padding: 25px

    .agent-info
        .insights-card
            position: relative

            .actions-container
                @include flex-position(space-between, center)
                margin-bottom: 10px

            .edit-info
                @include flex-position(flex-start, center)
                margin: 0 0 0 auto
                color: $grey
                font-weight: 500
                cursor: pointer

                .icon
                    display: flex
                    width: 15px
                    margin-right: 5px

                    svg
                        width: 100%

                        path
                            stroke: $grey

        &-item
            .customer-channels
                @include flex-position(initial, center)

                img
                    width: 20px
                    margin-right: 10px

                    &.disabled
                        opacity: 0.1

            .input-container
                .input
                    height: 30px

                    &.textarea
                        height: auto

            .ellipse
                @include ellipsis

            .file-input-container
                justify-content: flex-start

                .secondary-button.small
                    display: flex
                    height: 30px

        .agent-info-item
            .value.groups-container
                @include flex-position(flex-start, center)
                flex-wrap: wrap

                .input-container, .select_container
                    width: 100%

                .group
                    @include flex-position(flex-start, flex-start)
                    margin: 0 6px 6px 0
                    padding: 2px 0px 0px 6px
                    width: fit-content
                    background: $grey-6

                    &:last-child
                        margin: 0

                    span
                        padding: 0px 6px 3px 6px
                        margin-left: 8px
                        color: $white
                        background: $red
                        cursor: pointer

                .date_range_picker
                    @include flex-position(space-between, center)
                    padding: 6px 0px 6px 5px
                    text-align: center
                    border: 1px solid #DDDDDD
                    border-radius: 6px

                    svg
                        margin-right: 7px
                        width: 18px
                        height: 18px

                        path
                            stroke: #CCCCCC

                    .date_range_input
                        font-family: Manrope,sans-serif
                        font-weight: bold
                        color: #999
                        border: none
                        outline: none
                        // width: 185px

                    .input-container .input
                        padding: 1px 0px
                        height: auto
                        border: none
                        outline: none

    .customer-conversations
        width: 100%

        .insights-card
            height: auto

        .conversation-history-container
            .history-item
                border: 1px solid $grey-3
                border-radius: 4px

.add-customer-form form .top-fields
    display: grid
    grid-template-columns: 1fr 1fr
    grid-row-gap: 24px
    grid-column-gap: 15px

.add-customer-form form .custom-fields
    display: grid
    grid-template-columns: 1fr
    grid-gap: 24px
    margin-top: 24px

    label
        text-transform: capitalize

    .input-container .input.textarea
        height: auto

    label.file-input-container
        height: auto
        width: 100%

        .secondary-button
            width: 100%
            border: 1px solid #E6EAF0
            border-radius: 6px

    .date_range_picker
        margin: 0
        width: 100%
        border-radius: 6px

        .react-datepicker-wrapper
            width: 100%

            .date_range_input
                width: 100%

.add-customer-form .modal-form-action
    margin-top: 25px

.customers-table
    table
        tr
            th, td
                &:first-child
                    padding-right: 0px

.filter-customers-modal
    .modal-title
        margin-bottom: 20px

    .modal-form
        padding: 20px 15px
        border: 1px solid $grey-5
        border-radius: 6px

        .input-container
            margin-bottom: 24px

            &:last-child
                margin-bottom: 8px

        .date_range_picker
            margin-top: 0px
            width: 100%

            .react-datepicker-wrapper, .date_range_input
                width: 100%

    .modal-action
        button
            height: 45px
