.avatar-container
    width: 25px
    flex: 0 0 25px
    height: 25px
    border-radius: 50%
    overflow: hidden
    background: $white
    border: 1px solid $purple
    color: $black
    font-size: 12px
    @include flex-position(center, center)

    img
        @include image-full-fit(cover, top)

    &.solid
        background: $grey-3
        color: $white
        width: 35px
        flex: 0 0 35px
        height: 35px
        font-size: 15px
        border: 0

.avatar
    width: 30px
    flex: 0 0 30px
    height: 30px
    border-radius: 50%
    overflow: hidden
    background: $grey-3
    color: $white
    @include flex-position(center, center)

    img
        @include image-full-fit(cover, top)