.pdf_generator_container {
  margin: 0 auto;
  padding: 0px 64px;
  width: 100%;
  background: #fff;
}
@media screen and (max-width: 450px) {
  .pdf_generator_container {
    padding: 0px 12px;
  }
}

.pdf_generator_container .content {
  padding-top: 50px;
  min-height: 430px;
}

.pdf_generator_container .content p {
  font-size: 16px;
  letter-spacing: 0.01em;
  line-height: 24px;
  color: #333333;
}

.pdf_generator_container .content .invoice h6 {
  margin-top: 25px;
  margin-bottom: 22px;
  font-weight: 700;
  font-size: 20px;
  color: #333333;
}

.pdf_generator_container .content .invoice .list-div p {
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 12px 0px;
  border-bottom: 1px solid #e6eaf0;
}

.pdf_generator_container .content .invoice .list-div span {
  min-width: 110px;
  text-align: right;
}

.pdf_generator_container .content .invoice .total-div {
  margin: 0 0 0 auto;
  margin-top: 12px;
  max-width: 250px;
}

.pdf_generator_container .content .invoice .total-div div {
  text-align: right;
  margin-top: 49px;
  margin-bottom: 50px;
}

.pdf_generator_container .content .invoice .total-div a {
  padding: 9.5px 24px;
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;
  text-decoration: none;
  background: #4ba3e7;
  border: 1.5px solid #4ba3e7;
  border-radius: 6px;
}

.pdf_generator_container .footer {
  margin-top: 32px;
  padding-top: 24px;
  padding-bottom: 60px;
  border-top: 0.5px solid rgba(0, 0, 0, 0.14);
}

.pdf_generator_container .footer p {
  font-size: 12px;
  letter-spacing: 0.01em;
  line-height: 20px;
  color: #999999;
}
