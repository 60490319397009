.global-search-container
    position: relative

    .global-search
        @include flex-position(space-between, inherit)
        position: relative
        margin-bottom: 20px
        padding: 0
        min-height: 40px
        width: 100%
        font-family: $primary-font
        transition: all ease-in-out .3s
        background: #FFFFFF
        border: 1px solid #E6EAF0
        border-radius: 6px
        z-index: 2

        &.active
            border: 1px solid #4BA3E7

        .search-container-box
            display: flex
            align-items: center
            flex-wrap: wrap
            padding: 7px 12px
            padding-bottom: 0px
            width: 100%
            cursor: text

            .placeholder_text
                margin-bottom: 7px

            .search-item
                @include flex-position(initial, center)
                margin-right: 5px
                margin-bottom: 7px
                font-weight: bold
                font-size: 13.5px
                color: $grey
                background: $grey-2
                border: 1px dashed $grey-5
                border-radius: 6px
                cursor: default
                z-index: 2

                .padding
                    padding: 2px 8px
                    padding-bottom: 3px

                .search-type
                    // background: red

                .search-value
                    font-family: $primary-font
                    font-weight: bold
                    width: 120px
                    max-width: 100%
                    background: $white
                    border: none

                .select_container__control
                    padding: 0px 8px
                    width: 170px
                    max-width: 100%
                    min-height: auto
                    border: none
                    box-shadow: none
                    outline: none
                    background: $white
                    cursor: pointer

                    .select_container__value-container
                        padding: 0

                    .select_container__indicators
                        padding: 0

                        .select_container__indicator-separator
                            display: none

                        .select_container__indicator
                            padding: 0

                .select_container__menu .select_container__option
                    cursor: pointer

                .date_input
                    padding: 2px 8px
                    font-family: $primary-font
                    font-weight: bold
                    width: 120px
                    max-width: 100%
                    background: $white
                    border: none

                .close
                    padding-left: 6px
                    padding-right: 6px
                    border-radius: 0px 6px 6px 0px
                    cursor: pointer

                    svg
                        margin-top: 4px
                        width: 11.5px
                        height: 11.5px
                        padding: 1.5px
                        background: $black
                        border-radius: 50%

                        path
                            stroke-width: 2.5
                            stroke: $white

        .close-search
            @include flex-position(center, center)
            padding: 0px 8px
            padding-top: 3px
            min-height: 40px
            width: 30px
            background: $grey-2
            border-radius: 0 6px 6px 0
            cursor: pointer

    .global-search-options
        position: relative
        margin: 0
        margin-top: -20px !important
        padding: 10px 0px
        background: $white
        border-radius: 6px
        box-shadow: $user-dropdown-shadow
        overflow: hidden
        z-index: 2

        .search-option
            width: 100%
            height: 35px
            padding: 10px 15px
            color: $grey-7
            font-weight: $font-weight-medium
            @include flex-position(initial, center)
            transition: all ease-in-out .3s
            background: $white
            cursor: pointer

            &:hover
                background: $blue-light-3

                span
                    &.label
                        font-weight: bold

            span
                &.label
                    color: $black

                &.hint
                    margin-left: 5px
                    color: $black-trans-2

    .search-results-container
        position: absolute
        top: 45px
        right: 0
        left: 0
        height: 84vh
        background: #F6F7FB
        box-shadow: none
        border-radius: 6px
        overflow: hidden
        padding: 30px 7px 15px 7px
        overflow: auto
        z-index: 1

        .title
            padding: 0
            margin-top: 25px
            margin-left: 5px
            font-size: 16px
            font-weight: 600
            color: $black-2
            text-transform: capitalize

        .sub-title
            font-size: 14px
            font-weight: 600
            color: #8F99A6

            span
                font-weight: 600
                color: $black-2

        .search-results
            margin-top: 15px

            .searching
                margin-top: 20px
                font-size: 1.1em
                font-weight: 500
                text-align: center

                &.load_more
                    font-size: 1.05em
                    text-decoration: underline
                    cursor: pointer

            .search-result
                padding: 12px 12px
                border: none
                border-bottom: 1px solid #E6EAF0
                box-shadow: none

                .highlighted-text
                    background: rgba(247, 190, 31, 0.45)

                &.active
                    background: #EDF6FD !important
                    border: 1px solid #4BA3E7 !important

                &.conversation-item
                    margin-bottom: 10px
                    background: #FFFFFF
                    border: 1px solid #E6EAF0
                    border-radius: 6px

                    &:hover
                        background: #EDF6FD
                        border: 1px solid #4BA3E7

                    &.customer
                        align-items: center

                        .conversation-author
                            margin-top: 3px

                    .content
                        width: calc(100% - 40px)

                        .conversation-item-header
                            .conversation-author
                                font-size: 14px
                                font-weight: $font-weight-bold

                            .conversation-status
                                font-size: 13px
                                font-weight: 600
                                text-align: right
                                color: $blue-2

                            .conversation-time
                                font-size: 12px
                                font-weight: $font-weight-medium
                                color: $grey

                        .conversation-item-preview
                            max-width: 100%
                            font-size: 14px
                            font-weight: $font-weight-medium

                        .conversation-item-footer
                            @include flex-position(flex-start, center)
                            margin-top: 7px
                            font-size: 12px

                            .chat-tag-item
                                @include flex-position(flex-start, center)

                                .tag-icon.small
                                    margin-top: -4px
                                    width: 15px
                                    min-width: 15px
                                    flex: 0 0 15px
                                    height: 15px

                                    svg
                                        margin-top: 5px
                                        margin-left: 5px

                            .dot
                                margin: 0 8px
                                width: 4px
                                height: 4px
                                background: #8F99A6
                                border-radius: 50%

                            .csat
                                @include flex-position(flex-start, center)

                                svg
                                    margin-right: 3px
                                    width: 12px
                                    height: 12px
