.settings-page-container
    .settings-page-info
        border: 1px solid $grey-6
        border-radius: 6px
        padding: 25px
        @include flex-position(space-between, center)

        span
            display: block

        .title
            font-size: 18px
            font-weight: $font-weight-bold
            color: $black

        .mini-title
            font-size: 16px
            font-weight: $font-weight-bold
            line-height: 24px
            color: $black-2
            margin-bottom: 25px

        .description
            font-size: 14px
            font-weight: $font-weight-medium
            color: $black-2
            line-height: 21px
            margin: 10px 0 20px

        .image
            width: 95px
            flex: 0 0 95px
            height: 95px

            img
                width: 100%

    .profile-avatar
        img
            display: block

        .file-input-container
            margin-top: 10px
            justify-content: flex-start
            width: auto
            display: inline-flex

            .upload-link
                color: $blue-2

.channel-modal
    &.big-modal
        .modal-container
            width: 700px

    .modal-content
        padding: 24px

        .modal-form
            min-height: auto

            .d-flex
                @include flex-position(initial, center)
                margin-bottom: 50px

                img
                    padding: 16px
                    margin-right: 27px
                    width: 150px
                    height: 150px
                    background: #F2F2F2
                    border-radius: 8px

                .info
                    h6
                        margin-bottom: 12px
                        font-size: 15px
                        font-weight: bold

                    ul li
                        margin-bottom: 6px
                        font-weight: 500
                        font-size: 14px
                        color: #474F58

                        &:last-child
                            margin-bottom: 0px

            &.instagram_step_one
                .logos
                    margin-bottom: 30px

                h5
                    margin-bottom: 8px
                    font-weight: 500
                    font-size: 18px
                    color: #000000

                h6
                    padding-bottom: 9px
                    font-weight: 600
                    font-size: 15px
                    color: #474F58
                    border-bottom: 1px solid #E6EAF0

                .instagram_requirements
                    margin-top: 16px
                    margin-bottom: 35px

                    ul
                        padding-left: 15px

                        li
                            margin-bottom: 7px
                            font-weight: 500
                            font-size: 15px
                            line-height: 150%
                            color: #757F8A
                            list-style: auto

                            a
                                color: #4BA3E7
                                text-decoration: underline

            .instagram_next_steps
                margin-top: -30px
                margin-bottom: 40px
                margin-left: -10px
                width: 80%

            h6
                font-weight: 600
                font-size: 14px
                color: #474F58

            .logos
                margin-top: 30px
                margin-bottom: 50px

                svg, img
                    margin-right: 6px
                    width: 44px
                    height: 44px

            .facebook_pages
                margin-top: 8px
                margin-bottom: 32px
                padding-top: 24px
                border-top: 1px solid #E6EAF0

                .page
                    @include flex-position(space-between, center)
                    margin-bottom: 10px
                    padding: 8px 10px
                    cursor: pointer

                    &:hover
                        background: #F6F7FB

                    &:last-child
                        margin-bottom: 0

                    h6
                        font-weight: 600
                        font-size: 15px
                        line-height: 150%
                        color: #474F58

                    .check
                        @include flex-position(center, center)
                        width: 28px
                        height: 28px
                        background: #F6F7FB
                        border: 1px solid #E6EAF0
                        border-radius: 50%

                        span
                            width: 14px
                            height: 14px
                            background: #E6EAF0
                            border-radius: 50%

                        &.active
                            border: 1px solid #4BA3E7

                            span
                                background: #4BA3E7

                .error_message
                    color: red
                    font-weight: 500

            .actions
                @include flex-position(space-between, center)

                button
                    padding-bottom: 2px
                    font-size: 14px

                    &.primary-button
                        margin: 0 0 0 auto

                .tertiary-button
                    &.for_instagram
                        margin: 0 auto
                        margin-bottom: 15px
                        padding: 12px 26px
                        font-weight: bold
                        font-size: 14px
                        color: #4BA3E7
                        background: #EDF6FD
                        border-radius: 3.14501px

                .spinner
                    width: 100%
                    text-align: center

                    svg
                        width: 35px
                        height: 35px

.email_setup_modal
    &.import_contact_modal
        width: 100%

        .navigation
            @include flex-position(space-between, center)

            .nav-item
                @include flex-position(center, center)
                width: 33.3%

        .modal_content
            .input-container
                margin: 0 !important

                &.mb-50
                    margin-bottom: 50px !important

                .input
                    padding-top: 8px

        .actions
            margin-top: 35px !important

    .navigation
        @include flex-position(center, center)
        padding: 0px 25px
        border-bottom: 1px solid #eee

        .nav-item
            @include flex-position(flex-start, center)
            padding: 16px 14px
            padding-bottom: 12px
            font-weight: 600
            font-size: 12px
            color: #CAD0D9
            border-bottom: 2px solid #fff

            span
                @include flex-position(center, center)
                margin-top: -2px
                margin-right: 10px
                width: 16px
                height: 16px
                color: #CAD0D9
                background: #E6EAF0
                border-radius: 50%

            &.active
                color: #4BA3E7
                border-bottom: 2px solid #4BA3E7

                span
                    color: #fff
                    background: #4BA3E7

    .email_setup_container
        padding: 24px 27px
        padding-bottom: 33px
        width: 100%

        .header
            padding-bottom: 20px
            margin-bottom: 30px
            border-bottom: 1px solid #E6EAF0

            h5
                margin-bottom: 10px
                font-weight: 500
                font-size: 18px
                color: #000000

            p
                margin: 0
                font-weight: 500
                font-size: 14px
                line-height: 150%
                color: #8F99A6

        .form
            margin-top: 23px

            p
                margin-bottom: 26px
                font-weight: 600
                font-size: 14px
                color: #474F58

            .d-flex
                @include flex-position(flex-start, center)
                margin-bottom: 10px

                .input-container
                    width: -webkit-fill-available

                p
                    margin: 0
                    margin-left: 10px

                h6
                    margin-left: 10px
                    padding: 10px
                    font-weight: 500
                    font-size: 14px
                    color: #474F58
                    background: #F6F7FB
                    border: 1px solid #F6F7FB
                    border-radius: 5px

            .status
                font-weight: 500
                font-size: 14px

                &.error
                    color: #D94A10
                &.success
                    color: #92D048

            .warning
                @include flex-position(flex-start, center)
                margin-top: 20px
                font-weight: 600
                font-size: 14px
                line-height: 150%
                color: #F08B34

                svg
                    margin-right: 8px

        .actions
            @include flex-position(space-between, center)
            margin-top: 50px

            .secondary-button
                margin: 0
                font-weight: bold
                font-size: 15px
                color: #4BA3E7
                cursor: pointer

                &:disabled
                    background: transparent
                    border: 1px solid #4BA3E7
                    opacity: .3

        .tertiary-button
            padding: 9px 20px
            font-weight: bold
            font-size: 14px
            color: #4BA3E7
            background: #EDF6FD
            border-radius: 3.14501px

            &:disabled
                background: #CAD0D9
                color: #F6F7FB

            &.success
                padding: 9px 26px
                padding-bottom: 6px
                background: #DCFFEB

        &.step_one_view_two
            .copy_div
                @include flex-position(flex-start, center)
                margin-top: -10px
                margin-bottom: 30px
                width: 100%

                p
                    padding: 10px 12px
                    margin-right: 5px
                    font-weight: 500
                    font-size: 14px
                    color: #474F58
                    width: inherit
                    background: #F6F7FB
                    border: 1px solid #E6EAF0
                    border-radius: 6px

            .what_to_know
                .header
                    @include flex-position(space-between, center)
                    margin: 0
                    padding: 0
                    border: none
                    cursor: pointer

                    h6
                        font-weight: bold
                        font-size: 14px
                        color: #474F58

                    p
                        font-weight: 600
                        font-size: 12px
                        color: #4BA3E7

                .content
                    margin-top: 17px

                    .list
                        @include flex-position(flex-start, flex-start)
                        margin-bottom: 10px

                        svg
                            margin-right: 10px
                            width: 20px
                            height: 20px
                            transform: rotate(45deg)

                        span
                            font-weight: 600
                            font-size: 13px
                            line-height: 150%
                            color: #757F8A

            .actions
                margin-top: 30px

        &.step_two
            .content
                padding-bottom: 5px

                p
                    margin-top: 17px
                    margin-bottom: 13px
                    font-weight: 500
                    font-size: 13px
                    line-height: 150%
                    color: #474F58

                .d-flex
                    @include flex-position(space-between, center)
                    width: 100%

                    .input-container
                        margin-right: 10px
                        width: -webkit-fill-available

                    .select_container__control
                        margin-right: 10px
                        width: 180px
                        border: 1px solid #E6EAF0

                .images
                    margin-top: 16px
                    margin-bottom: 22px

                    a
                        margin-right: 12px

                        img
                            width: 133px
                            height: 46px
                            object-fit: contain

                table
                    width: 100%
                    border-spacing: 0

                    thead
                        tr
                            th
                                padding: 10px 6px
                                font-weight: bold
                                font-size: 14px
                                color: #8F99A6
                                text-align: left
                                border-bottom: 1px solid #E6EAF0

                    tbody
                        tr
                            td
                                padding: 14px 6px
                                font-weight: 500
                                font-size: 14px
                                color: #474F58
                                border-bottom: 1px solid #E6EAF0

                                svg
                                    margin-top: 5px

                                .copy
                                    margin-left: 10px

                                    svg
                                        margin-top: -5px
                                        margin-bottom: -6px
                                        cursor: pointer

                                        path
                                            stroke: #4BA3E7

                                .tertiary-button
                                    padding: 8px 15px

                .actions
                    margin-top: 20px

                    .success
                        @include flex-position(flex-start, center)
                        margin: 0px
                        font-weight: 600
                        font-size: 14px
                        color: #35C272

                        svg
                            margin-right: 8px

                    .hold_text
                        margin: auto
                        margin-left: 10px
                        font-size: 13px
                        font-weight: bold
                        color: #d6b03f

                    .error_response
                        margin-left: 10px
                        font-size: 12.5px
                        font-weight: 500
                        color: red

        &.step_three
            .input-container
                margin-bottom: 24px

                label
                    font-weight: 600
                    font-size: 14px
                    color: #474F58

            .d-flex
                @include flex-position(space-between, center)
                margin-top: 30px

                .select_container__control
                    margin-right: 10px
                    width: 240px
                    border: 1px solid #E6EAF0

                    &:last-child
                        margin-right: 0

.whatsapp_setup_container
    width: 100%

    &.step_one
        padding: 29px

        .header
            .logos
                @include flex-position(flex-start, center)
                margin-bottom: 20px

                svg
                    margin-right: 10px
                    width: 40px
                    height: 40px

                    &:last-child
                        margin-right: 0px
                        width: 45px
                        height: 45px

            .modal-title
                font-weight: bold
                font-size: 18px
                color: #474F58

        .content
            margin-top: 15px

            .sandbox_info
                margin-top: 24px

                h6
                    font-weight: 500
                    font-size: 16px
                    line-height: 150%
                    color: #474F58

                    span
                        color: #562CCA

                p
                    margin-top: 30px
                    margin-bottom: 24px
                    font-weight: 600
                    font-size: 14px
                    color: #8F99A6

                .send_start_container
                    @include flex-position(center, center)
                    margin-bottom: 52px
                    padding: 12px 12px
                    background: #FFFFFF
                    border: 1px solid #E6EAF0
                    border-radius: 4px

                    p
                        margin: 0 auto
                        text-align: center
                        font-weight: bold
                        font-size: 14px
                        color: #474F58

                    svg
                        text-align: right
                        transform: rotate(-90deg)

            .provider_selection
                p a
                display: block
                margin-top: 7px
                font-weight: 600
                color: #4BA3E7
                width: fit-content

                .providers
                    @include flex-position(flex-start, flex-start)
                    margin-top: 24px

                    .item_container
                        @include flex-position(space-between, flex-start)
                        margin-right: 10px

                        &:last-child
                            margin: 0

                    .item
                        @include flex-position(center, center)
                        flex-direction: column
                        margin-right: 10px
                        padding: 13px 25px
                        padding-bottom: 9px
                        text-align: center
                        height: 100px
                        border: 1px solid #E6EAF0
                        border-radius: 6px
                        cursor: pointer

                        &.selected
                            box-shadow: 0px 4px 30px #EAECEF
                            border: 1px solid #4BA3E7

                        .provider_image
                            height: 30px

                        .provider_name
                            margin-top: 5px
                            font-weight: 600
                            font-size: 14px
                            color: #757F8A

                        .recommended
                            @include flex-position(center, center)
                            margin-top: 5px
                            font-weight: 600
                            font-size: 12px
                            color: #562CCA

                            svg
                                margin-right: 5px
                                width: 15px
                                height: 15px

                                path
                                    fill: #562CCA

                    .environment
                        display: block
                        padding: 17px 15px
                        font-weight: bold
                        text-align: left
                        width: 50%
                        height: auto
                        border: 1px solid #DDDDDD

                        &:last-child
                            margin: 0

                        &.selected
                            box-shadow: 0px 4px 30px #EAECEF
                            border: 1px solid #4BA3E7

                        .image
                            @include flex-position(space-between, flex-start)

                            img
                                margin-bottom: 15px
                                width: 60px
                                height: 60px

                            p
                                font-weight: 600
                                font-size: 13px
                                color: #4BA3E7

                        h6
                            margin-bottom: 8px
                            font-weight: 600
                            font-size: 16px
                            color: #000000

                        p
                            font-size: 0.95em
                            font-weight: 500

                .todos
                    margin-top: 24px

                    .todo
                        @include flex-position(flex-start, center)
                        margin-bottom: 14px
                        padding: 2px
                        background: #FFFFFF
                        border: 1px solid #E6EAF0
                        border-radius: 4px

                        &:last-child
                            margin: 0

                        img
                            width: 100px
                            min-width: 100px
                            height: 45px
                            object-fit: contain
                            border-radius: 4px 0px 0px 4px

                        p
                            margin-left: 22px
                            font-weight: 600
                            font-size: 14px
                            color: #474F58

        .actions
            @include flex-position(space-between, center)
            margin-top: 40px

    &.step_two
        padding: 24px

        .header
            .modal-title
                margin-bottom: 5px
                font-weight: bold
                font-size: 18px
                color: #474F58

            .text-link
                font-weight: 500
                font-size: 12px
                width: fit-content
                color: #4BA3E7
                cursor: pointer

                &:hover
                    text-decoration: underline

        .sandbox_setup_form
            margin-top: 24px

            .form_field
                margin-bottom: 32px

                span
                    display: block
                    margin-top: 5px
                    font-weight: 500
                    font-size: 13px
                    color: #757F8A

            .success
                @include flex-position(flex-start, center)
                margin: 0px
                font-weight: 600
                font-size: 14px
                color: #35C272

                svg
                    margin-right: 8px

            .error
                margin-top: -30px
                margin-bottom: 40px
                font-weight: 500
                font-size: 13px
                line-height: 150%
                color: red

            .actions
                margin-bottom: 40px

        .setup_form
            margin-top: 24px

            .step
                padding-top: 16px
                padding-bottom: 16px
                border-top: 1px solid #E6EAF0
                opacity: .5

                &.active
                    opacity: 1

                .header
                    @include flex-position(space-between, center)
                    margin: 0
                    padding: 0
                    border: none
                    cursor: pointer

                    h6
                        font-weight: bold
                        font-size: 14px
                        color: #474F58

                        svg
                            margin-bottom: -6px
                            margin-left: 5px

                .content
                    padding-bottom: 16px

                    p
                        margin-top: 8px
                        margin-bottom: 20px
                        font-weight: 500
                        font-size: 13px
                        line-height: 150%
                        color: #474F58

                    .form_field
                        margin-bottom: 24px

                        label
                            display: block
                            margin-bottom: 5px
                            font-weight: 600
                            color: #474F58

                        span
                            display: block
                            margin: 0
                            margin-top: 3px
                            font-weight: 500
                            font-size: 12px
                            color: #757F8A

                    .d-flex
                        @include flex-position(space-between, center)
                        width: 100%

                        .select_container__control
                            margin: 0px
                            width: 75px
                            background: #E6EAF0
                            border: 1px solid #E6EAF0
                            border-radius: 6px 0px 0px 6px

                            .select_container__indicator-separator
                                display: none

                            .select_container__dropdown-indicator
                                padding: 0
                                padding-right: 5px

                        .input-container
                            margin-right: 10px
                            width: -webkit-fill-available

                            input
                                height: 38px
                                border-radius: 0px 6px 6px 0px

                    .error
                        margin-top: 8px
                        font-weight: 500
                        font-size: 13px
                        line-height: 150%
                        color: red

                    .copy_div
                        margin-bottom: -10px

                        h6
                            margin-bottom: -14px
                            font-size: 1em

                        .inner
                            @include flex-position(flex-start, center)
                            margin-top: 24px
                            margin-bottom: 30px
                            width: 100%
                            background: #F6F7FB
                            border: 1px solid #E6EAF0
                            border-radius: 6px

                            p
                                margin: 0
                                margin-right: 5px
                                padding: 10px 12px
                                font-weight: 500
                                font-size: 14px
                                color: #474F58
                                width: inherit

                            .tertiary-button
                                padding: 13px 12px
                                padding-bottom: 9px
                                background: transparent

                            svg path
                                stroke: #4BA3E7

                .actions
                    .success
                        @include flex-position(flex-start, center)
                        margin: 0px
                        font-weight: 600
                        font-size: 14px
                        color: #35C272

                        svg
                            margin-right: 8px

        .actions
            @include flex-position(space-between, center)

    &.step_four
        display: flex
        flex-direction: column
        align-items: center
        padding: 66px 28px
        padding-bottom: 47px

        h6
            margin-block-end: 14px
            font-weight: bold
            font-size: 19px
            color: #474F58

        p
            margin-bottom: 35px
            font-weight: 600
            font-size: 16px
            line-height: 150%
            color: #474F58

            &.center
                text-align: center

        .actions
            flex-direction: row

            .text-button
                display: block
                margin: 0 auto
                margin-top: 20px
                font-weight: bold
                font-size: 15px
                color: #4BA3E7

            .primary-button
                margin: 0 auto
                margin-top: 20px

    .no_api_key
        margin-top: 20px !important
        margin-bottom: 0px
        padding: 14px 21px
        color: $black-2 !important
        background: #F6F7FB
        border-radius: 6px

        // &.warning
        //     background: $yellow-trans
        //     color: $black !important

        a
            color: $blue
            font-weight: 600

    .tertiary-button
        padding: 10px 26px
        padding-bottom: 12px
        font-weight: bold
        font-size: 14px
        color: #4BA3E7
        background: #EDF6FD
        border-radius: 3.14501px

        &:disabled
            background: #CAD0D9
            color: #F6F7FB

        &.success
            padding: 9px 26px
            padding-bottom: 6px
            background: #DCFFEB

.sms_setup_container
    .copy_div
        @include flex-position(flex-start, center)
        margin-top: 24px
        margin-bottom: 8px
        width: 100%

        p
            padding: 7.5px 12px
            margin-right: 5px
            font-weight: 500
            font-size: 14px
            color: #474F58
            width: inherit
            background: #F6F7FB
            border: 1px solid #E6EAF0
            border-radius: 6px

        .tertiary-button
            padding: 9px 16px
            font-weight: bold
            font-size: 14px
            color: #4BA3E7
            background: #EDF6FD
            border-radius: 3.14501px

.live-chat-container
    width: 100%

    .modal-title
        margin-bottom: 10px
        font-weight: 500
        font-size: 18px
        color: #000000

    h6
        margin: 0
        margin-bottom: 30px
        padding-bottom: 20px
        font-weight: 400
        font-size: 14px
        line-height: 150%
        color: #8F99A6
        border-bottom: 1px solid #E6EAF0

    .actions
        margin-top: 40px

.avatar-resize-container
    .modal-form
        margin-top: 35px

        .modal-form-action
            justify-content: flex-end

            button
                &:first-child
                    margin-right: 10px

.agents-table
    .customer-name
        @include flex-position(initial, center)
        cursor: pointer

        .avatar-container
            margin-right: 10px

    .filter-button
        margin-right: 15px
        padding-bottom: 2px
        font-weight: 600
        font-size: 14px
        color: #8F99A6
        border: 1.5px solid #8F99A6
        border-radius: 6px

        svg
            fill: #8F99A6

    .agent-channels
        img
            width: 20px
            margin-right: 5px

            &:last-child
                margin-right: 0

            &.disabled
                opacity: 0.3
                filter: grayscale(1)

    .pending-status
        height: 20px
        display: inline-block
        padding: 5px
        background: $grey-6
        border-radius: 2px
        font-size: 12px
        font-weight: $font-weight-semi-bold
        color: $grey
        margin-left: 15px
        line-height: 1

    .pending
        opacity: 0.3
        filter: grayscale(1)

.agent-form
    margin-top: 30px

    .loading-users
        font-size: 12px
        font-weight: $font-weight-semi-bold
        color: $grey
        display: block
        margin: 15px 15px
        text-align: center

.tag-form
    margin-top: 40px

.saved-response-modal-title
    justify-content: space-between !important

    .toggle-switch
        display: flex
        align-items: center
        font-size: 16px
        color: #757F8A
        cursor: pointer

        span
            padding-right: 13px

        .switch
            position: relative
            display: inline-block
            width: 33px
            height: 18px

            input
                opacity: 0
                width: 0
                height: 0

            .slider
                position: absolute
                cursor: pointer
                top: 0
                left: 0
                right: 0
                bottom: 0
                background-color: #ccc
                -webkit-transition: .4s
                transition: .4s

            .slider:before
                position: absolute
                content: ""
                height: 10.5px
                width: 11px
                left: 4px
                bottom: 4px
                background-color: white
                -webkit-transition: .4s
                transition: .4s

            input:checked + .slider
                background-color: #92D048

            input:focus + .slider
                box-shadow: 0 0 1px #92D048

            input:checked + .slider:before
                -webkit-transform: translateX(13px)
                -ms-transform: translateX(13px)
                transform: translateX(13px)

            /* Rounded sliders */
            .slider.round
                border-radius: 34px

            .slider.round:before
                border-radius: 50%

.saved-response-form
    margin-top: 40px

    .file-input-container
        input
            padding-top: 8px

    .input-container
        label
            text-transform: capitalize

        .input.textarea
            padding-top: 8px
            padding-bottom: 38px
            height: 100px

        .template-options__control
            border: 1px solid #E6EAF0
            cursor: pointer

        .textarea-actions
            background: #fff

    .sun-editor
        margin-bottom: 70px
        height: 120px
        border: 1px solid #E6EAF0
        border-top: none
        border-left: none
        border-right: none

        .se-toolbar
            position: relative
            bottom: auto
            height: auto
            border: 1px solid #E6EAF0
            border-bottom: none
            background: transparent

            .se-btn-module
                margin-bottom: 5px

        .se-wrapper-inner
            border: 1px solid #E6EAF0

    .error_message
        margin-top: -20px
        margin-bottom: 35px
        color: red
        font-weight: 500
        font-size: 0.95em

    .info
        margin-bottom: 15px

        &:first-child
            margin-bottom: 25px

        p
            margin-bottom: 7px
            font-weight: 500
            font-size: 0.95em

        h5
            font-weight: 400
            font-size: 1.05em

        .listing
            @include flex-position(flex-start, flex-start)
            flex-wrap: wrap

            .list-item
                @include flex-position(flex-start, center)
                margin-right: 12px
                margin-bottom: 10px
                padding: 5px 10px
                border: 1px solid $grey-3

                span
                    padding-top: 3px
                    margin-left: 8px
                    cursor: pointer

                    svg path
                    stroke: $red

    .loading
        font-size: 1.05em
        font-weight: 500
        text-align: center

    .all-options
        margin-top: 18px
        padding-top: 12px
        border-top: 1px solid #E7E7E7

        p
            margin-bottom: 10px
            font-weight: 500
            font-size: 14px
            color: #8F99A6

        .option
            display: flex
            align-items: center
            margin-bottom: 10px

            .input-container
                width: calc( 100% - 20px )

            span
                margin-left: 10px
                padding-top: 5px
                cursor: pointer

                svg
                    width: 20px
                    height: 20px

        .add-option
            display: flex
            justify-content: space-between
            align-items: center
            margin-top: 18px
            margin-bottom: 24px
            padding: 6px 12px
            background: #F5F5F5
            border-radius: 6px
            cursor: pointer

    .buttons-label
        margin-bottom: 12px
        font-weight: 600
        color: #474F58

    .template-button-fields
        margin-bottom: 18px

        .item
            margin-bottom: 15px

            &:last-child
                margin: 0

            .type
                margin-bottom: 5px
                font-weight: 600
                font-size: 1em
                text-decoration: underline

            .flex
                @include flex-position(flex-start, center)
                gap: 16px

                .trash
                    cursor: pointer

                .form-row
                    width: 100%

            .input-container label
                font-weight: 500

    .template-button-options
        margin-bottom: 16px
        padding-bottom: 40px
        border-bottom: 1px solid #eee

        p
            margin-bottom: 5px
            font-weight: 500
            font-size: 1em
            font-style: italic

        .all-buttons
            @include flex-position(flex-start, center)
            gap: 18px

            .secondary-button
                padding-bottom: 1px
                font-size: 1em
                height: 32px

.saved-responses-table
    .saved-response-message
        @include flex-position(initial, center)

        &.link
            color: $blue-2
            font-weight: 500
            text-decoration: underline
            width: fit-content
            cursor: pointer

        .name
            @include flex-position(initial, center)
            position: relative
            padding-right: 10px
            margin-right: 5px
            font-weight: $font-weight-bold

            &::after
                content: ''
                position: absolute
                @include full-circle(5px, 5px)
                background: $black-2
                right: 0px

        .content
            position: relative
            max-width: 150px
            max-height: 20px
            @include ellipsis

    .settings-empty-state
        .description
            text-align: center

.manage-categories
    padding: 25px 0

    .category-items
        li
            font-size: 14px
            font-weight: $font-weight-medium
            border-bottom: 1px solid $grey-6
            height: 50px
            line-height: 21px
            padding: 0 25px
            color: $black-3
            cursor: pointer
            transition: all ease-in-out .3s
            white-space: nowrap
            @include flex-position(space-between, center)

            &:last-child
                border-bottom: 0

            &:hover
                background: $blue-light-3

            &.disabled
                pointer-events: none
                opacity: 0.3

            &.add-new-item
                color: $blue-2
                @include flex-position(initial, center)

                .icon
                    display: flex
                    margin-right: 10px
                    width: 15px
                    height: 15px

                    svg
                        width: 100%
                        height: auto

                        path
                            stroke: $blue-2

            &.item-input
                padding: 5px 25px

                &:hover
                    background: transparent

                input
                    background: $grey-2
                    border: 0
                    border-radius: 4px
                    padding: 5px 10px
                    width: 100%
                    height: 100%
                    color: $black-3
                    margin-right: 15px

.tag-highlights
    @include flex-position(space-between, center)
    width: 100%

    span
        @include full-circle(20px, 20px)
        @include flex-position(center, center)
        cursor: pointer
        margin-right: 10px

        &:last-child
            margin-right: 0

        svg
            opacity: 0
            transition: all ease-in-out .3s

        &.active
            svg
                opacity: 1

        &.inactive
            opacity: 0.6

.tags-table
    .tag-name
        @include flex-position(initial, center)

.tag-icon
    @include full-circle(35px, 35px)
    @include flex-position(center, center)
    margin-right: 10px
    position: relative

    .tag-bg
        content: ""
        @include full-absolute
        @include full-circle(35px, 35px)
        opacity: 0.2

    svg
        width: 20px
        height: auto

        path
            stroke-width: 3px

    &.small
        @include full-circle(20px, 20px)

        .tag-bg
            @include full-circle(20px, 20px)

        svg
            width: 10px !important

.integrations-container
    background: $white
    border-radius: 6px
    border: 1px solid $grey-6
    padding: 25px

    .search-integrations
        max-width: 545px
        margin-bottom: 35px

    .integration-section
        margin-bottom: 45px
        border-bottom: 1px solid #E6EAF0

        &:last-child
            margin-bottom: 0px
            border-bottom: none

        &-title
            font-size: 16px
            font-weight: $font-weight-bold
            color: $black-2

        &-content
            margin-top: 25px
            margin-bottom: 60px
            display: grid
            grid-template-columns: repeat(4, 1fr)
            grid-gap: 24px

            @media screen and (max-width: 1310px)
                grid-template-columns: repeat(3, 1fr)

            &.d-block
                display: block

            .integration-item
                background: $white
                border-radius: 6px
                border: 1px solid $grey-6
                padding: 15px
                padding-bottom: 20px
                cursor: pointer
                transition: all ease-in-out .3s

                &:hover
                    border-color: $blue-2
                    box-shadow: $user-dropdown-shadow

                .logo
                    display: flex
                    width: 38px
                    margin-bottom: 12px

                    img
                        width: 100%

                .title
                    margin-bottom: 3px
                    font-size: 15px
                    font-weight: $font-weight-bold
                    color: $black-2

                .type
                    font-size: 12px
                    font-weight: $font-weight-medium
                    color: $grey-7

                .description
                    @include ellipsis
                    margin-top: 8px
                    font-size: 13.5px
                    font-weight: $font-weight-medium
                    color: $black-2
                    line-height: 21px
                    display: -webkit-box
                    -webkit-line-clamp: 3
                    -webkit-box-orient: vertical
                    white-space: pre-wrap

                &.channel-item
                    @include flex-position(initial, center)
                    padding: 15px 16px

                    img
                        margin-bottom: 0
                        margin-right: 10px
                        width: 32px
                        height: 32px

                    &.coming-soon
                        background: #FFF9E6
                        border: 1px solid #FFF9E6

                        img
                            margin-right: -15px

                        .info
                            margin-left: 25px

                            .title
                                text-transform: inherit

                    .info
                        .title
                            margin-bottom: 2px
                            font-weight: bold
                            font-size: 14px
                            color: #474F58
                            text-transform: capitalize

                        .primary-link
                            margin-top: 0px
                            font-weight: 600
                            font-size: 12px
                            color: #4BA3E7

                            &:hover
                                text-decoration: none

                        .disabled
                            font-weight: 600
                            color: $red

            .active-channel-item
                margin-bottom: 24px

                &:last-child
                    margin-bottom: 0px

                .channel-title
                    @include flex-position(space-between, center)
                    padding: 15px 16px
                    background: #FFFFFF
                    box-shadow: inset 0px -1px 0px #D0D9F2, inset 0px 1px 0px #D0D9F2, inset 1px 0px 0px #D0D9F2, inset -1px 0px 0px #D0D9F2
                    border-radius: 6px 6px 0px 0px

                    .left
                        @include flex-position(initial, center)

                        img
                            margin-right: 10px
                            width: 24px
                            height: 24px

                        .title
                            font-weight: 600
                            font-size: 14px
                            color: #474F58

                        .note
                            padding-top: 2px
                            margin-left: 5px
                            color: $yellow
                            font-size: 13px
                            font-weight: 600

                    .primary-link
                        font-weight: 600
                        font-size: 14px
                        color: #4BA3E7

                        &:hover
                            text-decoration: none

                .channel-info
                    .list-item
                        @include flex-position(space-between, center)
                        padding: 6px 16px
                        padding-bottom: 9px
                        background: #F6F7FB
                        box-shadow: inset 0px -1px 0px #E6EAF0, inset 1px 0px 0px #E6EAF0, inset -1px 0px 0px #E6EAF0
                        border-radius: 0px

                        &:last-child
                            border-radius: 0px 0px 6px 6px

                        h6
                            margin: 0
                            font-weight: 600
                            font-size: 14px
                            color: #757F8A

                        .right
                            @include flex-position(initial, center)

                            p
                                margin: 0
                                margin-right: 16px
                                font-weight: 600
                                font-size: 14px
                                color: #757F8A

                            .options-dropdown-container
                                .status
                                    @include flex-position(initial, center)
                                    padding: 3px 6px
                                    padding-bottom: 4px
                                    font-weight: 600
                                    font-size: 11px
                                    color: #FFFFFF
                                    background: #92D048
                                    border-radius: 4px

                                    svg
                                        margin-bottom: -2px
                                        margin-left: 5px

                                .options-menu ul li
                                    padding: 9px 10px

                                    &:hover
                                        background: rgba(75, 163, 231, 0.1)

    .no-channels
        margin: 50px auto
        font-weight: 500
        font-size: 14px
        text-align: center
        width: 486px
        color: #757F8A

        svg
            margin-bottom: 16px

.integration-details-page
    .back
        @include flex-position(initial, center)
        cursor: pointer
        width: fit-content

        &.channels-back
            margin-bottom: 30px
            width: fit-content

        .icon
            display: flex
            margin-right: 10px

            svg
                width: 11px
                height: 11px

        .text
            font-size: 16px
            font-weight: $font-weight-semi-bold
            color: $black-2

    .integration-details-header
        @include flex-position(space-between, center)
        margin: 50px 0
        padding: 24px
        border: 1px solid #E6EAF0
        border-radius: 6px

        .item-info
            @include flex-position(initial, center)

            .logo
                display: flex
                width: 60px
                height: 60px
                margin-right: 15px

                img
                    width: 100%

            .title
                font-size: 20px
                font-weight: $font-weight-bold
                color: $black
                margin-bottom: 5px

            .type
                font-size: 12px
                font-weight: $font-weight-medium
                color: $grey-7

        .item-description
            margin-top: 15px
            font-size: 14px
            font-weight: $font-weight-medium
            color: $black-2
            line-height: 21px

        .actions
            margin-left: 50px

    .integration-details-content
        @include flex-position(flex-start, flex-start)
        padding: 24px
        border: 1px solid #E6EAF0
        border-radius: 6px

        .overview
            padding-right: 28px
            width: 65%

        .details
            padding-left: 28px
            width: 35%
            border-left: 1px solid #E6EAF0

        .title
            margin-bottom: 24px
            font-size: 16px
            font-weight: $font-weight-bold
            color: $black

        .section-title
            margin-bottom: 4px
            font-size: 14px
            font-weight: $font-weight-bold

        .section-content
            margin: 0
            font-size: 14px
            font-weight: $font-weight-medium
            line-height: 23px

        .info
            margin-bottom: 20px

        .categories
            display: flex
            flex-wrap: wrap
            margin-top: 8px

            .section-content
                margin: 0 8px 8px 0
                padding: 3px 10px
                padding-bottom: 4px
                font-size: 13px
                border: 1px solid #CAD0D9
                border-radius: 5px

        &.channels-setup-form
            margin-top: 16px
            padding: 16px 28px
            max-width: 100%
            background: $white
            border: 1px solid #E6EAF0
            box-sizing: border-box
            border-radius: 6px

            &.current
                padding-top: 30px
                padding-bottom: 40px

            .title
                @include flex-position(space-between, center)
                margin-bottom: 0px
                font-size: 16px

                .completed
                    @include flex-position(flex-start, center)
                    font-weight: 600
                    font-size: 14px
                    color: #35C272
                    display: none

                    &.visible
                        display: flex

                    svg
                        margin-right: 10px

            .integration-setup-form
                max-width: 525px

            .channel-setup-form
                margin-top: 25px
                display: none

                .input-container
                    max-width: 525px

                    .primary-link
                        margin-left: 4px

                &.show
                    display: block

                    &.flex-div
                        display: flex

                &.flex-div
                    @include flex-position(flex-start, flex-start)
                    display: none

                    .qr-code
                        margin-right: 20px
                        padding: 16px
                        width: 130px
                        height: 130px
                        background: #F2F2F2
                        border-radius: 8px

                .actions
                    @include flex-position(flex-start, center)

                button
                    padding-bottom: 2px
                    font-size: 14px

                    &.green
                        margin-right: 20px

                    &:disabled
                        background: rgba(75, 163, 231, 0.6)

                .description
                    font-weight: 600
                    font-size: 14px
                    color: #757F8A

                .message
                    margin-top: 15px
                    margin-bottom: 35px
                    font-weight: 500
                    font-size: 14px
                    color: #474F58

                    span
                        font-weight: 600
                        text-decoration: underline

                .alert
                    @include flex-position(flex-start, center)
                    padding: 9px 16px
                    padding-bottom: 10px
                    width: 100%
                    font-weight: 500
                    font-size: 14px
                    border-radius: 6px

                    svg
                        margin-right: 10px

                    &.success
                        color: #474F58
                        background: #EDF6FD

                    &.error
                        color: #E24660
                        background: #FDEDED

    .primary-link
        &.last
            margin-left: 24px

.omnichannel-container
    .page-header
        @include flex-position(initial, center)

        .back
            @include flex-position(initial, center)
            margin-right: 20px
            cursor: pointer

            svg
                width: 17px
                height: 17px

                path
                    stroke: $black-2
                    stroke-width: 0.1px

    .integrations-container
        @include flex-position(space-between, flex-start)

        .integration-section-title
            margin-bottom: 25px
            font-size: 19px

    .widget-details
        width: 100%
        max-width: 50%
        margin-right: 35px

    .input-container label
        margin-bottom: 12px

    .input-container.color-picker-container
        .color-picker-preview
            right: 10px

    .logo-container
        .logo-uploader
            @include flex-position(initial, center)

            .logo-preview
                width: 65px
                flex: 0 0 65px
                height: 65px
                border-radius: 8px
                @include flex-position(center, center)
                margin-right: 18px
                object-fit: cover

            p
                font-weight: 500
                font-size: 12px
                line-height: 150%
                color: #757F8A
                max-width: 215px

        label.file-input-container
            margin-top: 15px
            justify-content: flex-start !important
            width: fit-content

    .widget-channels
        margin: 30px 0
        padding: 30px 0
        border-top: 1px solid $grey-6
        border-bottom: 1px solid $grey-6

        &.no-bottom-border
            padding-bottom: 5px
            border-bottom: none

        .widget-channel-item
            @include flex-position(space-between, center)
            margin-top: 30px
            padding-left: 20px
            border-left: 5px solid $grey-6

            &:first-child
                margin-top: 0

            & > div
                @include flex-position(initial, center)

            .channel-details
                @include flex-position(initial, center)

                .icon
                    display: flex
                    width: 25px
                    margin-right: 15px

                    svg
                        width: 100%

                .text
                    font-size: 14px
                    font-weight: $font-weight-semi-bold
                    color: $black-2

            .select-container
                margin: 0

                .input-icon-container
                    min-width: 200px
                    max-width: 100%

            .clear-selection
                margin-left: 6px
                cursor: pointer

            &.disabled
                opacity: 0.3

    .widget-code-snippet
        margin-bottom: 30px
        padding-bottom: 40px
        border-bottom: 1px solid $grey-6

        .integration-section-title
            margin-bottom: 10px

        .description
            font-size: 14px
            font-weight: $font-weight-medium
            color: $grey-7
            line-height: 21px

        .code-snippet
            background: $black
            color: $grey-3
            padding: 15px
            border-radius: 4px
            margin-top: 30px
            word-break: break-word

            .copy-code
                @include flex-position(center, center)
                display: inline-flex
                height: 30px
                padding: 0 10px
                border-radius: 4px
                color: $white
                font-size: 12px
                font-weight: $font-weight-semi-bold
                background: $black-trans
                margin-bottom: 25px
                cursor: pointer

                .icon
                    display: flex
                    width: 20px
                    margin-right: 10px

                    svg
                        width: 100%

            code
                display: block

        &.save-configs
            margin: 0
            padding-bottom: 0
            border: none

            .primary-button
                margin-top: 30px
                margin-bottom: 40px
                padding: 0 35px
                height: 45px

    .widget-preview
        width: 410px
        flex: 0 0 410px

.billing-page-container
    .loading
        padding: 60px 12px
        text-align: center
        background: #FFFFFF
        border: 1px solid #E6EAF0
        border-radius: 6px

    .section_container
        margin-bottom: 40px
        padding: 15px 24px
        padding-bottom: 0px
        background: #FFFFFF
        border: 1px solid #E6EAF0
        border-radius: 6px

        .header
            @include flex-position(space-between, center)
            padding-bottom: 16px
            border-bottom: 1px solid #F6F7FB

            p
                margin: 0
                font-weight: bold
                font-size: 19px
                color: #000000

        .content
            .info_container
                @include flex-position(space-between, center)
                padding-top: 15px
                padding-bottom: 20px
                border-bottom: 1px solid #F6F7FB

                &:last-child
                    border-bottom: none

                h4
                    margin-bottom: 15px
                    font-weight: bold
                    font-size: 18px
                    color: #8F99A6

                    &.plan_name
                        text-transform: capitalize

                h6
                    margin-bottom: 10px
                    font-weight: bold
                    font-size: 14px
                    color: #8F99A6

                .agents_container
                    @include flex-position(flex-start, center)

                    .agents
                        @include flex-position(center, center)
                        margin-right: 10px

                        .agent
                            @include flex-position(center, center)
                            margin-left: -17px
                            width: 36px
                            height: 36px
                            font-size: 16px
                            font-weight: bold
                            color: #FFFFFF
                            background: #CAD0D9
                            border: 1px solid #E6EAF0
                            border-radius: 50%

                            &:first-child
                                margin-left: 0

                .progress_bar_div
                    @include flex-position(flex-start, center)

                    .progress_bar
                        position: relative
                        margin-right: 9px
                        width: 352px
                        height: 9px
                        background: #F5F5F5
                        border-radius: 100px

                        span
                            position: relative
                            display: block
                            height: 100%
                            background: #92D048
                            border-radius: 100px
                            overflow: hidden

                    .counter

                .cost_div
                    font-weight: bold
                    font-size: 24px
                    color: #8F99A6

                    span
                        font-size: 14px

            .no_data
                margin: 0 auto
                padding: 50px 12px
                padding-bottom: 55px
                font-weight: 500
                font-size: 15px
                line-height: 150%
                text-align: center
                color: #757F8A
                max-width: 416px

            .cards
                @include flex-position(flex-start, flex-start)
                flex-wrap: wrap

                .card_info_container
                    margin-top: 25px
                    margin-right: 15px
                    padding: 16px
                    padding-top: 6px
                    padding-bottom: 0px
                    width: 350px
                    border: 1px solid #E6EAF0
                    border-radius: 6px

                    .card_header
                        @include flex-position(flex-start, center)
                        padding-bottom: 6px
                        border-bottom: 1px solid #E6EAF0

                        img
                            margin-right: 5px
                            margin-left: -10px
                            width: 60px
                            border-radius: 5px

                        p
                            font-weight: 600
                            font-size: 15px
                            color: #757F8A

                    .card_body
                        @include flex-position(space-between, center)
                        padding-top: 11px
                        padding-bottom: 12px
                        border-bottom: 1px solid #E6EAF0

                        p
                            font-weight: 600
                            font-size: 15px
                            color: #757F8A
                            text-transform: capitalize

                            span
                                font-weight: 400
                                text-transform: none

                    .card_footer
                        @include flex-position(flex-end, flex-end)
                        padding: 8px 0px

                        .primary-button
                            padding: 0 25px
                            height: 35px
                            font-size: 15px
                            font-weight: 600

                        .tertiary-button
                            display: flex
                            justify-content: center
                            align-items: center
                            margin-left: 10px
                            background: #E6EAF0
                            border-radius: 6px
                            color: #474F58
                            width: auto
                            padding: 0 25px
                            height: 35px
                            font-size: 15px
                            font-weight: 600

            .card_warning
                margin-top: 15px

            .card_notification
                margin-top: -23px
                margin-bottom: 30px
                font-size: 0.9em !important
                font-weight: 600 !important

            .actions
                @include flex-position(flex-start, flex-start)
                margin-top: 20px
                margin-bottom: 30px

                button
                    margin-right: 20px

                    &:last-child
                        margin-right: 0

            &.invoice-table-container .table table
                @media screen and (max-width: 1465px)
                    width: 1050px

            .table
                margin-bottom: 0

                thead tr th
                color: #8F99A6
                background: #fff

                &:first-child
                    padding-left: 0px

                tbody tr td
                    padding: 15px 25px
                    color: #474F58
                    background: #fff

                    &:first-child
                        padding-left: 0px
                        font-weight: bold

                    .invoice-actions
                        @include flex-position(flex-start, center)

                        button:nth-child(2)
                            margin-left: 12px

                    .primary-button
                        padding: 0 10px
                        padding-bottom: 3px
                        font-size: 0.95em
                        height: 30px

                    .plain-button
                        cursor: pointer

            .pagination-container
                padding-right: 0px
                padding-left: 0px
                border: none

            .warning
                font-weight: bold
                font-size: 14px
                color: #F08B34

            .pdf_viewer
                height: 0px
                overflow: hidden

.billing-confirmation-modal
    .modal-icon svg
        width: 40px

.upgrade-page-container
    .loading
        padding: 60px 12px
        text-align: center
        background: #FFFFFF
        border: 1px solid #E6EAF0
        border-radius: 6px

    .section_container
        .top_section
            margin-bottom: 42px
            padding-bottom: 16px
            border-bottom: 1px solid #E6EAF0

            .go_back
                @include flex-position(flex-start, center)
                font-weight: 600
                font-size: 14px
                width: fit-content
                color: #585858
                cursor: pointer

                svg
                    margin-right: 7px
                    transform: rotate(225deg)

                    path
                        stroke: #585858

            .title
                margin-top: 50px
                text-align: center
                font-size: 2.5em
                font-weight: 600
                letter-spacing: -0.4px
                color: $black

            .cycle_and_price
                @include flex-position(space-between, center)
                margin-top: 40px

                .cycle
                    @include flex-position(center, center)
                    padding: 5px
                    border-radius: 32px
                    background: #F8F8F8

                    .item
                        padding: 8px 26px
                        text-transform: capitalize
                        color: $black
                        font-weight: 500
                        cursor: pointer

                        &.active
                            color: $white
                            background: $blue-2
                            border-radius: 24px

                .price
                    padding: 5px 8px
                    font-family: $primary-font
                    font-size: 14px
                    font-weight: 500
                    border: 1px solid #E6EAF0
                    border-radius: 6px

        .content
            .plans_container
                display: grid
                grid-template-columns: repeat(3, 1fr)
                gap: 22px

                .plan
                    width: 100%
                    background: $white
                    border-radius: 12px
                    border: 1px solid #CAD0D9

                    &.recommended
                        border: 2px solid #4BA3E7

                    &.current
                        border: 2px solid #4BA3E7

                        .primary-button
                            color: #474F58
                            border: 2px solid #CAD0D9
                            background: #CAD0D9
                            cursor: auto

                    .top
                        padding: 24px 20px

                        .name
                            color: #1A1A1A
                            font-size: 1.7em
                            font-weight: 600
                            letter-spacing: 0.24px
                            text-transform: capitalize

                        .desc
                            margin-top: 5px
                            color: #6C747A
                            font-size: 1em
                            font-weight: 500

                        .cost
                            margin-top: 12px
                            color: #1A1A1A
                            font-size: 2em
                            font-weight: 600

                        .primary-button
                            margin-top: 18px
                            padding-bottom: 2px
                            font-size: 1em
                            width: 100%

                    .features_div
                        padding: 12px 20px
                        padding-bottom: 36px
                        border-top: 1px solid #CAD0D9

                        h6
                            font-weight: 600
                            font-size: 15px
                            color: #474F58

                        p
                            display: grid
                            grid-template-columns: 20px 1fr
                            gap: 10px
                            margin-top: 20px
                            font-size: 1em
                            font-weight: 500
                            color: #6C747A

                            svg
                                margin-bottom: -1px
                                width: 20px

                                circle
                                    fill: $blue-2

            .payg_container
                margin: auto
                margin-top: 42px
                width: 492px
                max-width: 100%
                text-align: center

                .title
                    color: $black
                    font-size: 1.2em
                    font-weight: 600

                .sub_title
                    margin-top: 5px
                    color: #757F8A
                    font-size: 1em
                    font-weight: 500

                .primary-button
                    margin: 0 auto
                    margin-top: 29px
                    padding-bottom: 2px
                    font-size: 1em
                    width: 306px
                    max-width: 100%
                    height: 50px

            .usage_container
                margin-top: 75px

                .title_section
                    // @include flex-position(space-between, center)
                    margin-top: 40px

                    p
                        // padding-left: 70px
                        color: $black
                        font-size: 1.75em
                        font-weight: 600
                        text-align: center
                        width: 100%

                    .price
                        padding: 5px 8px
                        font-family: $primary-font
                        font-size: 14px
                        font-weight: 500
                        border: 1px solid #E6EAF0
                        border-radius: 6px

                .table
                    margin-top: 32px

                    .header
                        display: grid
                        grid-template-columns: repeat(3, 1fr)
                        border-radius: 12px 12px 0px 0px
                        background: #EDEFF2

                        p
                            padding: 12px 16px
                            color: #0C0C0C
                            font-size: 0.95em
                            font-weight: 700

                    .body
                        display: block

                        .row
                            display: grid
                            grid-template-columns: repeat(3, 1fr)
                            border-bottom: 1px solid #E1E5EA

                            p
                                padding: 14px 16px
                                color: #6C747A
                                font-size: 1em
                                font-weight: 500

        .upgrade_div
            padding-top: 18px
            padding-bottom: 47px

            h5
                margin-bottom: 14px
                font-weight: bold
                font-size: 20px
                color: #000000

            .chosen_text
                margin-bottom: 11px
                font-weight: 500
                font-size: 14px
                color: #474F58

            .info
                padding: 18px 19px
                max-width: 400px
                background: #FFFFFF
                border: 1px solid #CAD0D9
                border-radius: 4px

                p
                    @include flex-position(space-between, center)
                    margin-bottom: 14px
                    font-weight: 600
                    font-size: 15px
                    color: #757F8A

                    span
                        font-weight: 600
                        color: #474F58

                        &.success
                            font-weight: bold
                            color: #92D048

                    &:last-child
                        margin-top: 24px
                        margin-bottom: 0px
                        padding-top: 14px
                        font-weight: 600
                        color: #474F58
                        border-top: 1px solid #E6EAF0

            .charged_text
                margin-top: 22px
                font-weight: 500
                font-size: 15px
                color: #474F58

            .actions
                @include flex-position(flex-start, center)
                margin-top: 35px

                button
                    &:first-child
                        margin-right: 40px

.upgrade-info-modal
    .top
        @include flex-position(space-between, center)

        .close
            @include flex-position(center, center)
            padding: 8px
            border-radius: 50%
            background: #EDF1F4

            svg
                width: 16px
                height: 16px

    .plan
        margin-top: 24px
        color: #797F84
        font-size: 1.1em
        font-weight: 500
        text-transform: capitalize

    .cost
        color: $black
        font-size: 2.3em
        font-weight: 700

    .billed
        margin-top: 9px
        padding: 6px 12px
        color: #8F99A6
        font-size: 1em
        font-weight: 500
        width: fit-content
        border-radius: 8px
        border: 1px solid #F2F7FD
        background: #F6FAFF

    .input-container
        margin-top: 12px

    .minimum
        margin-top: 5px
        color: #8F99A6
        font-weight: 500

        span
            font-weight: 600
            color: $black

    .primary-button
        margin-top: 35px
        padding-block: 2px
        height: 45px
        width: 100%

.account-settings-container
    .settings-page-info
        display: block
        padding: 0px

        .nav_tabs
            @include flex-position(flex-start, center)
            padding: 0px 25px
            border-bottom: 1px solid #eee

            .tab
                margin-right: 30px
                padding: 15px 0px
                padding-top: 17px
                font-weight: 500
                border-bottom: 3px solid $white
                cursor: pointer

                &:last-child
                    margin-right: 0

                &:hover
                    color: $blue-2

                &.active
                    color: $blue-2
                    font-weight: bold
                    border-bottom: 3px solid $blue-2

        .nav_content
            padding: 25px
            padding-top: 40px
            min-height: 77vh
            background: #F6F7FB

            .tab_content
                margin: 0 auto
                padding: 0px 15px
                max-width: 600px
                background: $white
                border: 1px solid #efefef
                border-radius: 6px

                .header
                    padding: 17px 0px
                    font-weight: bold
                    font-size: 14px
                    text-align: center
                    border-bottom: 1px solid #efefef

                .content
                    padding-bottom: 10px

                    .item
                        @include flex-position(space-between, center)
                        padding: 18px 0px
                        border-bottom: 1px solid #fafafa

                        &:last-child
                            border: none

                        .label
                            font-weight: 600

                        .input_container
                            @include flex-position(flex-start, center)
                            padding: 0px
                            padding-left: 10px
                            font-weight: 500
                            border: 1px solid #efefef
                            border-radius: 6px

                            p
                                padding: 9px 10px
                                padding-left: 0px
                                margin: 0
                                max-width: 273px
                                white-space: nowrap
                                overflow: hidden
                                text-overflow: ellipsis

                            .copy_btn
                                display: flex
                                padding: 8px 8px
                                padding-bottom: 9px
                                font-weight: 500
                                color: $white
                                background: $blue-2
                                border-radius: 0 6px 6px 0
                                cursor: pointer

                                svg
                                    path
                                        stroke: $white

                                &:disabled
                                    opacity: .8
                                    cursor: not-allowed

                            .update_text
                                margin: 0 8px
                                font-size: 0.9em
                                text-decoration: underline
                                font-weight: 600
                                cursor: pointer

                        .logo_container
                            padding: 5px 10px

                            img
                                width: auto
                                height: 35px

                            .copy_btn
                                @include flex-position(center, center)
                                height: 40px

                        &.new_config_container
                            @include flex-position(flex-end, center)

                            .label
                                margin-right: 10px

                            .input_container
                                .input
                                    border: none

            .helper_text
                margin-top: 40px
                text-align: center

                p
                    margin-bottom: 7px

                a
                    font-weight: 600

                    &:hover
                        color: $blue-2

.working-hours-container
    .settings-page-info
        min-height: 87vh
        align-items: flex-start

        .table-container
            margin: 0
            width: 100%
            border: none

            .table-filter
                padding: 0

                .table-other-filters .primary-button
                    font-weight: 400

                    .icon
                        padding-top: 4px

            .hours-listing
                margin-top: 28px

                .item
                    @include flex-position(space-between, center)
                    margin-bottom: 16px
                    padding: 16px
                    border: 1px solid $grey-5
                    border-radius: 10px

                    &:last-child
                        margin: 0

                    &:hover
                        border: 1px solid $blue-2

                    .left-side
                        p
                            margin-bottom: 6px
                            font-weight: 600
                            font-size: 0.9em

                        h6
                            font-weight: 600
                            font-size: 1em

                    .right-side
                        @include flex-position(flex-end, center)

                        .status
                            display: none

                            &.active
                                @include flex-position(flex-start, center)
                                margin-right: 30px
                                padding: 4px 12px
                                color: $blue-2
                                background: #DFEFFB
                                border-radius: 5px

                                .dot
                                    margin-top: 1px
                                    margin-right: 8px
                                    width: 6px
                                    height: 6px
                                    background: $blue-2
                                    border-radius: 50%

                                p
                                    font-size: 0.9em
                                    text-transform: capitalize

                        .options-dropdown-container .icon svg
                            margin-top: 4px
                            width: 22px
                            height: 22px

        .no-record
            display: flex
            align-items: center
            flex-direction: column
            margin: auto

            h6
                margin-bottom: 12px
                font-weight: bold
                font-size: 1.2em

            p
                margin-bottom: 32px
                font-weight: 500
                font-size: 1em

            .primary-button
                font-weight: 400
                height: 45px

                .icon
                    padding-top: 4px

    .working-hour-form
        .modal-title
            margin-bottom: 40px
            font-weight: 500

        .modal-form
            .label
                margin-bottom: 5px
                font-weight: 600
                font-size: 1em

            .sub_label
                margin-bottom: 12px
                font-weight: 500
                font-size: 0.9em
                color: #757F8A

            .select_container__control
                padding: 2px 0 2px 8px
                width: 100%
                border: 1px solid #E6EAF0
                box-shadow: none
                outline: none
                background: transparent
                cursor: pointer

                .select_container__value-container
                    padding: 0

                .select_container__indicators
                    padding: 0

                .select_container__indicator-separator
                    display: none

                    .select_container__indicator
                        padding: 0

            .select_container__menu .select_container__option
                cursor: pointer

            .hours_select_container
                margin-bottom: 25px
                padding-bottom: 24px
                border-bottom: 1px solid #E6EAF0

                .item
                    @include flex-position(space-between, center)
                    margin-bottom: 15px
                    width: 100%

                    &:last-child
                        margin: 0

                    .select_container__control
                        padding: 2px 0 2px 8px
                        width: 135px
                        border: 1px solid #E6EAF0
                        box-shadow: none
                        outline: none
                        background: transparent
                        cursor: pointer

                        .select_container__value-container
                            padding: 0

                        .select_container__indicators
                            padding: 0

                        .select_container__indicator-separator
                            display: none

                            .select_container__indicator
                                padding: 0

                    .select_container__menu .select_container__option
                        cursor: pointer

                    .trash
                        cursor: pointer

                .add_button
                    padding: 3px 0px
                    font-weight: 600
                    font-size: 14px
                    color: $blue-2
                    width: fit-content
                    cursor: pointer

            .input_container
                padding: 0px
                border: 1px solid #E6EAF0
                border-radius: 6px

                &:focus-within
                    border: 1px solid $blue-2

                .input
                    padding: 12px
                    font-size: 0.98em
                    width: 100%
                    line-height: 150%
                    border: none
                    border-radius: 6px

                    &:focus
                        outline: none

                .uploading_file
                    padding: 0px 12px 5px 12px
                    font-weight: 500

                .file_container
                    position: relative
                    padding: 0px 12px
                    margin-bottom: 5px
                    width: 120px
                    height: 60px

                    .file
                        width: 100%
                        height: 60px
                        object-fit: cover
                        border: 1px solid #efefef
                        border-radius: 6px

                    .close
                        position: absolute
                        top: -7px
                        right: 4px
                        width: 18px
                        height: 18px
                        border-radius: 50%
                        border: 1px solid #efefef
                        cursor: pointer
                        opacity: 1

                .input_actions
                    @include flex-position(flex-start, center)
                    padding: 5px 12px 10px 12px

                    .action
                        margin-right: 20px
                        cursor: pointer

                        &:last-child
                            margin: 0

                        img
                            width: 18px
                            height: 18px

                        .emoji-container
                            position: absolute
                            bottom: 0
                            z-index: 1

        .modal-form-action
            margin-top: 40px

.template-message-preview-modal
    .modal-name
        font-weight: bold
        font-size: 1.2em

    .modal-form
        .item
            p
                margin-bottom: 5px
                font-size: 0.9em

            h6
                margin: 0
                font-size: 1.05em
                font-weight: 500
                line-height: 150%

        .modal-form-action
            margin-top: 40px

.signature-page-container
    .settings-page-info
        margin-bottom: 26px

    .signature-page-content
        display: grid
        grid-template-columns: 0.9fr 1.1fr
        grid-gap: 16px

        .signature-listing
            padding: 27px 16px
            background: #FFFFFF
            border: 1px solid #E6EAF0
            border-radius: 12px

            .header
                display: flex
                justify-content: space-between
                align-items: center
                margin-bottom: 35px

                p
                    font-weight: 700
                    font-size: 18px
                    color: #474F58

                .create-new
                    display: flex
                    align-items: center
                    color: #4BA3E7
                    font-weight: 700
                    font-size: 16px
                    cursor: pointer

                    svg
                        margin-left: 9px

                        path
                            stroke: #4BA3E7

            .list
                .item
                    display: flex
                    justify-content: space-between
                    align-items: center
                    margin-bottom: 15px
                    padding: 12px 16px
                    font-weight: 500
                    font-size: 15px
                    color: #757F8A
                    background: #F6F7FB
                    border: 1px solid #E6EAF0
                    border-radius: 12px
                    cursor: pointer

                    .left
                        display: flex
                        justify-content: flex-start
                        align-items: center

                        .active-indicator
                            display: block
                            margin-left: 10px
                            width: 9px
                            height: 9px
                            border-radius: 50%
                            background: $green-2

                    svg
                        margin-bottom: -3px
                        width: 17px
                        height: 17px

                        path
                            stroke: #8F99A6

                .no-signature
                    margin: 100px 0px
                    text-align: center
                    font-weight: 500
                    font-size: 15px

        .signature-form
            background: #FFFFFF
            border: 1px solid #E6EAF0
            border-radius: 12px

            .header
                display: flex
                justify-content: space-between
                align-items: center
                padding: 15px 16px
                border-radius: 12px 12px 0px 0px
                background: #F7F7F7

                .start
                    display: flex
                    justify-content: flex-start
                    align-items: center

                    .close-signature svg
                        margin-bottom: -3px
                        width: 16px
                        height: 16px
                        cursor: pointer

                    p
                        margin-left: 10px
                        font-style: normal
                        font-weight: 700
                        font-size: 18px
                        color: #474F58

                .toggle-switch
                    display: flex
                    align-items: center
                    font-size: 16px
                    color: #474F58
                    cursor: pointer

                    span
                        padding-right: 13px

                    .switch
                        position: relative
                        display: inline-block
                        width: 33px
                        height: 18px

                        input
                            opacity: 0
                            width: 0
                            height: 0

                        .slider
                            position: absolute
                            cursor: pointer
                            top: 0
                            left: 0
                            right: 0
                            bottom: 0
                            background-color: #ccc
                            -webkit-transition: .4s
                            transition: .4s

                        .slider:before
                            position: absolute
                            content: ""
                            height: 10.5px
                            width: 11px
                            left: 4px
                            bottom: 4px
                            background-color: white
                            -webkit-transition: .4s
                            transition: .4s

                        input:checked + .slider
                            background-color: #92D048

                        input:focus + .slider
                            box-shadow: 0 0 1px #92D048

                        input:checked + .slider:before
                            -webkit-transform: translateX(13px)
                            -ms-transform: translateX(13px)
                            transform: translateX(13px)

                        /* Rounded sliders */
                        .slider.round
                            border-radius: 34px

                        .slider.round:before
                            border-radius: 50%

            .content
                padding: 24px 16px

                .input-container .placeholders
                    gap: 8px

                    span
                        padding: 4px 8px
                        font-weight: 500
                        font-size: 14px
                        border-radius: 4px
                        color: #757F8A
                        background: #F6F7FB

                        &.active
                            color: #F6F7FB
                            background: #4BA3E7
                            cursor: pointer

                .sun-editor
                    height: 250px
                    border: 1px solid #E6EAF0
                    border-radius: 6px

                    &:focus-within
                        border-color: #4BA3E7

                    .se-toolbar
                        height: 40px

                        .se-btn-tray
                            .se-btn
                                width: 38px

                            .se-btn-select
                                width: 65px

                    .se-wrapper
                        border-radius: 6px

                    .se-wrapper-inner
                        border: none

                        p
                            margin-top: 0

                    .se-resizing-bar
                        display: none

                .form-action
                    display: flex
                    justify-content: flex-end
                    margin-top: 15px
                    margin-bottom: 24px

.field-page-container
    .settings-page-info
        margin-bottom: 26px

    .field-page-content
        display: grid
        grid-template-columns: 0.9fr 1.1fr
        grid-gap: 16px

        .object-listing
            padding: 32px 16px
            background: #FFFFFF
            border: 1px solid #E6EAF0
            border-radius: 12px

            .list
                .item
                    display: flex
                    justify-content: space-between
                    align-items: center
                    margin-bottom: 16px
                    padding: 12px 16px
                    border: 1px solid #E7E7E7
                    border-radius: 8px
                    cursor: pointer

                    &.active
                        background: #FBFBFB

                    .left
                        h6
                            margin-bottom: 3px
                            font-weight: 500
                            font-size: 16px
                            color: #474F58

                        p
                            font-weight: 400
                            font-size: 14px
                            color: #757F8A

                    svg
                        width: 17px
                        height: 17px
                        transform: rotate(-90deg)

                        path
                            stroke: #474F58

        .field-listing
            padding: 32px 16px
            background: #FFFFFF
            border: 1px solid #E6EAF0
            border-radius: 12px

            .header
                margin-bottom: 28px

                h6
                    margin-bottom: 6px
                    font-weight: 600
                    font-size: 18px
                    color: #474F58

                p
                    font-weight: 400
                    font-size: 14px
                    color: #757F8A

            .list
                .item
                    display: flex
                    justify-content: space-between
                    align-items: center
                    margin-bottom: 16px
                    padding: 12px 16px
                    font-weight: 500
                    font-size: 15px
                    color: #474F58
                    background: #FBFBFB
                    border-radius: 6px
                    cursor: pointer

                    svg
                        width: 17px
                        height: 17px
                        transform: rotate(-90deg)

                        path
                            stroke: #474F58

                    .delete
                        cursor: pointer

                        svg
                            transform: rotate(0deg)

                            path
                                stroke: $red

                    &.add_new
                        justify-content: flex-start
                        color: #4BA3E7
                        background: rgba(75, 163, 231, 0.07)
                        font-weight: 600

                        svg
                            margin-right: 8px

                            path
                                stroke: #4BA3E7

                .no-field
                    margin: 100px 0px
                    text-align: center
                    font-weight: 500
                    font-size: 15px
