.csat_container
  min-height: 100vh
  min-width: 100vw
  background: $white

  // @at-root body
  //   .oneroute_widget
  //     display: none

  @media screen and (max-width: 640px)
    padding: 0px 12px
    padding-bottom: 50px

  .content
    margin: 0 auto
    max-width: 600px
    text-align: center

    .logo
      margin-bottom: 70px
      padding-top: 70px

      img
        height: 40px

      p
        font-size: 1.6em
        text-transform: uppercase
        text-decoration: overline
        color: #4B2DB5
        font-weight: bold

    h5
      font-size: 1.4em
      font-weight: 600

    .ratings
      @include flex-position(center, center)
      margin-top: 40px
      margin-bottom: 50px

      .rating
        margin-right: 30px
        text-align: center
        width: 120px
        cursor: pointer

        @media screen and (max-width: 560px)
          width: auto

        &:last-child
          margin: 0

        p
          font-size: 2.5em

          @media screen and (max-width: 560px)
            font-size: 2em

        h6
          font-size: 1.15em
          font-weight: 500

          @media screen and (max-width: 560px)
            font-size: 1em

          @media screen and (max-width: 560px)
            font-size: 0.8em

        &.active
          h6
            font-weight: 600
            text-decoration: underline

    .form_control
      display: flex
      flex-direction: column

      label
        margin-bottom: 8px
        font-size: 1em
        font-weight: 600
        text-align: left

      textarea
        padding: 10px 12px
        font-size: 1em
        font-family: $primary-font
        letter-spacing: 0.02px

      .primary-button
        margin-top: 20px
        height: 46px

    .powered_by
      margin: 0 auto
      margin-top: 60px
      padding: 7px 20px
      width: fit-content

      a
        @include flex-position(flex-start, center)
        font-size: 1em
        font-weight: 500

        svg
          display: block
          margin-left: 5px
          height: 23px
          width: auto
