.sidebar
    position: fixed
    height: 100vh
    width: 65px
    flex: 0 0 65px
    z-index: 100

.sidebar-container
    background: $purple
    position: relative
    width: 65px
    min-height: 100%
    height: 100%
    overflow: auto
    padding: 15px 0 40px
    padding-top: 0
    display: flex
    flex-direction: column
    justify-content: space-between

    .main-menu, .footer-menu
        .menu-item
            width: 65px
            height: 65px

            a
                width: 100%
                height: 100%
                @include flex-position(center, center)
                transition: all ease-in-out .3s
                position: relative

                &:hover
                    background: #4E289A

                svg, div
                    pointer-events: none

                svg
                    path
                        stroke: $white
                        transition: all ease-in-out .3s

                &.active
                    background: #F6F7FB

                    svg
                        path
                            stroke: $purple

                &.dead
                    background: transparent

                    svg
                        path
                            stroke: $white

                .badge
                    position: absolute
                    top: 14px
                    right: 12px
                    padding-top: 0.2px
                    font-weight: bold
                    font-size: 13px
                    color: #FFFFFF
                    text-align: center
                    width: 20px
                    height: 20px
                    background: #E24660
                    border-radius: 50%

    .main-menu
        margin-bottom: 30px

.menu-label
    position: absolute
    top: 0
    left: calc(100% + 15px)
    padding: 0 10px
    height: 35px
    display: inline-flex
    align-items: center
    border-radius: 4px
    background: $white
    box-shadow: $menu-shadow
    line-height: 1
    white-space: nowrap
    font-weight: $font-weight-bold
    color: $purple
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04)
    z-index: 10

    &::before
        content: ''
        position: absolute
        top: 10px
        left: -6px
        width: 15px
        height: 15px
        border-radius: 4px
        background: $white
        transform: rotate(45deg)

.profile-menu
    top: unset
    height: auto
    width: 225px
    padding: 15px 25px
    flex-direction: column
    z-index: 9

    &::before
        top: unset
        bottom: 10px

    &-header
        @include flex-position(initial, center)
        width: 100%
        padding-bottom: 10px
        border-bottom: 1px solid $grey-6

        .profile-picture
            width: 35px
            flex: 0 0 35px
            height: 35px
            border-radius: 50%
            margin-right: 10px
            position: relative

            img
                border-radius: 50%
                @include image-full-fit(cover, top)

            .status-color
                position: absolute
                bottom: 0
                right: 0
                width: 10px
                flex: 0 0 10px
                height: 10px
                border-radius: 50%
                border: 2px solid $white
                display: flex

                &.active
                    background: $green

        .name
            display: block
            font-weight: $font-weight-semi-bold
            color: $black-2
            margin: 5px 0

        .status
            font-size: 12px
            font-weight: $font-weight-semi-bold
            color: $grey

    &-status-container
        width: 100%
        padding: 10px 0
        border-bottom: 1px solid $grey-6

        .title
            font-size: 12px
            font-weight: $font-weight-medium
            color: $grey-7
            margin-bottom: 5px
            display: flex
            line-height: 18px

    .logout
        margin-top: 15px
        font-weight: $font-weight-semi-bold
        color: $black-2
        cursor: pointer
        align-self: self-start
