.text-divider
    font-weight: $font-weight-semi-bold
    color: $blue-light
    position: relative
    text-transform: uppercase
    height: 20px
    @include flex-position(center, center)

    &::before, &::after
        content: ''
        position: absolute
        height: 1px
        background: $blue-light
        width: calc(50% - 20px)

    &::before
        left: 0

    &::after
        right: 0
