.toaster-container
    position: fixed
    bottom: 30px
    left: 90px
    height: 40px
    display: flex
    align-items: center
    justify-content: flex-start
    padding: 0 15px
    font-size: 14px
    font-weight: $font-weight-semi-bold
    color: $white
    opacity: 0
    transform: translateY(100%)
    z-index: 10000

    .toaster-close
        cursor: pointer
        display: flex
        align-items: center
        justify-content: center
        margin-left: 20px

        svg
            width: 15px

            path
                stroke: $white

    div
        display: flex
        align-items: center

    &.open, &.closed
        transition: all ease-in-out .4s

    &.open
        opacity: 1
        transform: translateY(0)

    &.error
        background: $red

    &.success
        background: $green

    .toaster-icon
        margin-right: 20px

        svg
            width: 35px

    .toaster-message
        a
            display: inline-block
            margin: 0 5px