.omnichannel-widget
    position: sticky
    top: 25px
    align-self: flex-start

    &-container
        width: 350px
        flex: 0 0 350px
        box-shadow: 0px 2px 16px rgba(143, 159, 163, 0.3)
        border-radius: 6px
        background: #FFFFFF
        position: relative
        overflow: hidden

        // &::after
        //     content: ""
        //     position: absolute
        //     bottom: -10px
        //     right: 0
        //     width: 25px
        //     height: 25px
        //     border-radius: 1px
        //     background: #FFFFFF

    .omnichannel-widget-header
        padding: 25px 20px 0px
        padding-bottom: 50px
        color: #FFFFFF

        &__logo
            margin-bottom: 22px
            width: 48px
            height: 48px
            border-radius: 8px
            object-fit: cover

        &__title
            font-size: 22px
            font-weight: 700
            margin-bottom: 10px

        &__message
            font-size: 15.5px
            font-weight: 500
            line-height: 24px

    .omnichannel-widget-content
        transform: translateY(-25px)
        padding: 0 20px

        &__item
            background: #FFFFFF
            box-shadow: 0px 2px 12px rgba(143, 159, 163, 0.24)
            border-radius: 25px 4px 4px 4px
            margin-bottom: 15px
            display: flex
            align-items: center
            padding: 0 15px
            height: 45px
            cursor: pointer

            &-icon
                width: 25px
                flex: 0 0 25px
                display: flex
                margin-right: 10px

                img
                    width: 100%

            &-name
                font-size: 14px
                font-weight: 600
                line-height: 21px
                color: #474F58

            &.disabled
                .omnichannel-widget-content__item-icon, .omnichannel-widget-content__item-name
                    opacity: 0.3

    .omnichannel-widget-copyright
        display: flex
        justify-content: center
        align-items: center
        font-size: 12px
        font-weight: 500
        color: #757F8A
        margin: 20px 0
        margin-top: -5px

        &__logo
            display: flex
            width: 15px
            flex: 0 0 15px
            margin-right: 5px

            svg
                width: 100%

        a
            color: #562CCA
            font-weight: 700
            display: flex
            margin-left: 5px

    .omnichannel-widget-toggler
        margin-top: 30px
        display: flex
        justify-content: flex-end
        align-items: center

        &__message
            height: 50px
            padding: 15px
            display: flex
            align-items: center
            background: #FFFFFF
            border: 1px solid #EFEFEF
            border-radius: 4px
            box-shadow: 0px 4px 14px rgba(167, 167, 167, 0.25)
            position: relative

        &__button
            display: flex
            justify-content: center
            align-items: center
            width: 50px
            min-width: 50px
            flex: 0 0 50px
            height: 50px
            border-radius: 50%
            cursor: pointer
            margin-left: 20px

            svg, img
                width: 25px
                height: auto

.omnichannel-widget-border-caret
    display: inline-block
    position: absolute
    right: 0
    border-radius: 1px
    background: #FFFFFF
    border: 0
    transform: rotate(45deg)

    &__left
        width: 50px
        height: 25px
        bottom: 75px
        box-shadow: 0px 4px 14px rgba(167, 167, 167, 0.25)
        clip-path: polygon(50% 0%, 0% 100%, 100% 100%)
        transform: rotate(45deg)
        right: -16px

    &__right
        background: #FFFFFF
        width: 15px
        height: 15px
        border-radius: 0 4px 0 0
        border: 0
        border-top: 1px solid #EFEFEF
        border-right: 1px solid #EFEFEF
        right: -8px
        transform: rotate(45deg)
