.chat-container
    width: 100%
    display: flex
    background: $grey-6

    .chats
        background: $white
        width: 100%
        border-right: 1px solid $grey-6
        position: relative

        &::before
            content: ""
            @include full-absolute
            opacity: 0.02
            background: url("../../assets/images/auth-bg.png")
            pointer-events: none

        &.search_active
            background: $grey-2

            .global-search-container
                margin-top: 12px

                .search-results-container
                    padding-top: 0

                    .title
                        display: none

                    .search-results
                        .ais-Hits
                            &:first-child
                                display: none

        &.showConversationHistory
            background: $grey-2
            overflow-y: auto

            .history-title
                position: sticky
                top: 0
                padding: 10px 15px
                font-size: 15px
                font-weight: 600
                color: #778699
                background: #F6F7FB

            &::before
                content: none

        .chat-header
            background: $grey-2
            padding: 0 15px
            height: 55px
            position: relative
            @include flex-position(space-between, center)

            .sender
                @include flex-position(initial, center)

            .name
                font-size: 16px
                font-weight: $font-weight-semi-bold
                color: $grey-8
                line-height: 1
                display: flex
                margin-left: 10px

            .icon
                cursor: pointer

        .chat-metadata
            @include flex-position(space-between, center)
            padding: 8px 12px
            background: #F1F9FF

            .left, .right
                @include flex-position(flex-start, center)
                gap: 12px

            .started
                font-size: 13px
                color: #000
                font-weight: 500

            .status
                padding: 2px 7px
                padding-bottom: 4px
                color: #fff
                font-size: 11px
                font-weight: 500
                background: #F38B5C
                border-radius: 20px

            .audit
                @include flex-position(center, center)
                padding: 5px
                background: #4BA3E7
                border-radius: 20px
                cursor: pointer

                svg
                    width: 13px
                    height: 13px

                    path
                        stroke: #fff

            .tags
                @include flex-position(flex-start, center)
                padding: 2px 9px
                padding-right: 7px
                font-size: 11.5px
                font-weight: 500
                color: #474F58
                border: 1px solid #4BA3E7
                border-radius: 40px
                cursor: pointer

                svg
                    margin-left: 4px

                    path
                        stroke: #4BA3E7

            .tags-container
                position: absolute
                display: grid
                grid-template-columns: 1fr 1fr
                grid-gap: 8px
                margin-top: 85px
                padding: 8px
                min-width: 140px
                min-height: 40px
                background: #FFFFFF
                border: 1px solid #F7F7F7
                box-shadow: -1px 4px 5px rgba(117, 127, 138, 0.1)
                border-radius: 8px
                z-index: 1

                p
                    @include flex-position(flex-start, center)
                    font-size: 12px
                    color: #474F58
                    font-weight: 500

                    span
                        margin-right: 4px
                        width: 6px
                        height: 6px
                        border-radius: 50%

            .agent
                @include flex-position(flex-start, center)

                .initials
                    @include flex-position(center, center)
                    margin-right: 5px
                    padding-bottom: 1px
                    width: 23px
                    height: 23px
                    color: #fff
                    font-size: 11px
                    font-weight: 500
                    background: #75B22B
                    border-radius: 40px

                .name
                    font-size: 13px
                    color: #474F58
                    font-weight: 500

        .chat-actions
            height: 40px
            background: $white
            padding: 0 15px
            box-shadow: $menu-shadow
            position: relative
            @include flex-position(space-between, center)

            @media screen and (max-width: 600px)
                flex-wrap: wrap
                height: 64px

            & > div
                display: flex

                &:first-child
                    @media screen and (max-width: 600px)
                        padding: 2px 0px
                        padding-bottom: 5px

                &:last-child
                    @media screen and (max-width: 600px)
                        justify-content: flex-end
                        margin: 0 0 0 auto
                        width: 100%
                        border-top: 1px solid #eee

            .select-assignee
                @include flex-position(initial, center)
                position: relative
                cursor: pointer

                @media screen and (max-width: 600px)
                    margin-top: 3px

                &.disabled
                    pointer-events: none
                    opacity: 0.6

                .current-assignee
                    font-weight: $font-weight-medium
                    color: $grey-8

                svg
                    margin-left: 10px

                    &:first-child
                        margin: 0

                    path
                        stroke: $grey

                .colon
                    margin: 0 5px

            .assignee-options
                width: 255px
                max-height: 485px
                overflow: auto
                position: absolute
                top: calc(100% - 5px)
                background: $white
                box-shadow: $user-dropdown-shadow
                z-index: 21

                .search-assignees
                    padding: 10px 15px

                    .input
                        background: $grey-2
                        border: 0
                        height: 30px

                .return-queue
                    background: $blue-light-3
                    padding: 10px 15px
                    color: $blue-2
                    font-weight: $font-weight-semi-bold
                    cursor: pointer
                    @include flex-position(initial, center)

                    svg
                        width: 20px
                        height: auto
                        margin-right: 10px

                        path
                            stroke: $blue-2

                .title
                    font-size: 12px
                    font-weight: $font-weight-semi-bold
                    color: $grey
                    display: block
                    margin: 10px 15px

                .loading-users
                    font-size: 12px
                    font-weight: $font-weight-semi-bold
                    color: $grey
                    display: block
                    margin: 15px 15px
                    text-align: center

                .assignee-list-items
                    .assignee-item
                        @include flex-position(initial, center)
                        padding: 5px 15px
                        border-bottom: 1px solid $grey-6
                        cursor: pointer

                        .icon
                            @include flex-position(center, center)
                            background: $grey-3
                            width: 25px
                            flex: 0 0 25px
                            height: 25px
                            border-radius: 50%
                            padding: 5px

                            svg
                                path
                                    stroke: $grey-7

                        &:last-child
                            border-bottom: 0

                        &:hover
                            background: #EDF6FD

            .select-container
                margin: 0
                margin-right: 13px
                border-right: 1px solid #E6EAF0

                .input-icon-container
                    margin-right: 8px

                    .input
                        padding: 0
                        height: 29px
                        border: none

            .icon
                @include flex-position(center, center)
                width: 30px
                flex: 0 0 30px
                height: 30px
                margin-right: 15px
                cursor: pointer
                border-radius: 6px
                transition: all ease-in-out .3s

                div:first-child
                    margin-top: 5px

                svg
                    width: 20px
                    height: auto

                &:last-child
                    margin-right: 0

                &:hover, &.active
                    background: $grey-6

                .chat-tags-container
                    position: absolute
                    top: 100%
                    background: $grey-6
                    height: 40px
                    left: 0
                    right: 0
                    padding: 0 15px
                    @include flex-position(initial, center)
                    z-index: 20
                    cursor: initial
                    display: none

                    &.show
                        display: flex

                    &::after
                        content: ""
                        position: absolute
                        width: 20px
                        height: 20px
                        transform: rotate(45deg)
                        top: 0
                        right: 155px
                        background: $grey-6
                        border-radius: 2px

                    .chat-tag-item
                        height: 30px
                        background: $white
                        padding: 0 10px
                        @include flex-position(initial, center)
                        font-size: 13px
                        font-weight: $font-weight-medium
                        border-radius: 6px
                        margin: 0
                        margin-right: 10px

                        &:last-child
                            margin-right: 0

                        .tag-icon
                            margin-right: 5px

                        .tag-remove
                            @include full-circle(15px, 15px)
                            @include flex-position(center, center)
                            background: #fff
                            margin-left: 5px
                            border: 1px solid $grey-3
                            cursor: pointer

                            svg
                                width: 7px !important

                                path
                                    stroke: $grey-7

                        &.add-tag
                            color: $grey-7
                            cursor: pointer

                            .icon
                                &:hover
                                    background: transparent

                            svg
                                path
                                    stroke: $grey-7

                .add-tags-container
                    position: absolute
                    top: calc(100% + 40px)
                    background: $white
                    box-shadow: $user-dropdown-shadow
                    width: 310px
                    max-height: 220px
                    border-radius: 4px
                    left: 15px
                    padding: 5px 0
                    overflow: auto
                    z-index: 22

                    .add-tag-item
                        @include flex-position(initial, center)
                        font-size: 12px
                        font-weight: $font-weight-semi-bold
                        color: $black-2
                        padding: 5px 15px
                        cursor: pointer
                        transition: all ease-in-out .3s

                        &:last-child
                            margin-bottom: 0

                        &:hover
                            background: $blue-light-3

                        .color-bg
                            @include full-circle(10px, 10px)
                            margin-right: 10px

        .chat-content
            // height: calc(100vh - 260px)
            overflow: auto
            padding: 15px 15px
            position: relative

            @media screen and (max-width: 790px)
                padding-bottom: 40px

            .scroll-to-load-previous
                margin-bottom: -15px
                padding: 12px 0px
                font-weight: 600
                text-align: center

            .now_assigned
                position: sticky
                bottom: -15px
                padding: 3px 0px
                margin-bottom: -20px
                font-size: 13px
                font-weight: 600
                text-align: center
                color: $white
                background: $purple

        .chat-form
            padding: 15px
            padding-top: 5px
            padding-bottom: 7px
            position: sticky
            position: -webkit-sticky
            bottom: 0
            background: $white

            .excess_spacing_error
                padding-top: 4px
                padding-bottom: 4px
                font-size: 12px
                text-align: center
                color: $white
                border-top: 1px solid #eee
                background: $red-2

            .help
                margin-top: 5px
                text-align: right
                font-size: 10px
                font-weight: $font-weight-medium
                color: $grey-7

    .convo_sidebar_container
        @include flex-position(flex-start, center)
        flex-direction: column
        gap: 24px
        position: relative
        padding: 25px 8px
        width: 60px
        background: $white

        .item
            @include flex-position(center, center)
            width: 40px
            height: 40px
            background: $white
            border: 1px solid $grey-6
            border-radius: 12px
            cursor: pointer

            &:hover
                background: $grey-6

            &.selected
                background: $grey-6

            svg path
                stroke: $black
                stroke-width: 1.5px

    .convo_sidebar_content
        position: absolute
        top: 0
        bottom: 0
        right: 58px
        width: 0px
        transition: all .3s ease-in-out .2s
        border-left: 1px solid $grey-6
        z-index: 10
        overflow: hidden

        &.showSidebar
            width: 350px
            transition: all ease-in-out .3s
            overflow-y: auto
            background: $white

        .toggle-display
            display: none

            &.active
                display: flex
                position: fixed
                margin-left: -13px
                margin-top: 46px

        .title_header
            padding: 15px 15px
            font-size: 18px
            font-weight: 600
            color: $grey-7
            background: $grey-2

        .integrations-container
            position: relative
            width: 100%
            height: 100%
            background: $white
            border-radius: 0px
            border: none
            padding: 0px
            padding-bottom: 80px

            .integration-container-item
                margin-bottom: 40px

                &:last-child
                    margin: 0

                .integrations-header
                    height: 60px
                    padding: 0 15px
                    border-top: 1px solid $grey-6
                    border-bottom: 1px solid $grey-6
                    @include flex-position(space-between, center)

                    .title
                        font-size: 18px
                        font-weight: $font-weight-semi-bold
                        color: $black-2

                    .icon
                        display: flex
                        width: 15px
                        cursor: pointer

                        svg
                            width: 100%
                            height: auto

                            path
                                stroke: $grey-7

                .integrations-content
                    .integration-item-content
                        padding: 18px 15px

                        .integration-item-container
                            padding-bottom: 18px
                            margin-bottom: 18px
                            border-bottom: 1px solid $grey-2

                            &:last-child
                                margin: 0

                            .header
                                @include flex-position(space-between, center)

                                .info
                                    @include flex-position(flex-start, center)

                                    img
                                        margin-right: 5px
                                        width: 28px
                                        height: 28px
                                        object-fit: contain

                                    p
                                        font-weight: 600
                                        font-size: 13px

                                a
                                    padding-top: 3px
                                    font-weight: 600
                                    font-size: 12px
                                    color: $purple-2

                                    &:hover
                                        text-decoration: underline

                            .content
                                margin-bottom: 12px

                                &:last-child
                                    margin: 0

                                .ticket-item
                                    @include flex-position(space-between, flex-start)
                                    margin-top: 10px

                                    span
                                        font-weight: 500
                                        font-size: 13px

                                        &.ticket-date
                                            color: $grey-4

                                        a
                                            cursor: pointer

                                .ticket-actions
                                    @include flex-position(flex-start, center)
                                    margin-top: 4px

                                    a
                                        color: $purple-2
                                        font-size: 13px
                                        font-weight: 600

                                        &:first-child
                                            margin-right: 20px

                                        &:hover
                                            text-decoration: underline

                        .create-ticket
                            display: flex
                            justify-content: center
                            align-items: center
                            margin-top: 18px
                            padding: 7px 15px
                            padding-bottom: 9px
                            font-size: 13px
                            font-weight: 600
                            width: 100%
                            background: #4BA3E7
                            border-radius: 6px
                            color: #FFFFFF

                        .csat-content
                            .info
                                margin-bottom: 13px

                                &:last-child
                                    margin: 0

                                p
                                    margin-bottom: 5px
                                    font-weight: 400
                                    font-size: 0.9em

                                h6
                                    font-weight: 500
                                    font-size: 1em

                        .group-item
                            margin-bottom: 8px
                            font-weight: 500
                            font-size: 1em

                            &:last-child
                                margin: 0

                            &.no-group
                                font-size: 0.95em
                                font-style: italic

        .customer-info-container
            .content
                padding: 0px 12px
                margin-top: 24px

                .initials_and_name
                    @include flex-position(flex-start, center)
                    flex-direction: column
                    margin: auto
                    width: fit-content
                    cursor: pointer

                    .initials
                        @include flex-position(center, center)
                        margin: 0
                        padding-bottom: 2px
                        width: 64px
                        height: 64px
                        font-size: 28px
                        font-weight: bold
                        background: $grey-6
                        border-radius: 50%

                .customer_name
                    @include flex-position(center, center)
                    gap: 8px
                    margin-top: 10px

                    .name
                        margin: 0
                        font-size: 18px
                        font-weight: 600
                        text-align: center

                    .icon
                        cursor: pointer

                .form-group
                    @include flex-position(center, center)
                    gap: 12px
                    margin-top: 10px

                    input
                        padding: 6px 8px
                        max-width: 180px
                        font-family: $primary-font
                        border: 1px solid #EAECF5
                        border-radius: 3px

                        &:focus
                            border: 1px solid $purple-2

                    .icon
                        cursor: pointer

                        svg
                            width: 20px
                            height: 20px

                            path
                                fill: $purple-2

                        &.cancel
                            svg
                                path
                                    stroke: red

                .please_wait
                    margin-top: 8px
                    text-align: center
                    font-size: 14px
                    font-weight: 600

                .icon_actions
                    @include flex-position(center, center)
                    gap: 24px
                    margin-top: 14px

                    .icon
                        padding: 8px
                        width: 40px
                        height: 40px
                        background: $grey-2
                        border-radius: 50%

                        img
                            width: fit-content

                .infos
                    margin-top: 22px
                    padding-bottom: 24px
                    border-bottom: 1px solid #EAECF5

                    .item
                        @include flex-position(flex-start, flex-start)
                        gap: 5%
                        margin-bottom: 10px

                        .label
                            width: 32%

                        .value
                            width: 63%
                            font-weight: bold

                        &:last-child
                            margin: 0

                .groups
                    margin-top: 24px
                    padding-bottom: 24px
                    border-bottom: 1px solid #EAECF5

                    label
                        display: block
                        margin-bottom: 6px
                        color: #000
                        font-size: 14px
                        font-weight: 600

                    .selected_groups
                        @include flex-position(flex-start, center)
                        flex-wrap: wrap
                        gap: 10px
                        margin-top: 12px

                        .item
                            padding: 2px 9px 4px 9px
                            color: $black-2
                            font-size: 13px
                            font-weight: 600
                            border-radius: 16px
                            border: 1px solid #EAECF5
                            background: $grey-2

                            span
                                margin-left: 6px
                                color: $red
                                cursor: pointer

                    .please_wait
                        margin-top: 8px
                        text-align: center
                        font-size: 14px
                        font-weight: 600

                .actions
                    margin-top: 30px
                    padding-bottom: 30px
                    border-bottom: 1px solid #EAECF5

                    .item
                        @include flex-position(space-between, center)
                        margin-bottom: 18px
                        padding: 8px 10px
                        border-radius: 6px
                        border: 1px solid $grey-3
                        cursor: pointer

                        &:last-child
                            margin: 0

                        .left
                            @include flex-position(flex-start, center)

                            span
                                margin-left: 8px
                                color: $black-2
                                font-weight: 500

                        .chevron svg
                            margin-top: 5px
                            margin-block: -5px
                            width: 20px
                            height: 20px
                            transform: rotate(-90deg)

                            path
                                stroke: $grey

                .notes
                    margin-top: 30px
                    padding-bottom: 30px
                    border-bottom: 1px solid #EAECF5

                    label
                        display: block
                        margin-bottom: 6px
                        color: #000
                        font-size: 14px
                        font-weight: 600

                    .textarea_container
                        padding: 8px
                        border-radius: 8px
                        border: 1px solid #E5EBF3
                        background: #F6F7FB

                        textarea
                            padding: 4px 8px
                            font-size: 13.5px
                            height: 70px
                            color: $black
                            background: $white
                            border: none

                            &:focus
                                outline: none

                        button
                            @include flex-position(flex-start, center)
                            margin: 10px 0 0 auto
                            padding: 6px 12px 8px 12px
                            height: auto
                            font-size: 13px

                            svg
                                display: block
                                margin-top: -3px
                                margin-left: 8px
                                transform: rotate(-35deg)

                                path
                                    stroke: $white

                    .notes_history
                        margin-top: 24px

                        .item
                            @include flex-position(flex-start, flex-start)
                            margin-bottom: 18px

                            &:last-child
                                margin: 0

                            .initials
                                @include flex-position(center, center)
                                margin-right: 6px
                                padding: 3px
                                font-size: 13px
                                font-weight: bold
                                width: 30px
                                height: 30px
                                background: $grey-3
                                border-radius: 50%

                            .info
                                width: calc(100% - 36px)

                                h6
                                    margin: 0
                                    font-size: 14px
                                    font-weight: 600

                                    span
                                        margin-left: 6px
                                        font-size: 13px
                                        font-weight: 400
                                        color: #6C747A

                                p
                                    margin: 0
                                    margin-top: 3px
                                    font-size: 13.5px

.ticket-details-container
    margin-top: 30px

    .ticket-details-item
        margin-bottom: 20px

        .title
            font-size: 14px
            font-weight: $font-weight-bold

        .value a
            color: #4BA3E7
            font-weight: 500
            text-decoration: underline

.new-ticket-form
    margin-top: 30px

    .modal-form-action
        & > div
            display: flex

            button
                height: 40px

                &:first-child
                    margin-right: 30px

    .attachment_container
        margin-top: 25px

        .d_flex
            @include flex-position(flex-start, center)

            h6
                margin-right: 10px
                font-size: 14px

            .file-input-container
                display: block
                width: 50px

                .secondary-button
                    padding: 0 18px
                    font-size: 13px
                    height: 30px

        .attachments
            @include flex-position(flex-start, flex-start)
            flex-wrap: wrap
            margin-top: 12px

            p
                @include flex-position(flex-start, center)
                margin-right: 10px
                margin-bottom: 10px
                padding: 8px 14px
                border: 1px solid $grey-6

                span
                    @include flex-position(center, center)
                    cursor: pointer

                    svg
                        margin-left: 8px

    .learn-more
        @include flex-position(initial, center)
        font-size: 12px
        font-weight: $font-weight-medium
        color: $grey

        .icon
            display: flex
            margin-right: 5px
            width: 10px

            svg
                width: 100%

                path
                    stroke: $grey

.chat-message-container
    @include flex-position(initial, flex-start)
    margin-bottom: 5px

    &.highlighted
        .message-container
            background: #EDF6FD !important
            border-color: #4BA3E7 !important

    .avatar
        margin-right: 15px
        opacity: 1
        pointer-events: none

    .message-container
        min-width: 215px
        max-width: 400px
        border-radius: 6px
        border: 1px solid $grey-3
        background: $white
        padding: 10px
        color: $black
        font-weight: $font-weight-medium
        line-height: 21px
        word-break: break-word
        position: relative

        &.emailMessageContainer
            padding: 15px
            min-width: 300px
            max-width: 450px

        .message-options
            position: absolute
            top: 15px
            right: 10px
            width: 10px
            height: 10px
            display: flex
            cursor: pointer
            opacity: 0
            pointer-events: none
            transition: all ease-in-out .3s

            svg
                width: 100%
                height: auto

                path
                    stroke: $grey-7

        .message-options-menu
            top: auto !important

        .message-content
            display: block
            // padding-right: 15px
            white-space: pre-wrap

            .quoted-message-content
                background: $grey-2
                padding: 10px
                border-radius: 6px 0px 0px 6px
                display: flex
                margin-right: -10px
                margin-bottom: 10px

                &::before
                    content: ""
                    width: 2px
                    flex: 0 0 2px
                    min-height: 100%
                    border: 100px
                    background: $purple
                    margin-right: 10px

            .auth-message
                @include flex-position(flex-start, center)
                font-style: italic
                font-weight: 600
                color: #8F99A6

                img
                    width: 15px
                    height: 15px

            .message-content-itself
                // transform: scaleX(1)

                .message-link
                    color: $purple-2

                    &:hover
                        text-decoration: underline

                table
                    width: 100% !important

            .email-message-content
                .header
                    display: flex

                    .sender-initials
                        margin-right: 15px
                        padding: 6px 7px 5px
                        width: 36px
                        height: 34px
                        color: $white
                        font-size: 13px
                        font-weight: 500
                        text-align: center
                        text-transform: uppercase
                        background: #562CCA
                        border-radius: 100%

                    .sender-info
                        width: 100%

                        h6
                            display: flex
                            justify-content: space-between
                            align-items: flex-start
                            color: $black-2
                            font-size: 14px
                            font-weight: bold

                            span
                                display: block
                                color: #8F99A6
                                font-weight: 500

                        p
                            margin-top: 6px
                            color: #8F99A6
                            font-weight: 500

                            &.cc_preview
                                width: fit-content
                                max-width: 400px
                                cursor: pointer
                                @include ellipsis

                            span
                                color: $black-2

                        .all_ccs
                            padding-left: 15px

                            .cc
                                display: block
                                margin-bottom: -2px
                                font-size: 13px

                .body
                    display: inherit
                    margin-top: 16px
                    padding-top: 19px
                    border-top: 1px solid #CAD0D9
                    overflow-x: auto

                    &:has(meta)
                        white-space: normal !important
                    &:has(table)
                        white-space: normal !important

                    a
                        color: $purple-2

                        &:hover
                            text-decoration: underline

                    .gmail_quote
                        @include ellipsis
                        margin-bottom: -15px
                        max-height: 75px
                        font-size: 0.95em
                        font-style: italic
                        color: $grey

                        a
                            color: $grey

                            &:hover
                                text-decoration: none

                    .attachments
                        margin-top: 16px
                        padding-top: 19px
                        border-top: 1px solid #CAD0D9

        .message-footer
            @include flex-position(space-between, center)
            margin-top: 10px
            font-size: 12px
            font-weight: $font-weight-semi-bold
            color: $grey

            div
                display: flex

                span
                    position: relative
                    @include flex-position(initial, center)
                    padding-right: 5px
                    margin-right: 10px

                    &.msg-dlr
                        img
                            width: 16px

                            &.sent
                                width: 13px

                    &::after
                        content: ''
                        position: absolute
                        width: 5px
                        height: 5px
                        border-radius: 50%
                        background: transparentize($black-2, 0.6)
                        right: -5px

                    &:last-child
                        padding-right: 0
                        margin-right: 0
                        &::after
                            content: none

        .failed_dlr_reason
            margin: -2px 0px
            font-weight: 600
            font-size: 0.85em
            color: $red
            text-align: right
            line-height: normal

        .message-unsent-actions
            @include flex-position(flex-end, center)
            gap: 8px
            margin-top: 4px
            padding: 0px 8px
            font-size: 0.85em
            background: white
            border-radius: 3px

            .retry
                color: $purple-2
                cursor: pointer

                &:hover
                    text-decoration: underline

            .delete
                color: red
                cursor: pointer

                &:hover
                    text-decoration: underline

        &:hover, &.messageOptions
            .message-options
                opacity: 1
                pointer-events: all

    &.authUser
        justify-content: flex-end

        .avatar
            margin-left: 15px
            margin-right: 0

        .message-container
            background: $grey-9
            border-color: $white

            .quoted-message-content
                background: $white

    &.authFirstChild
        .avatar
            opacity: 1
            pointer-events: all

    &.firstChild
        margin-top: 15px

        .avatar
            opacity: 1
            pointer-events: all

    &.lastChild
        margin-bottom: 15px

    &.sending
        .message-container
            background: $grey-trans-2

            .message-content
                color: $grey

            .message-footer
                color: $grey-3

.failed-action
    @include flex-position(flex-end, initial)
    font-size: 12px
    font-weight: $font-weight-semi-bold
    color: $grey
    margin-right: 45px

    span
        display: flex
        margin-left: 5px
        color: $blue
        cursor: pointer

.message-media
    max-width: 280px
    height: 155px
    background: $grey-2
    border-radius: 4px
    overflow: hidden
    margin-top: 10px
    display: block
    position: relative

    img, video
        @include image-full-fit(cover, center)
        pointer-events: none

.message-audio, .message-file
    margin-top: 10px

.message-file
    @include flex-position(initial, center)

    .icon
        display: flex
        width: 20px
        flex: 0 0 20px
        margin-right: 10px

        svg
            width: 100%
            height: auto

            path
                stroke: $black-2

    a
        color: $purple

.message-order
    img
        margin-bottom: 2px
        width: 188px
        height: 130px
        object-fit: cover

    h5
        margin-bottom: 15px
        font-weight: 600
        font-size: 14px
        color: #757F8A

    h6
        @include flex-position(flex-start, center)
        margin-bottom: 2px
        font-weight: 600
        font-size: 14px
        color: #000

        svg
            margin-right: 5px
            width: 14.5px
            height: 14.5px

    p
        margin: 0
        font-weight: 500
        font-size: 14px
        color: #8F99A6

    button
        margin-top: 12px
        padding: 8px
        width: 188px
        font-weight: 600
        font-size: 13px
        color: #4BA3E7
        border: 1px solid #4BA3E7
        border-radius: 6px
        cursor: pointer

    hr
        margin-top: 15px
        height: 1px
        background: #CAD0D9
        border: none

.message-contact
    .item
        margin-top: 10px

        &:first-child
            margin-top: 0

    h6
        margin-bottom: 2px
        font-weight: 600
        font-size: 14px
        color: #000

    p
        margin: 0
        font-weight: 500
        font-size: 14px
        color: #8F99A6

        span
            &::after
                content: ","

            &::after
            &:last-child
                content: none

            &:first-child
                margin-right: 3px

    hr
        margin-top: 12px
        height: 1px
        background: #CAD0D9
        border: none

.message-contact
    img
        margin-bottom: 2px
        width: 188px
        height: 130px
        object-fit: cover
        cursor: pointer

    h6
        font-weight: 600
        font-size: 14px
        color: #000

    hr
        margin-top: 12px
        height: 1px
        background: #CAD0D9
        border: none

.modal-form
    .message-media
        max-width: none
        height: auto

    video
        width: 100%
        height: 70vh

.template-message-form
    margin-top: 40px

    .template-options
        max-width: 325px

    .template-message-preview
        background: $blue-trans-2
        border-radius: 5px
        padding: 15px
        margin-bottom: 25px

        .title
            font-size: 12px
            font-weight: $font-weight-bold
            margin-bottom: 10px
            display: block

        .content
            line-height: 21px
            font-weight: $font-weight-medium

    .excess_template_spacing_error
        margin-top: -24px
        margin-bottom: 30px
        padding-top: 4px
        padding-bottom: 4px
        font-size: 12px
        text-align: center
        color: $white
        border-top: 1px solid #eee
        background: $red-2

    .file-input-container
        justify-content: flex-start
        margin-bottom: 10px !important

.new-conversation-container
    position: relative
    padding-top: 20px
    overflow: hidden

    .modal-title h6
        margin-right: 10px
        font-weight: 600
        font-size: 16px
        color: #000000

    .new-conversation-channel
        @include flex-position(initial, center)
        padding: 0px 7px
        padding-right: 5px
        border: 1px solid #E6EAF0
        border-radius: 6px

        svg
            width: 22px
            height: 22px

        .select_container__control
            margin-left: 4px
            padding: 0px
            width: 180px
            border: none
            box-shadow: none
            outline: none
            background: transparent
            cursor: pointer

            .select_container__value-container
                padding: 0

                .select_container__single-value
                    font-weight: bold
                    font-size: 14px
                    color: #474F58

            .select_container__indicators
                padding: 0

                .select_container__indicator-separator
                    display: none

                .select_container__indicator
                    padding: 0

        .select_container__menu .select_container__option
            cursor: pointer

        .select_container__option
            font-size: 15px
            background-color: white

            &--is-focused
                background-color: rgba(75, 163, 231, 0.2)

            &--is-selected
                background-color: #4BA3E7

        .input
            color: $black-2
            line-height: 1

    .new-conversation-form
        margin-top: 20px
        min-height: 330px

        .new-recipient
            @include flex-position(initial, center)
            height: 40px
            padding: 21.5px 25px
            border-top: 1px solid $grey-6
            border-bottom: 1px solid $grey-6
            font-weight: $font-weight-semi-bold
            line-height: 1
            font-size: 14px
            margin: 0 -25px

            label
                margin-right: 15px

            input
                font-family: $primary-font
                color: $black-2
                width: 100%
                height: 30px
                font-size: 15px
                font-weight: $font-weight-medium
                letter-spacing: 0.2px
                border: none

                @include placeholder
                    color: $grey
                    font-size: 15px
                    font-weight: $font-weight-medium

        .start-new-conversation-container
            .start-new-conversation
                @include flex-position(space-between, center)
                height: 45px
                padding: 0 20px
                margin: 5px -25px
                margin-bottom: -10px
                transition: all ease-in-out .3s
                color: $black-2
                line-height: 1
                border-top: 1px solid $grey-6
                border-bottom: 1px solid $grey-6

                .d-flex
                    @include flex-position(initial, center)
                    padding: 0px 5px
                    height: inherit

                .edit
                    @include flex-position(initial, center)
                    padding: 0px 5px
                    height: inherit
                    font-weight: bold
                    cursor: pointer

                    &:hover
                        background: $blue-light-3

                .icon
                    display: flex
                    margin-right: 15px

            .error
                margin-top: 12px
                margin-bottom: -15px
                text-align: right
                color: red

                &.loading
                    color: #474F58
                    font-weight: bold

        .chat-container-contacts
            margin-top: 20px
            padding-bottom: 15px
            max-height: 400px
            overflow-y: scroll

            h6
                margin-bottom: 10px
                font-weight: 600
                font-size: 16px
                color: #474F58

            .contact
                display: flex
                align-items: center
                padding: 5px 0px
                margin-bottom: 8px
                cursor: pointer

                &:hover
                    background: rgba(202, 208, 217, 0.1)

                &:last-child
                    margin-bottom: 25px

                .avatar-container
                    margin-right: 12px
                    width: 35px
                    height: 35px
                    flex: none
                    text-transform: uppercase
                    font-size: 13px
                    font-weight: 600
                    color: #FFFFFF
                    background: #CAD0D9
                    border: none

        .new-conversation-action
            @include flex-position(space-between, center)
            height: 60px
            padding: 0 15px
            margin: 0 -25px
            width: 100%
            box-shadow: $user-dropdown-shadow
            border-top: 1px solid $grey-6
            position: absolute
            bottom: 0
            background: #fff

.email_chat_modal_content
    width: 100%
    height: auto

    .header
        @include flex-position(flex-start, center)
        padding: 16px
        background: #F7F7F7
        border-radius: 4px 4px 0px 0px

        h6
            margin: 0 auto
            font-weight: 900
            font-size: 16px
            color: #000000

        .close
            @include flex-position(center, center)
            width: 22px
            height: 22px
            background: #ACADAD
            border-radius: 50%
            cursor: pointer

            svg
                width: 12px
                height: 12px

                path
                    stroke: #fff

    .content
        margin: 16px
        border: 1px solid #D6D5D5
        border-radius: 4px

        .send_to
            @include flex-position(flex-start, center)
            padding: 1px 12px
            padding-right: 15px
            padding-bottom: 1px
            border-bottom: 1px solid #D6D5D5

            span
                margin-right: 6px
                font-weight: 500
                font-size: 14px
                color: #A3A3A3
                width: 56px

                &:last-child
                    margin: 0 0 0 auto
                    padding-left: 6px

            .emails
                @include flex-position(flex-start, flex-start)
                flex-wrap: wrap
                margin-top: 8px
                width: calc(100% - 112px)

                .email
                    @include flex-position(flex-start, center)
                    margin: -1px 3px 6px 3px
                    padding-bottom: 3px
                    background: #EEEEEE
                    border-radius: 4px

                    p
                        padding: 2px 8px
                        padding-top: 3px
                        padding-right: 4px
                        font-weight: 500
                        font-size: 14px
                        color: #474F58

                    .remove_cc
                        margin-bottom: -3px
                        padding: 5px 4px
                        padding-bottom: 1px
                        background: rgba(255, 99, 71, 0.15)
                        cursor: pointer

                        svg
                            width: 14px
                            height: 14px

                            path
                                stroke-width: 1
                                stroke: red

                .input-container
                    margin: 3px 3px 3px 6px
                    padding-bottom: 3px
                    min-width: 200px

                    .input
                        padding: 0
                        font-weight: 500
                        font-size: 14px
                        height: auto
                        border: none

            .toggle_cc
                @include flex-position(flex-start, center)

                span
                    cursor: pointer

                    &:hover
                        font-weight: bold
                        text-decoration: underline

            &:nth-child(2)
                padding-top: 8px
                padding-bottom: 7px

                .emails
                    margin-top: 0px

                    .email
                        margin: 0
                        margin-left: 3px

        .d_flex
            @include flex-position(flex-start, center)
            width: 100%

            .subject
                @include flex-position(flex-start, center)
                padding: 11px 12px
                width: 50%
                height: 45px
                border-right: 1px solid #D6D5D5

                &:last-child
                    border: none

                span
                    margin-right: 6px
                    font-weight: 500
                    font-size: 14px
                    color: #A3A3A3

                .input-container
                    margin: -1px 3px 0px 3px
                    width: 100%

                    .input
                        padding: 0
                        font-weight: 500
                        font-size: 14px
                        height: auto
                        border: none

                .select_container__control
                    margin-top: -1px
                    width: 160px
                    border: none
                    background: transparent

        .file-input-container
            padding: 13px 5px 5px 5px
            width: 100%
            background: #fafafa

            .attach
                display: flex
                justify-content: center
                align-items: center
                padding: 4px
                width: 100%
                font-size: 15px
                font-weight: bold
                border: 1px solid #dadada
                border-radius: 4px

                svg
                    margin-left: 3px

        .attachment_media
            display: inline-block
            position: relative
            margin: 5px
            padding: 3px
            border: 1px solid $grey-3
            border-radius: 5px

            .close
                width: 20px
                height: 20px
                border: 1px solid $grey-3
                position: absolute
                top: -10px
                right: -10px
                border-radius: 50%
                background: $white
                cursor: pointer
                padding: 4px
                z-index: 2
                @include flex-position(center, center)

            .message_media
                z-index: 1

                img
                    margin-bottom: -4px
                    width: 75px
                    height: 45px
                    border-radius: 5px
                    object-fit: cover

            .message-audio, .message-file
                margin-top: 0

    .action
        margin-right: 16px
        margin-bottom: 16px

        .primary-button
            margin: 0 0 0 auto
            padding-bottom: 2px
            height: 43px
            font-size: 14px

.conversation-history-container
    .loading_history
        text-align: center
        margin-top: 60px
        font-weight: 500
        font-size: 1.05em

    .history-item
        height: 65px
        @include flex-position(space-between, center)
        padding: 0 20px
        color: $grey
        background: $white
        border-bottom: 2px solid $grey-2
        cursor: pointer

        .message-preview
            font-size: 14px
            font-weight: $font-weight-semi-bold
            margin-bottom: 3px
            @include ellipsis
            max-width: 390px

        .message-length
            font-size: 12px
            font-weight: $font-weight-medium

        .message-action
            display: flex

        .message-date
            @include flex-position(initial, center)
            font-size: 12px
            font-weight: $font-weight-medium
            margin-right: 15px

            .icon
                width: 12px
                display: flex
                margin-right: 10px

                svg
                    width: 100%

        .message-open
            cursor: pointer

            svg
                path
                    stroke: $grey-7

.message-options-menu
    position: absolute
    width: 180px
    background: $white
    box-shadow: $message-options-shadow
    border-radius: 4px
    overflow: hidden
    top: 50px
    right: 15px
    z-index: 10

    span
        @include flex-position(initial, center)
        height: 35px
        padding: 0 10px
        font-size: 12px
        font-weight: $font-weight-medium
        color: $black-2
        cursor: pointer
        border-bottom: 1px solid $grey-2
        transition: all ease-in-out .3s

        &:last-child
            border-bottom: 0

        &:hover
            background: $blue-trans

.grouped-messages
    &-container
        border-top: 1px solid $grey-6
        margin-top: 40px

    &-date
        position: sticky
        position: -webkit-sticky
        top: 0px
        margin: -17.5px 0 22px
        @include flex-position(center, center)
        z-index: 10

        &-text
            @include flex-position(center, center)
            padding: 0 20px
            height: 35px
            border: 1px solid $grey-6
            border-radius: 100px
            background: $white
            position: relative
            cursor: pointer

            svg
                margin-left: 5px

            .message-options-menu
                left: 0
                right: unset

            .react-datepicker
                position: absolute
                top: 50px
                left: 50px
                z-index: 10

.sun-editor
    height: 250px
    border: none

    .se-toolbar
        position: absolute
        bottom: 0
        height: 30px
        outline: none
        border-top: 1px solid #dadada

    .se-btn
        align-items: center
        width: 48px
        height: 28px

    .se-btn-tray
        display: flex
        flex-wrap: wrap
        justify-content: space-between
        padding-top: 5px

    .se-btn-select, .se-btn-tool-size
        width: auto
        max-width: 70px

        .txt
            font-size: 14px
            font-weight: 500

        svg
            margin-left: 5px

    .se-wrapper
        height: 100%

        .se-wrapper-inner
            height: 100% !important
            border-top: 1px solid #D6D5D5

            &.sun-editor-editable
                padding: 16px 12px
                padding-bottom: 60px
                font-family: "Trebuchet MS"
                font-weight: 500
                font-size: 14px
                line-height: 150%
                color: #0A1133

    .se-resizing-bar
        display: none
