div.overview-card
    @include flex-position(initial, center)
    padding: 0 25px
    height: 110px
    border-radius: 6px
    border: 1px solid $grey-6
    box-shadow: $overview-shadow
    background: $white

    .icon
        @include flex-position(center, center)
        width: 50px
        flex: 0 0 50px
        height: 50px
        border-radius: 50%
        background: $blue-trans
        margin-right: 20px

    .title, .value
        display: block

    .title
        font-size: 14px
        color: $grey
        font-weight: $font-weight-semi-bold

    .value
        font-size: 28px
        font-weight: $font-weight-semi-bold