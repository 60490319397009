.broadcast-table
  .message
    max-width: 350px
    display: -webkit-box
    -webkit-line-clamp: 2
    -webkit-box-orient: vertical
    overflow: hidden
    text-overflow: ellipsis

.broadcast-page-container

  .loading-broadcast
    margin: 50px auto
    font-weight: 500
    font-size: 16px
    font-weight: bold
    text-align: center
    width: 486px
    color: #757F8A

  .settings-page-info
    display: block !important
    padding-top: 0px !important

  .navigation
    @include flex-position(space-between, center)
    padding: 0px
    border-bottom: 1px solid #eee

    .nav-item
      @include flex-position(center, center)
      padding: 20px 0px
      padding-bottom: 16px
      font-weight: 600
      font-size: 13px
      color: #999
      width: 33%
      border-bottom: 2px solid #fff

      span
        @include flex-position(center, center)
        margin-top: 0px
        margin-right: 10px
        width: 20px
        height: 20px
        color: #999
        background: #E6EAF0
        border-radius: 50%

      &.active
        color: #4BA3E7
        border-bottom: 2px solid #4BA3E7

        span
          color: #fff
          background: #4BA3E7

      &.done
        cursor: pointer

  .form_container
    padding-top: 50px
    padding-bottom: 80px

    form, .form
      margin: 0 auto
      max-width: 800px

      .select_container__control
        padding: 2px 0 2px 8px
        width: 100%
        border: 1px solid #E6EAF0
        box-shadow: none
        outline: none
        background: transparent
        cursor: pointer

        .select_container__value-container
          padding: 0

        .select_container__indicators
          padding: 0

          .select_container__indicator-separator
            display: none

            .select_container__indicator
              padding: 0

      .select_container__menu .select_container__option
        cursor: pointer

      .checkbox-container
        @include flex-position (flex-start, center)

        .input
          height: 20px
          width: 20px
          cursor: pointer

        label
          padding-left: 12px
          font-weight: 500
          font-size: 1.05em
          cursor: pointer

      .helper_container
        padding: 20px 15px
        background: #F7F7F7
        border: 1px solid #E6EAF0
        border-radius: 6px

        &.mt--20
          margin-top: -20px

        p
          line-height: 150%

        ul
          margin-top: 20px

          li
            @include flex-position(space-between, center)
            margin-bottom: 15px
            width: 50%
            text-transform: capitalize

            &:last-child
              margin: 0

            span
              width: 50%
              font-weight: bold
              text-transform: none

      .customers_list_table
        margin-top: 20px
        width: 100%
        border: 1px solid $grey-6
        border-bottom: none
        border-radius: 6px

        thead tr td
          padding: 8px 12px
          padding-bottom: 10px
          font-size: 0.95em
          font-weight: bold
          background: #F6F7FB
          border: none

        tbody tr td
          padding: 9px 12px
          padding-bottom: 11px
          font-weight: 500
          border: none
          border-bottom: 1px solid $grey-6

        .input
          height: 18px
          width: 18px
          cursor: pointer

      .template-message-preview
        margin-top: 15px
        margin-bottom: 30px
        background: $blue-trans-2
        border-radius: 5px
        padding: 15px

        .title
          font-size: 14px
          font-weight: $font-weight-bold
          margin-bottom: 10px
          display: block

        .content
          display: flex
          flex-wrap: wrap
          line-height: 21px
          font-weight: $font-weight-medium
          white-space: break-spaces

      .excess_template_spacing_error
        margin-top: -24px
        margin-bottom: 30px
        padding-top: 4px
        padding-bottom: 4px
        font-size: 12px
        text-align: center
        color: $white
        border-top: 1px solid #eee
        background: $red-2

      .file-input-container
        justify-content: flex-start
        margin-bottom: 12px !important

        .secondary-button
          display: flex

      .sun-editor
        height: 300px
        border: 1px solid #D6D5D5
        border-radius: 6px

        &:focus-within
          border-color: #4BA3E7

        .se-toolbar
          height: 40px

        .se-wrapper
          border-radius: 6px

          .se-wrapper-inner
            border: none

            p
              margin-top: 0

        .se-resizing-bar
          display: none

    .form-action
      margin-top: 50px

      &.d_flex
        @include flex-position(space-between, center)

        button
          width: 40%

      button
        padding-bottom: 1.5px
        width: 100%
        height: 48px

  .input-container
    .textarea
      padding: 10px
      height: auto

      &:focus
        outline: none
        border-color: #4BA3E7

    p
      margin-top: 8px
      font-size: 1.05em
      line-height: 150%
      letter-spacing: 0.02px

    .groups-container
      @include flex-position(flex-start, center)
      flex-wrap: wrap
      margin-top: 10px

      .group
        @include flex-position(flex-start, flex-start)
        margin: 0
        margin-right: 8px
        padding: 2px 10px 3px 10px
        font-size: 0.95em
        font-weight: 600
        width: fit-content
        background: $grey-6

        &:last-child
          margin: 0

  .error-container
    margin-top: -25px
    margin-bottom: 25px
    color: $red
    font-weight: 500

  .d_grid
    display: grid
    grid-template-columns: repeat(3, 1fr)
    grid-gap: 15px

  .settings-page-info
    &.off-white-bg
      background: #F6F7FB

    &.overview
      display: grid !important
      grid-template-columns: repeat(2, 1fr)
      grid-gap: 25px
      padding-top: 25px !important

      &.summary
        grid-template-columns: repeat(5, 1fr)

  .status
    padding: 0px 6px
    padding-bottom: 1px
    font-weight: 600
    font-size: 0.95em
    color: $white
    width: fit-content

    &.rejected, &.undelivered
      background: $red

    &.pending
      background: $yellow

    &.in-progress
      background: $yellow

    &.executed
      background: $green-2

    // &.executed
    //   background: $purple

  .filter_container
    display: flex
    justify-content: flex-end
    margin-top: 40px
    margin-bottom: 18px
    width: 100%

    .select_container__control
      margin: 0 0 0 auto
      width: 300px

  .message-logs-container
    padding: 0px
    height: auto
    background: $white
    border: 1px solid $grey-6
    border-radius: 6px
    box-shadow: $overview-shadow

    .loading_table_data
      padding: 14px 0px
      width: 100%
      text-align: center
      font-weight: 600
      font-style: italic

    .grid-view
      display: grid
      grid-template-columns: repeat(6, 1fr)

      &.header
        padding: 8px 15px
        padding-bottom: 10px
        background: $grey-2

        p
          font-weight: bold
          opacity: .8

      &.message
        margin-bottom: 10px
        padding: 15px 15px
        border-bottom: 1px solid $grey-6

        &:last-child
          margin: 0
          border: none

        .info
          font-size: 1em
          font-weight: 600

          &.id
            @include ellipsis
            padding-right: 12px
            max-width: 273px

          .status
            padding: 0px 8px
            padding-bottom: 1.5px
            font-weight: 600
            color: $white
            width: fit-content
            border-radius: 5px

            &.delivered
              background: $green-2

            &.undelivered
              background: $red

            &.undeliverable
              background: $red

            &.pending
              background: $yellow

    .pagination-container
      border-bottom: none
