.primary-link
    color: $purple
    cursor: pointer
    font-weight: $font-weight-medium
    text-decoration: underline

    &.noline
        text-decoration: none

    svg
        margin-right: 4px

    &.blue
        color: $blue-2

    &.green
        @include flex-position(flex-start, center)
        margin-left: 0px
        margin-top: 18px
        font-weight: bold
        color: #35C272
        text-decoration: none

    &.no-decoration
        text-decoration: none

    &.semi-bold
        font-weight: $font-weight-semi-bold

    &.bold
        font-weight: $font-weight-bold

    &:hover
        text-decoration: underline
