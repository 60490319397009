.table-container
    margin-top: 25px
    margin-bottom: 24px
    border: 1px solid $grey-6
    background: $white

    .table-filter
        @include flex-position(space-between, center)
        padding: 25px

        .table-search-filter
            @include flex-position(flex-start, center)
            max-width: 320px

        .table-other-filters
            @include flex-position(initial, center)

            button
                &:last-child
                    margin-right: 0px

    .table-actions
        @include flex-position(space-between, center)
        margin-bottom: 16px
        padding: 0px 25px

        .input-container
            @include flex-position(flex-start, center)

            label
                margin: 0
                margin-right: 10px

    .table-counter
        display: flex
        justify-content: flex-end
        font-weight: 800
        font-size: 14px
        color: #474F58
        width: 100%

    .table-tabs
        display: flex
        margin-left: 25px
        padding-top: 10px

        .table-tab-item
            @include flex-position(center, center)
            display: inline-flex
            padding: 0 3px 7px
            margin-right: 30px
            cursor: pointer
            border-bottom: 2px solid transparent
            color: $grey
            font-weight: $font-weight-semi-bold
            transition: all ease-in-out .3s
            font-size: 14px

            .table-tab-item-badge
                @include flex-position(center, center)
                margin-left: 5px
                padding-bottom: 1px
                width: 22px
                height: 22px
                font-size: 12.5px
                color: #fff
                background: #757F8A
                border-radius: 50%

            &.active
                color: $blue-2
                border-color: $blue-2

                .table-tab-item-badge
                    background: #4BA3E7

.table
    position: relative
    margin-bottom: 50px
    width: 100%
    border-bottom: none
    border-radius: 6px
    overflow: auto

    table
        position: relative
        border: 0
        border-spacing: 0
        width: 100%

        tr
            th, td
                padding: 10px 25px
                font-size: 14px

                .checkbox_container
                    input
                        margin-top: 5px
                        width: 16px
                        height: 16px
                        cursor: pointer

            th
                background: $grey-11
                color: $grey-7
                text-align: left
                font-weight: $font-weight-bold

            td
                background: $white
                color: $black-2
                font-weight: $font-weight-medium
                border-top: 1px solid $grey-6

                span
                    text-transform: capitalize

                .ellipsis
                    display: block
                    max-width: 250px
                    @include ellipsis

            .row-action
                padding: 10px 15px
                cursor: unset
                text-align: center

                span
                    display: inline-block
                    cursor: pointer
                    margin-right: 50px

                    &:last-child
                        margin-right: 0

                    &.disabled
                        opacity: 0.4
                        pointer-events: none

            &:last-child
                td
                    border-bottom: 1px solid $grey-6

    &.version2
        table
            tr
                th, td
                    border-left: 0

                    &:first-child
                        border-left: 1px solid $blue-light-2

                th
                    background: transparent

                    &:first-child
                        border-radius: 8px 0 0 0

                    &:last-child
                        border-radius: 0 8px 0 0

    tfoot
        tr
            position: fixed
            bottom: 0

        .pagination-td
            padding: 0px
            padding-right: 30px
            width: 100vw
            border: none

.pagination-container
    display: flex
    justify-content: space-between
    align-items: center
    padding: 10px 15px
    border-top: 1px solid #E6EAF0
    border-bottom: 1px solid #E6EAF0
    background: #FFFFFF
    width: 100%

.pagination-actions
    display: flex

    span
        display: flex !important
        align-items: center
        justify-content: center
        text-align: center
        width: 28px
        height: 28px
        margin: 0 2px
        border: 1px solid $grey-6
        border-radius: 4px
        cursor: pointer
        color: $black-2
        transition: all ease-in-out .3s

        svg
            g, path
            fill: $black-2
            transition: all ease-in-out .3s

        &.previous
            svg
                transform: rotate(180deg)

        &.current
            color: $white
            background: $blue
            box-shadow: $pagination-shadow

            svg
                g, path
                fill: $white

        &.disabled
            pointer-events: none
            opacity: 0.3

.bulk_actions_dropdown
    margin-top: -10px
    margin-bottom: -5px
    position: relative
    background: $white
    border-radius: 6px
    border: 1.5px solid #8F99A6
    color: $grey-7
    min-width: 120px
    width: auto
    font-size: 14px
    font-weight: $font-weight-medium
    box-shadow: none

    .options-dropdown-container
        @include flex-position(center, center)
        padding: 0 10px
        padding-bottom: 1px

        .icon
            @include flex-position(space-between, center)
            height: 35px
            width: 100%

            svg
                margin-left: 8px

        .options-menu
            top: auto
            right: auto
            bottom: calc(-120%)
            border: 1px solid $grey-9
            border-radius: 5px

            .icon
                height: auto

            svg
                margin: 0
                width: 15px
                height: 15px
