.conversations-container
    height: 100vh
    min-width: 350px
    display: flex
    overflow: hidden

.conversation-sections
    &.searchActive
        width: 490px
        flex: 0 0 490px
        transition: all .3s ease-in-out .2s

    .conversation-section-items
        position: relative

        .global-search-container
            margin: 0 10px

            .placeholder_text
                @include ellipsis
                max-width: 180px

            .global-search .search-container-box
                width: 86%

            .search-results-container
                position: relative
                top: 0
                margin: 0
                margin-top: -18px
                padding-left: 0px
                padding-right: 0px
                height: auto

        .item
            .icon
                &.noStroke
                    svg
                        path
                            stroke: unset !important

            &.sub-item
                margin-left: 23px
                margin-bottom: 0
                width: 90.3%
                border-left: 1px solid rgba(0, 0, 0, 0.1)

                &.active
                    background: rgba(24, 160, 251, 0.1)

                div:first-child
                    margin-left: 0px

.conversation-lists-container
    background: $white
    width: 250px
    flex: 0 0 250px
    height: 100%
    padding: 0 0 25px
    border-right: 1px solid $grey-6
    // overflow: auto
    overflow-y: auto
    position: relative
    transition: all ease-in-out .05s

    &.hideDisplay
        width: 20px
        flex: 0 0 20px
        transition: all .3s ease-in-out .2s

        .title, .conversation-item, .conversation-list-items
            opacity: 0
            transition: opacity ease-in-out .3s
            pointer-events: none

    &.searchActive
        position: absolute

        .toggle-container
            display: none

    .toggle-container
        @include flex-position(flex-end, center)
        position: sticky
        position: -webkit-sticky
        top: 0
        z-index: 10

        .toggle-display
            position: fixed
            top: 0
            margin-top: 55px
            margin-right: -13px

    .title
        padding: 25px 15px 10px 18px
        background: $white
        transition: opacity .3s ease-in-out .2s
        @include flex-position(space-between, center)
        position: sticky
        position: -webkit-sticky
        top: 0
        z-index: 9

    p
        font-size: 20px
        font-weight: $font-weight-bold
        max-width: 300px
        white-space: nowrap
        overflow: hidden

        // &::after
        //     content: ""
        //     position: absolute
        //     left: 110px
        //     width: 40px
        //     height: 40px
        //     background: linear-gradient(270deg, #FFFFFF 69.44%, rgba(255, 255, 255, 0) 96.95%)

    .icon
        margin-left: 40px
        font-weight: bold
        font-size: 14px
        color: #4BA3E7
        cursor: pointer
        display: flex

        span
            margin-right: 8px

        svg path
            stroke: #4BA3E7
            stroke-width: 1.4px

    .bulk-actions
        display: flex
        justify-content: space-between
        align-items: center
        margin-top: 10px
        margin-bottom: -10px
        padding: 0 15px

        .checkbox_container
            margin-right: 12px
            width: 18px
            height: 18px

            .input
                width: 18px
                height: 18px
                cursor: pointer

        .bulk_actions_dropdown
            margin: 0
            width: 140px
            min-width: 100px
            font-size: 13px

            .options-dropdown-container
                display: block

                .icon
                    margin-left: 0px
                    height: 32px
                    font-weight: 600
                    color: $black

                    svg path
                    stroke: $black

                .options-menu
                    right: 0

                    ul li div
                    padding: 4px 15px
                    border-radius: 5px

            .select_container__control
                min-height: 30px
                border: none

                .select_container__indicator
                    padding: 0px 4px !important

    .conversation-list-items
        margin-top: 15px

        .conversation-date
            font-size: 12px
            font-weight: $font-weight-medium
            color: $grey-4
            padding: 10px 15px 5px

.conversation-item
    width: 100%
    padding: 10px 15px
    padding-bottom: 13px
    cursor: pointer
    border-left: 1px solid $white
    border-right: 1px solid $white
    box-shadow: $conversation-list-shadow
    @include flex-position(initial, flex-start)
    transition: all ease-in-out .3s

    .avatar
        width: 30px
        flex: 0 0 30px
        height: 30px
        border: 1px solid $grey-3
        border-radius: 50%
        @include flex-position(center, center)
        background: $grey-3
        color: $white
        font-size: 12px
        margin-right: 10px
        transition: all ease-in-out .3s

    .checkbox_container
        margin-top: 3px
        margin-right: 10px
        width: 18px
        height: 18px

        .input
            width: 18px
            height: 18px
            cursor: pointer

    .content
        width: 100%

    &-header
        @include flex-position(space-between, center)
        padding-bottom: 5px

        .conversation-author
            font-weight: $font-weight-semi-bold

        .conversation-time
            font-size: 10px
            font-weight: $font-weight-medium
            color: $black

    &-preview-container
        @include flex-position(space-between, center)

    &-preview
        font-size: 12px
        max-width: 175px
        @include ellipsis
        @include flex-position(flex-start, center)
        gap: 5px
        width: calc(100% - 13px)

        svg
            margin-right: 4px
            margin-top: -2px

        span
            position: relative
            @include flex-position(initial, center)

            &.msg-dlr
                img
                    width: 16px

                    &.sent
                        width: 13px

        p
            @include flex-position(flex-start, center)
            font-size: 12.5px
            font-weight: 400
            width: inherit

    &-channel
        display: flex
        position: relative
        margin-top: 5px

        span
            font-size: 12px
            font-weight: $font-weight-medium
            @include flex-position(initial, center)
            transition: all ease-in-out .3s

            svg
                margin-top: -1px
                margin-left: 5px
                width: 18px
                height: 18px

        .conversation-button
            opacity: 0
            position: absolute
            transition: all ease-in-out .3s

    &:hover, &.active
        background: $grey-trans

        .avatar
            background: $grey

        .conversation-item-channel.hover-effect
            span
                opacity: 0

            .conversation-button
                opacity: 1

    &.unread
        .conversation-item-header
            .conversation-author
                font-weight: $font-weight-bold

        .conversation-item-preview
            max-width: 140px

.audit-trail-container
    .modal-title
        font-weight: 700
        font-size: 20px

    .trail-container
        margin-top: 30px

        .convo
            margin-bottom: 32px

        .convo_id
            margin-bottom: 12px
            font-size: 1,2em
            font-weight: 600
            color: $black

            span
                color: $grey-7

        .d-grid
            display: grid
            grid-template-columns: 110px 1fr
            grid-gap: 0px

        .header
            margin-bottom: 12px
            background: $grey-2
            border-radius: 4px

            p
                padding: 8px 10px
                font-weight: 600
                color: $grey-7

        .trail-item
            padding: 0 10px
            margin-bottom: 12px

            .date
                margin-bottom: 12px
                font-weight: 600
                font-size: 14px
                color: $black

            .list
                padding-bottom: 16px
                border-bottom: 1px solid $grey-6

                .list-item
                    margin-bottom: 12px

                    &:last-child
                        margin: 0

                    h6
                        font-weight: 600
                        font-size: 14px
                        color: $black-2

                    p
                        font-size: 14px
                        color: $black-2

    .modal-form-action
        @include flex-position(flex-end, center)
        margin-top: 40px

.order-details-container
    @include flex-position(space-between, flex-start)
    flex-direction: column

    .top
        width: 100%

        .modal-title
            @include flex-position(space-between, center)
            width: 100%

            p
                font-weight: 600
                font-size: 20px
                color: #474F58

            span
                cursor: pointer

                svg
                    width: 24px
                    height: 24px

        .loading
            margin-top: 40px
            padding: 0 12px
            width: 100%
            text-align: center
            font-weight: 500
            font-size: 14px
            color: #000

        .selected
            @include flex-position(space-between, center)
            margin-top: 18px
            padding-bottom: 22px
            border-bottom: 1px solid #E6EAF0

            p
                font-weight: 600
                font-size: 14px
                color: #757F8A

            button
                padding: 6px 12px
                font-weight: 700
                font-size: 14px
                height: auto

        .details-headers
            display: grid
            gap: 12px
            grid-template-columns: 1fr 2fr 0.5fr 2fr
            margin-top: 6px
            padding: 0px 12px
            padding-bottom: 8px
            border-bottom: 1px solid #E6EAF0

            p
                display: flex
                font-size: 13px

                &.center
                    justify-content: center

                &.end
                    justify-content: flex-end

        .details-container
            max-height: 70vh
            height: 100%
            overflow: auto

            .item
                display: grid
                grid-template-columns: 1fr 2fr 0.5fr 2fr
                gap: 12px
                padding: 12px
                border-bottom: 1px solid #E6EAF0

                img
                    width: 40px
                    height: 40px
                    border-radius: 6px
                    object-fit: contain
                    border: 1px solid #eee

                p
                    @include flex-position(flex-start, center)
                    font-size: 14px
                    color: #000

                    &.quantity
                        justify-content: center

                    &.bold
                        font-weight: 500

                    &.price
                        @include flex-position(flex-end, center)

                .select-container .input-icon-container
                    min-width: auto

            .sub_total
                @include flex-position(space-between, center)
                margin-top: 14px
                padding: 0 12px

                p
                    font-weight: 500
                    font-size: 14px
                    color: #000

    .modal-form-action
        gap: 16px
        width: 100%

        button
            width: 50%
