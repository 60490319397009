.auth-container
    display: flex
    align-items: flex-start
    width: 100%
    min-height: 700px
    height: 100vh
    background: $white

    &.sign-up
        min-height: 100vh
        height: auto
        background-image: url("https://s3.eu-west-3.amazonaws.com/oneroute.asb.ng/OneRoute+Background.png")

    &.verify
        min-height: 100vh
        height: auto
        width: 100%

    .signup-form
        margin: 0 auto
        margin-top: 45px
        margin-bottom: 65px
        width: 580px

        .logo
            margin: 0 auto
            margin-bottom: 45px
            width: fit-content
            text-align: center
            cursor: pointer

        form
            padding: 32px 32px 22px
            background: #FFFFFF
            border: 1px solid #EBEDF2
            box-shadow: 0px 4px 30px #EAECEF
            border-radius: 10px

            .title
                margin-bottom: 5px
                text-align: center
                font-weight: bold
                font-size: 28px
                color: #474F58

            .description
                margin-bottom: 21px
                text-align: center
                font-weight: 600
                font-size: 14px
                color: #8F99A6

            .input-container
                input
                    background: #F6F7FB
                    border: 1px solid #CAD0D9

                    &:focus
                        border: 1px solid #4BA3E7

            .checkbox-container
                a
                    color: #4BA3E7

            .error
                margin-top: -20px
                margin-bottom: 20px
                color: red

            .primary-link
                color: #4BA3E7

    .auth-form, .auth-content
        width: 50%
        min-height: 100%
        height: 100%
        padding: 50px 20px

    .auth-form
        position: relative
        @include flex-position(center, center)

        @media screen and (max-width: 820px)
            padding: 50px 12px
            width: 100%

        .auth-bg
            @include full-absolute
            opacity: 0.02

            img
                @include image-full-fit(cover, center)

        .auth-form-content
            position: relative

        .login-form
            width: 350px
            flex: 0 0 350px
            background: $white

            @media screen and (max-width: 400px)
                width: 290px

            .title
                font-size: 35px
                font-weight: $font-weight-bold
                margin-bottom: 40px

            .description
                font-weight: $font-weight-semi-bold
                color: $grey
                margin-bottom: 25px

            .text-divider
                margin: 25px 0

            .login-actions
                display: flex
                justify-content: space-between

                span
                    cursor: pointer

            .sign-up-link
                font-weight: $font-weight-medium
                line-height: 1

    .auth-content
        background: $purple
        @include flex-position(center, center)
        flex-direction: column

        @media screen and (max-width: 820px)
            display: none

        .message
            font-size: 16px
            font-style: italic
            color: $white
            text-align: center
            max-width: 375px
            margin: 0 auto

        .auth-image
            margin: 50px auto 0
            position: relative
            max-width: 585px
            width: 100%
            padding: 0 45px 0 55px

            .rocket-img
                position: absolute
                top: 100px
                left: 0
                max-width: 140px

            .main-img
                width: 100%

            .message-img
                position: absolute
                right: 0
                bottom: 5px
                max-width: 420px

.register_page_container
    display: grid
    grid-template-columns: 2fr 3fr
    min-height: 100vh

    @media screen and (max-width: 767px)
        grid-template-columns: 1fr
        width: 100%

    .side
        @include flex-position(center, flex-start)
        flex-direction: column
        padding: 100px 40px

        @media screen and (max-width: 450px)
            padding: 60px 12px

    .left_side
        background: #F6F7FB

        @media screen and (max-width: 767px)
            display: none

        .carousel
            margin-top: 65px

            .slide
                display: none

                &.active
                    display: block

                .quotation_mark
                    color: $black
                    font-size: 70px
                    font-weight: 500
                    line-height: 10px

                .title
                    margin-bottom: 8px
                    color: $black
                    font-size: 1.15em
                    font-weight: 700
                    line-height: 130%

                .text
                    margin-bottom: 16px
                    color: #6C747A
                    font-size: 1em

                .author
                    font-size: 1em
                    font-weight: 500
                    color: $black

            .controls
                @include flex-position(center, center)
                gap: 12px
                margin-top: 37px

                .item
                    width: 8px
                    height: 8px
                    border-radius: 50%
                    background: #D9D9D9
                    cursor: pointer

                    &.active
                        background: $black

    .form_container
        max-width: 620px

        .title
            margin-bottom: 5px
            color: #1B1B1B
            font-size: 1.75em
            font-weight: 600

        .sub_title
            color: $grey
            font-size: 1.05em
            font-weight: 500

        .steps_container
            @include flex-position(space-between, center)
            gap: 18px
            margin-top: 32px
            width: 100%

            @media screen and (max-width: 390px)
                gap: 12px

            .step
                padding-top: 12px
                color: #212326
                font-size: 0.95em
                font-weight: 600
                letter-spacing: 0.2px
                text-align: center
                width: 100%
                border-top: 3px solid #F6F7FB

                @media screen and (max-width: 390px)
                    font-size: 0.85em

                &.active
                    border-top: 3px solid $blue-2

                span
                    font-weight: 400

        .form
            margin-top: 42px
            width: 100%

            .error_message
                margin-top: -17px
                margin-bottom: 20px
                color: $red
                font-weight: 500

            .password_validators
                margin-top: -8px

                .item
                    @include flex-position(flex-start, center)
                    gap: 6px
                    margin-bottom: 5px
                    font-size: 0.9em

                    &:last-child
                        margin: 0

                    .icon
                        width: 14px
                        height: 14px
                        background: #F6F7FB
                        border: 1px solid #CAD0D9
                        border-radius: 50%

                    svg
                        width: 14px
                        height: 14px

            .forgot-password
                margin: 0 0 0 auto
                margin-top: -15px
                width: fit-content
                font-size: 0.95em
                cursor: pointer

                &:hover
                    text-decoration: underline

            .channels_container
                margin-top: 30px
                padding-top: 25px
                border-top: 1px solid #E1E5EA

                .title
                    margin-bottom: 5px
                    color: #1B1B1B
                    font-size: 1.15em
                    font-weight: 600

                .sub_title
                    color: $grey
                    font-size: 0.95em
                    font-weight: 500

                .channels
                    display: grid
                    grid-template-columns: repeat(3, 1fr)
                    gap: 15px
                    margin-top: 24px

                    @media screen and (max-width: 997px)
                        grid-template-columns: repeat(2, 1fr)

                    @media screen and (max-width: 390px)
                        grid-template-columns: repeat(1, 1fr)

                    .item
                        @include flex-position(space-between, center)
                        padding: 12px
                        background: $white
                        border: 1px solid #EDEFF2
                        border-radius: 8px
                        cursor: pointer

                        .flex
                            @include flex-position(flex-start, center)
                            width: calc(100% - 28px)

                            img
                                margin-right: 14px
                                width: 24px
                                height: 24px

                        .circle
                            width: 22px
                            height: 22px
                            border-radius: 50%
                            border: 3px solid #E6EAF0
                            background: $white

                        &.selected
                            border: 1px solid #92D048

                            .circle
                                border: 3px solid #92D048
                                background: #92D048

            .agreement_check
                margin-top: 18px

                .checkbox-label
                    font-weight: 500

                    a
                        color: $blue-2

            .primary-button
                margin-top: 26px

            .sign-up-link
                margin-top: 30px
                color: $grey

                .primary-link
                    color: $blue-2

.verification_page_container
    margin: auto
    max-width: 542px
    text-align: center

    .form
        padding: 40px 24px
        border-radius: 10px
        border: 1px solid #EBEDF2
        background: #FFF

        svg
            width: 100px
            height: 100px

        .title
            margin-top: 30px
            color: #1B1B1B
            font-size: 1.35em
            font-weight: 600

        .sub_title
            margin-top: 8px
            margin-bottom: 42px
            color: #8A939F
            font-size: 1.05em

            span
                color: #1B1B1B
                font-weight: 500

        input
            padding: 8px 12px !important
            text-align: center
            height: auto !important
            font-size: 1.4em !important
            letter-spacing: 0.9px
            font-weight: 500

    .go_back
        margin: auto
        margin-top: 42px
        width: fit-content
        cursor: pointer

        &:hover
            text-decoration: underline
