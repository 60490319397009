.mentions-container
  height: 100vh
  min-width: 350px
  display: flex
  overflow: hidden

  .mention-lists-container
    .conversation-date
      padding: 10px 15px

    .conversation-item
      display: block
      padding: 13px 15px
      padding-bottom: 9px

      .conversation-item-type
        @include flex-position(space-between, center)
        margin-bottom: 12px

        .conversation-type
          padding: 2px 10px
          font-weight: 600
          font-size: 11px
          color: $purple
          background: #EFE7FF
          border: 0.5px solid #39206A
          border-radius: 4px
          text-transform: capitalize

          &.reply
            color: #4BA3E7
            background: #E8F4FD
            border: 0.5px solid #4BA3E7

        .conversation-time
          color: $black
          font-size: 11px
          font-weight: $font-weight-medium

      .d-flex
        @include flex-position(inherit, flex-start)

      .avatar
        text-transform: uppercase
        font-weight: 600
        font-size: 15px

      .conversation-item-channel
        justify-content: space-between
        margin-top: 15px
        padding-top: 6px
        border-top: 1px solid #F6F6F6

        span
          opacity: 1

      &.active
        color: $white
        background: $blue-2

        .avatar
          color: $blue-2
          background: $white

        .conversation-time
          color: $white

        .conversation-item-channel
          svg path
            fill: $white

        .with-hover span
          opacity: 0

        .conversation-button
          width: 100%
          font-weight: 600
          color: $blue-2
          background: $white

      &:hover
        &.with-hover span
          opacity: 0

        .conversation-button
          padding-bottom: 2px
          width: 100%
          height: 20px

  .mentions-thread
    @include flex-position(space-between, inherit)
    flex-direction: column
    background: $grey-2

    .avatar
      text-transform: uppercase
      font-weight: 600

    .chat-content
      padding-top: 30px

      .primary-mention-chat-message
        margin-bottom: 22px
        background: $white
        border-radius: 12px

        .mention-chat-message:first-child
          margin: 0
          border-radius: 12px 12px 0px 0px
          border-bottom: 1px solid #F7F7F7

        .mention-chat-message:last-child
          border-radius: 12px

      .mention-chat-message
        margin-bottom: 22px
        padding: 16px
        background: $white
        border-radius: 12px

        .sender
          @include flex-position(space-between, flex-start)

          .left
            @include flex-position(space-between, flex-start)

            .avatar
              @include flex-position(center, center)
              margin-right: 8px
              width: 40px
              flex: 0 0 40px
              height: 40px
              color: $white
              text-transform: uppercase
              font-weight: 600
              font-size: 14px
              border: 1px solid $grey
              border-radius: 50%
              background: $grey

            .name
              h6
                margin-bottom: 2px
                font-weight: 600
                font-size: 16px
                color: $black-2
                text-transform: capitalize

              p
                font-size: 13px
                color: #ABB2BC

          .time
            font-size: 13px
            color: #ABB2BC

        .message-content
          margin-top: 16px
          margin-bottom: 6px
          padding-left: 48px

          p
            font-size: 14px
            line-height: 150%
            color: #39206A

        .stats
          @include flex-position(flex-start, center)
          margin-top: 16px
          padding-top: 12px
          border-top: 1px solid #EEEEEE

          p
            margin-right: 20px
            font-size: 13px
            color: $black

            span
              margin-right: 3px
              font-weight: 600

          .link-button
            @include flex-position(center, center)
            margin: 0 0 0 auto
            padding: 9px 12px
            padding-top: 8px
            font-size: 13px
            font-weight: 600
            color: $blue-2
            background: #EBF6FF
            border-radius: 8px

            span
              margin-left: 3px
              margin-right: 10px

            svg path
              stroke: $blue-2

        .message-footer
          padding-left: 48px

          span
            font-size: 13px
            font-weight: 600
            text-align: left
            color: $green

          .failed-action
            justify-content: flex-start
            margin: 0
            padding: 5px 10px
            text-align: left
            width: fit-content
            background: $red

            span
              color: $white
              text-decoration: underline
              cursor: pointer

    .chat-form
      background: $grey-2

      .chat_input_header
        background: $white
