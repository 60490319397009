.get-started-container
  @include flex-position(center, center)
  padding: 60px 24px
  width: 100%
  background-image: url("https://s3.eu-west-3.amazonaws.com/oneroute.asb.ng/OneRoute+Background.png")
  min-height: 93vh

  .content
    margin: auto
    width: 100%
    max-width: 550px

    .title
      margin-bottom: 24px
      font-weight: 600
      font-size: 1.3em
      text-align: center
      color: $black

      span
        color: $blue-2

    .todos
      .item
        @include flex-position(flex-start, flex-start)
        margin-bottom: 15px
        padding: 14px 16px
        background: #FFFFFF
        border-radius: 12px

        &:last-child
          margin-bottom: 0px
          cursor: pointer

          &.disabled
            cursor: auto

        img, svg
          margin-right: 12px
          width: 28px
          height: 28px

        svg path
          stroke: $black

        .check svg path
          stroke: $white

        &.disabled
          background: $grey-5

        .info
          width: 100%

          p
            margin: 0
            font-weight: 500
            font-size: 0.95em
            color: $black-2

            span
              color: $blue-2

          .primary-button
            margin: 8px 0 0 auto
            padding: 0px 12px 1px 12px
            font-size: 0.9em
            width: fit-content
            height: 35px

.welcome-modal
  .title
    margin-bottom: 16px
    font-size: 1.6em
    font-weight: 700

  .message
    color: #5B646B
    font-size: 1.05em
    line-height: 150%

    ul
      margin: 14px 0px 14px 18px

      li
        list-style: disc

  .primary-button
    margin-top: 30px
    font-size: 1em
    width: 100%

.verify-phone-modal
  text-align: center

  img
    margin-bottom: 20px
    width: 64px
    height: 64px

  .title
    margin-bottom: 8px
    font-size: 1.6em
    font-weight: 700

  .sub_title
    margin-bottom: 42px
    font-size: 1.1em
    font-weight: 500
    color: #8A939F

  form
    .input-container
      margin-bottom: 16px

      input
        text-align: center

  .primary-button
    font-size: 1em
    width: 100%

  .resend_code
    margin-top: 20px
    font-weight: 500
    color: $blue-2
    cursor: pointer

    &:hover
      text-decoration: underline

.add-channel-container
  .modal-title
    font-weight: 600
    font-size: 20px

  .modal-sub-title
    margin-top: 5px
    font-weight: 500
    font-size: 14px
    color: #8F99A6

  .channel_listing
    margin-top: 30px
    margin-bottom: 50px

    .item
      @include flex-position(space-between, center)
      margin-bottom: 12px
      padding: 13px 20px
      border: 1px solid #E6EAF0
      border-radius: 6px
      cursor: pointer

      &:last-child
        margin: 0

      .info
        @include flex-position(flex-start, center)

      .channel_icon
        margin-right: 15px
        width: 24px
        height: 24px

      h6
        font-weight: bold
        font-size: 15px
        text-transform: capitalize

      p
        font-weight: 600
        font-size: 13px
        color: #8F99A6

      svg
        margin-left: 18px
        width: 24px
        height: 24px
        transform: rotate(-90deg)
        opacity: .6

        path
          stroke: #757F8A

  .fields
    @include flex-position(space-between, center)
    margin: 40px 0px

  .error
    margin-top: -30px
    margin-bottom: 40px
    font-weight: 500
    color: $red

  .actions
    @include flex-position(space-between, center)
