.chatinput-container
    border: 1px solid $grey-3
    border-radius: 6px

    &.disabled
        opacity: 0.5
        pointer-events: none

    .chatinput-header
        height: 40px
        border-radius: 6px 6px 0 0
        background: $grey-2
        padding: 0 10px
        border-bottom: 1px solid $grey-3
        @include flex-position(initial, center)

        img
            width: 20px
            height: auto

        .input-container
            margin-left: 10px

            .input
                @include placeholder
                    font-weight: $font-weight-medium
                    color: $grey-3
                    font-family: $primary-font

    .chatinput-content
        padding: 10px

        .input-container
            .input
                font-family: $primary-font
                font-weight: $font-weight-medium !important
                color: $black-2 !important
                max-height: 85px
                line-height: 21px
                height: auto
                padding: 0 0 10px !important

            &.isFocused
                .input
                    outline: none !important

    .chatinput-media
        border: 1px solid $grey-3
        border-radius: 7px
        padding: 10px
        display: inline-block
        position: relative
        margin-bottom: 10px

        .close
            width: 20px
            height: 20px
            border: 1px solid $grey-3
            position: absolute
            top: -10px
            right: -10px
            border-radius: 50%
            background: $white
            cursor: pointer
            padding: 4px
            @include flex-position(center, center)

        .message-media
            max-width: 100px
            height: 50px
            margin-top: 0

        .message-audio, .message-file
            margin-top: 0

    .chatinput-actions
        @include flex-position(space-between, center)
        position: relative

        & > div
            @include flex-position(initial, center)

            .icon
                @include flex-position(center, center)
                width: 30px
                flex: 0 0 30px
                height: 30px
                margin-right: 5px
                cursor: pointer
                border-radius: 6px
                transition: all ease-in-out .3s

                svg
                    width: 15px
                    height: auto

                    path
                        transition: all ease-in-out .3s

                &:last-child
                    margin-right: 0

                &:hover
                    background: $grey-6

                &.send
                    &:hover, &.active
                        background: $blue-2

                        svg
                            path
                                stroke: $white

    .templateOnly, .reopenOnly
        background: $grey-2
        border-radius: 0 0 6px 6px

        .input-container, .input
            background: $grey-2
            color: $grey-7 !important

        .chatinput-actions
            .select-template
                color: $blue
                cursor: pointer
                font-size: 14px
                font-weight: $font-weight-bold

    .saved-responses-container
        position: absolute
        top: auto
        right: auto
        bottom: auto
        height: 350px
        width: 310px
        background: $white
        border-radius: 4px
        box-shadow: $user-dropdown-shadow
        padding: 10px 20px
        z-index: 35
        overflow-y: scroll
        cursor: initial

        .title
            font-size: 16px
            font-weight: $font-weight-medium
            color: $black
            text-align: center

        .search
            margin-top: 10px

            .input
                background: $grey-2
                padding-left: 35px !important
                padding-bottom: 0 !important
                height: 30px !important

        .saved-responses-section
            margin-top: 15px
            padding-bottom: 10px
            border-bottom: 1px solid $grey-6

            .responses-category
                font-size: 12px
                font-weight: $font-weight-semi-bold
                color: $grey-7
                text-transform: uppercase
                cursor: pointer
                @include flex-position(initial, center)

                .icon
                    display: flex
                    width: 10px
                    flex: 0 0 10px
                    margin-left: 10px
                    height: auto

                    svg
                        width: 100%
                        height: auto

            .responses-item
                margin-top: 10px
                @include flex-position(initial, center)
                font-size: 14px
                color: $black-2
                font-weight: $font-weight-medium
                cursor: pointer

                .item-title
                    font-weight: $font-weight-bold
                    @include flex-position(initial, center)
                    position: relative
                    padding-right: 10px
                    margin-right: 5px
                    white-space: nowrap
                    @include ellipsis
                    max-width: 115px

                    &::after
                        content: ''
                        @include full-circle(5px, 5px)
                        background: $black-2
                        position: absolute
                        right: 0

                .item-value
                    @include ellipsis
                    max-width: 145px
                    max-height: 20px

.chat_input_container
    background: #FFFFFF
    border: 1px solid #CAD0D9
    border-radius: 6px

    &.disabled
        background: #F6F7FB

        .chat_input_content
        .chat_input_actions
            background: #F6F7FB

    .chat_input_header
        @include flex-position(initial, center)
        padding: 0px 10px
        width: 100%
        background: #F6F7FB
        border-radius: 6px 6px 0px 0px
        border-bottom: 1px solid #CAD0D9

        @media screen and (max-width: 790px)
            flex-wrap: wrap

        .d_flex
            @include flex-position(initial, center)

            &.end
                margin: 0 0 0 auto

            @media screen and (max-width: 650px)
                flex-wrap: wrap
                margin: 0

            svg
                width: 20px
                height: 20px

            .select_container__control
                margin-left: 5px
                padding: 0px
                width: 100px
                border: none
                box-shadow: none
                outline: none
                background: transparent
                cursor: pointer

                .select_container__value-container
                    padding: 0

                .select_container__indicators
                    padding: 0

                    .select_container__indicator-separator
                        display: none

                    .select_container__indicator
                        padding: 0

            .select_container__menu .select_container__option
                cursor: pointer

        .customer_info
            @include ellipsis
            margin: 0 10px 0 auto
            max-width: 230px
            font-size: 13px

            &:last-child
                margin-right: 0

            @media screen and (max-width: 790px)
                margin-top: -5px
                margin-bottom: 6px

            @media screen and (max-width: 650px)
                margin-top: -3px
                margin-left: 0

            span
                font-weight: bold

    .chat_input_content
        margin-bottom: 3px
        padding: 7px 10px
        padding-bottom: 0px

        textarea
            font-family: "Manrope", sans-serif
            font-weight: 500
            font-size: 14px
            width: 100%
            min-height: 59px
            border: none
            outline: none
            overflow: scroll

            &:focus
                outline: none

            &:disabled
                background: #F6F7FB

        .chat_input_media
            border: 1px solid $grey-3
            border-radius: 5px
            padding: 3px
            display: inline-block
            position: relative

            .close
                width: 20px
                height: 20px
                border: 1px solid $grey-3
                position: absolute
                top: -10px
                right: -10px
                border-radius: 50%
                background: $white
                cursor: pointer
                padding: 4px
                z-index: 2
                @include flex-position(center, center)

            .message_media
                z-index: 1

                img, video
                    margin-bottom: -4px
                    width: 75px
                    height: 45px
                    border-radius: 5px
                    object-fit: cover

            .message-audio, .message-file
                margin-top: 0

    .chat_input_actions
        @include flex-position(initial, center)
        gap: 16px
        padding: 0px 10px
        padding-bottom: 5px
        border-radius: 0px 0px 6px 6px

        @media screen and (max-width: 495px)
            flex-wrap: wrap

        span
            padding: 5px 7px
            padding-bottom: 1px
            cursor: pointer

            @media screen and (max-width: 495px)
                padding-bottom: 3px

            &:first-child
                padding-left: 0px

                @media screen and (max-width: 495px)
                    padding-left: 7px

            svg
                width: 18px
                height: 18px

            &:last-child
                margin: 0 0 0 auto

                @media screen and (max-width: 495px)
                    margin-right: 16px

        .send
            &:hover, &.active
                background: $blue-2
                border-radius: 5px

                svg
                    path
                        stroke: $white

        .saved-responses-container
            position: absolute
            top: auto
            right: auto
            bottom: 35px
            height: 350px
            width: 310px
            background: $white
            border-radius: 4px
            box-shadow: $user-dropdown-shadow
            padding: 10px 20px
            z-index: 35
            overflow-y: scroll
            cursor: initial

            .title
                font-size: 16px
                font-weight: $font-weight-medium
                color: $black
                text-align: center

            .search
                margin-top: 10px

                .input
                    background: $grey-2
                    padding-left: 35px !important
                    padding-bottom: 0 !important
                    height: 30px !important

            .saved-responses-section
                margin-top: 15px
                padding-bottom: 10px
                border-bottom: 1px solid $grey-6

                .responses-category
                    font-size: 12px
                    font-weight: $font-weight-semi-bold
                    color: $grey-7
                    text-transform: uppercase
                    cursor: pointer
                    @include flex-position(initial, center)

                    .icon
                        display: flex
                        width: 10px
                        flex: 0 0 10px
                        margin-left: 10px
                        height: auto

                        svg
                            width: 100%
                            height: auto

                .responses-item
                    margin-top: 10px
                    @include flex-position(initial, center)
                    font-size: 14px
                    color: $black-2
                    font-weight: $font-weight-medium
                    cursor: pointer

                    .item-title
                        font-weight: $font-weight-bold
                        @include flex-position(initial, center)
                        position: relative
                        padding-right: 10px
                        margin-right: 5px
                        white-space: nowrap
                        @include ellipsis
                        max-width: 115px

                        &::after
                            content: ''
                            @include full-circle(5px, 5px)
                            background: $black-2
                            position: absolute
                            right: 0

                    .item-value
                        @include ellipsis
                        max-width: 145px
                        max-height: 20px

        .emoji-container
            position: absolute
            bottom: 15px
            left: 15px
            width: 15px
            cursor: pointer

            .emoji-mart
                position: relative
                z-index: 10

        .emoji-select
            position: relative
            z-index: 10
            display: flex

        .select-template
            color: $blue
            cursor: pointer
            font-size: 14px
            font-weight: $font-weight-bold

        &.email
            display: flex
            justify-content: center
            align-items: center
            height: 81px

            p
                text-align: center
