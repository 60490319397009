.insights-details-page
    background: $grey-2
    height: 100%

    .insights-details-header
        @include flex-position(space-between, flex-start)
        padding: 50px 25px 20px
        margin-bottom: 25px
        position: relative

        .header-bg
            @include full-absolute
            opacity: 0.03
            pointer-events: none
            overflow: hidden

            img
                width: 17%

        & > div
            @include flex-position(initial, center)

        .agent-avatar
            @include full-circle(90px, 90px)
            margin-right: 15px
            overflow: hidden

            img
                @include image-full-fit(cover, top)

        .name
            font-size: 18px
            font-weight: $font-weight-bold
            margin-bottom: 5px

        .status
            @include flex-position(initial, center)
            margin-bottom: 5px

            .icon
                @include full-circle(10px, 10px)
                background: $green
                margin-right: 10px

            .text
                font-size: 12px
                font-weight: $font-weight-semi-bold

        .location
            @include flex-position(initial, center)

            .icon
                display: flex
                margin-right: 10px

            .text
                font-size: 12px
                font-weight: $font-weight-medium

        .select-current
            background: $white

    .insights-details-content
        padding: 15px 25px
        display: flex
        width: 100%

        .agent-info
            width: 240px
            flex: 0 0 240px
            margin-right: 25px

            .insights-card
                height: calc(100% - 37px)

            .agent-info-item
                margin-bottom: 15px
                font-size: 14px
                font-weight: $font-weight-bold

                span
                    display: block

                .title
                    color: $grey
                    margin-bottom: 5px
                    text-transform: capitalize

                .value
                    color: $black-2

                input:focus
                    box-shadow: 0px 0px 0px 4px rgba(193, 224, 255, 1)

        .performance-conversations
            width: 100%

            .performance-overview
                margin-bottom: 25px

                .insights-card
                    height: 115px
                    @include flex-position(initial, center)

                    .performance-item
                        @include flex-position(initial, center)
                        width: 100%
                        margin-right: 10px
                        padding-right: 10px
                        border-right: 1px solid $grey-6

                        &:last-child
                            margin-right: 0
                            border-right: 0

                        .icon
                            @include full-circle(50px, 50px)
                            @include flex-position(center, center)
                            margin-right: 10px

                        span
                            display: block

                        .title
                            font-size: 12px
                            font-weight: $font-weight-semi-bold
                            color: $grey
                            margin-bottom: 5px

                        .value
                            font-size: 32px
                            font-weight: $font-weight-semi-bold
                            color: $black-2
                            line-height: 1
                            @include flex-position(initial, flex-start)

                            .small
                                font-size: 12px
                                font-weight: $font-weight-bold

                        &.agent-rank
                            .icon
                                background: $blue-trans

                                svg
                                    path
                                        stroke: $blue-2

                        &.agent-rating
                            .icon
                                background: $yellow-trans

                                svg
                                    path
                                        stroke: $yellow

                        &.conversations
                            .icon
                                background: $green-trans

                                svg
                                    path
                                        stroke: $green

                        &.avg-response-time
                            .icon
                                background: $green-trans

                                svg
                                    path
                                        stroke: $green

.tickets-table
    width: 100%

    .table-tabs
        .table-tab-item
            font-weight: $font-weight-bold
            color: $black
            @include flex-position(initial, center)

            .count
                @include full-circle(25px, 25px)
                @include flex-position(center, center)
                background: $grey-7
                color: $white
                font-size: 14px
                font-weight: $font-weight-bold
                margin-left: 10px
                transition: all ease-in-out .3s

            &.active
                .count
                    background: $blue-2

    .table
        table
            tr
                td
                    color: $grey
                    font-size: 14px
                    font-weight: $font-weight-semi-bold

                    .customer-info
                        color: $black-2

                    .message-tag
                        display: inline-block
                        background: $grey-6
                        border-radius: 4px
                        padding: 3px 10px
                        font-size: 12px
                        font-weight: $font-weight-bold
                        color: $black-2
