button
    background: transparent
    border: 0
    cursor: pointer
    position: relative
    white-space: nowrap
    font-family: $primary-font

    &:disabled
        pointer-events: none

.default-button
    @include flex-position(center, center)
    background: $blue-light-7
    border-radius: 5px
    color: $black-2
    width: auto
    padding: 0 25px
    height: 40px
    font-size: 16px
    font-weight: $font-weight-bold
    box-shadow: none
    transition: all ease-in-out .3s

    &.full
        width: 100%

    &:hover
        box-shadow: 0px 6px 14px rgba(8, 109, 235, 0.15)

    &:disabled
        background: $blue-light
        color: $grey-2

.primary-button
    @include flex-position(center, center)
    background: $blue-2
    border-radius: 6px
    color: $white
    width: auto
    padding: 0 25px
    height: 40px
    font-size: 16px
    font-weight: $font-weight-bold
    box-shadow: none
    transition: all ease-in-out .3s

    &.green
        background: #4CAF50

    .icon
        display: flex
        margin-right: 10px
        width: 20px

        svg
            width: 100%
            height: auto

            path
                stroke: $white

        &.fill
            svg
                path
                    fill: $white

    &.full
        width: 100%

    &.small
        font-size: 14px

    &:hover
        box-shadow: 0px 6px 14px rgba(8, 109, 235, 0.15)

    &:disabled
        background: $grey-3
        color: $grey-2

.secondary-button
    @include flex-position(center, center)
    background: $white
    border-radius: 5px
    border: 1px solid $blue-2
    color: $blue-2
    width: auto
    padding: 0 25px
    height: 40px
    font-size: 16px
    font-weight: $font-weight-bold
    box-shadow: none
    transition: all ease-in-out .3s

    &.red
        border-color: $red
        color: $red

    &.full
        width: 100%

    &.small
        font-size: 14px
        padding: 0 25px

    &:hover
        box-shadow: 0px 6px 14px rgba(8, 109, 235, 0.15)

    &:disabled
        background: $grey-3
        border-color: $grey-3
        color: $grey-2

.plain-button
    font-weight: $font-weight-bold
    color: $blue-2

    &.black
        color: $black-2
        font-size: 14px

    &:disabled
        opacity: 0.5

.icon-button-group
    display: flex
    align-items: center

    .icon-button
        margin-right: 5px

        &:last-child
            margin-right: 0

.icon-button
    @include flex-position(center, center)
    width: 40px
    height: 40px
    border: 1px solid $blue-light
    border-radius: 5px

    svg
        g, path
        fill: $grey-4

    &.active
        svg
            g, path
            fill: $blue

.filter-button
    @include flex-position(center, center)
    background: $white
    border-radius: 6px
    border: 1px solid $grey-6
    color: $grey-7
    width: auto
    padding: 0 10px
    padding-top: 1px
    height: 40px
    font-size: 14px
    font-weight: $font-weight-medium
    box-shadow: none
    transition: all ease-in-out .3s

    .icon
        display: flex
        width: 15px
        margin-right: 10px

        svg
            path
                stroke: $grey-7

    &:disabled
        background: $grey-3
        border-color: $grey-3
        color: $grey-2

.google-button
    background: $blue
    border-radius: 6px
    display: flex
    height: 40px
    cursor: pointer

    .icon
        width: 40px
        flex: 0 0 40px
        background: $white
        border: 1px solid $blue
        border-radius: 6px 0 0 6px
        @include flex-position(center, center)

        svg
            width: 15px
            height: auto

    .text
        width: 100%
        @include flex-position(center, center)
        color: $white
        font-weight: $font-weight-semi-bold

.conversation-button
    background: $blue-2
    border-radius: 4px
    height: 20px
    font-size: 12px
    font-weight: $font-weight-medium
    color: $white
    padding: 0 15px
    @include flex-position(center, center)
